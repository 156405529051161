import { ActionTypesEnum } from './action/ActionTypesEnum';
import { FilterTypesEnum } from './filters/FilterTypesEnum';

export const mainTableColorCellPrefix = '_markColor_';
export const mainTableLabelCellPrefix = '_markLabel_';

export const mainTableLabelItem = '_labelItemText';
export const mainTableColorItem = '_labelItemColor';

export const mainTableColumnDefaultGroup = 'Default';

// TODO: refactor logic to default action to each coluumn type
export const actionTypesGroups = {
  arithmeticAndOverride: [
    ActionTypesEnum.override,
    ActionTypesEnum.setEmpty,
    ActionTypesEnum.add,
    ActionTypesEnum.divide,
    ActionTypesEnum.multiply,
    ActionTypesEnum.subtract,
  ],
  arithmeticExtend: [ActionTypesEnum.add, ActionTypesEnum.divide, ActionTypesEnum.multiply, ActionTypesEnum.subtract],
  allTextActions: [
    // ActionTypesEnum.fixUppercase,
    ActionTypesEnum.generateValue,
    ActionTypesEnum.override,
    ActionTypesEnum.addEnd,
    ActionTypesEnum.addStart,
    ActionTypesEnum.caseChange,
    ActionTypesEnum.capitalized,
    ActionTypesEnum.lower,
    ActionTypesEnum.upper,
    ActionTypesEnum.replace,
    ActionTypesEnum.regexpReplace,
    ActionTypesEnum.setEmpty,
    // ActionTypesEnum.addFoundFromField,
  ],
};

// TODO: refactor logic to default filter to each coluumn type
export const filterTypesGroups = {
  default: [
    FilterTypesEnum.is_empty,
    FilterTypesEnum.not_empty,
    FilterTypesEnum.equal,
    FilterTypesEnum.exists_in,
    FilterTypesEnum.not_exists_in,
  ],
  defaultWithoutEmpty: [FilterTypesEnum.equal, FilterTypesEnum.exists_in, FilterTypesEnum.not_exists_in],
  allFilters: [
    FilterTypesEnum.is_empty,
    FilterTypesEnum.not_empty,
    FilterTypesEnum.equal,
    FilterTypesEnum.exists_in,
    FilterTypesEnum.not_exists_in,
    // FilterTypesEnum.is_uppercase,
    FilterTypesEnum.include,
    // FilterTypesEnum.include_one_of,
    FilterTypesEnum.exclude,
    FilterTypesEnum.start,
    FilterTypesEnum.end,
    FilterTypesEnum.regexp,
    // FilterTypesEnum.field_not_include_field,
  ],
  allFiltersNumeric: [
    FilterTypesEnum.is_empty,
    FilterTypesEnum.not_empty,
    FilterTypesEnum.num_equal,
    FilterTypesEnum.num_bigger,
    FilterTypesEnum.num_biggerrEqual,
    FilterTypesEnum.num_smaller,
    FilterTypesEnum.num_smallerEqual,
  ],
  allOnlyFiltersNumeric: [
    FilterTypesEnum.num_equal,
    FilterTypesEnum.num_bigger,
    FilterTypesEnum.num_biggerrEqual,
    FilterTypesEnum.num_smaller,
    FilterTypesEnum.num_smallerEqual,
  ],
  forProjectId: [
    FilterTypesEnum.end,
    FilterTypesEnum.equal,
    FilterTypesEnum.exclude,
    FilterTypesEnum.exists_in,
    FilterTypesEnum.is_empty,
    FilterTypesEnum.not_empty,
    FilterTypesEnum.not_exists_in,
    FilterTypesEnum.start,
  ],
  specialStrings: [FilterTypesEnum.string_bigger, FilterTypesEnum.string_smaller],
};
