import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import routerConfig from 'src/app/configs/router.config';
import { NotificationService } from 'src/app/notification/notification.service';
import { ConfirmModalComponent } from '../../../shared/modals/confirm-modal/confirm-modal.component';
import { RouterService } from '../../../shared/service/router.service';
import { CurrentPackage } from '../models/payments.model';
import { AdditionalProductsResponse, PaymentsService } from '../payments.service';

@Component({
  selector: 'app-add-products',
  templateUrl: './add-products.component.html',
  styleUrls: ['./add-products.component.scss'],
})
export class AddProductsComponent implements OnInit, OnDestroy {
  currentPackage!: CurrentPackage;
  form!: UntypedFormGroup;
  private onDestroy$ = new Subject<void>();
  isLoading = false;
  contentLoading = false;
  max = 20;
  stillAbleToChoose!: number;
  canBuyMoreProducts = false;
  currentAmount = 0;
  currency = '';

  availableProductKeys: string[] = [];
  additonalProductResponse!: AdditionalProductsResponse;

  constructor(
    private paymentsService: PaymentsService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private routerService: RouterService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.currentPackage = this.paymentsService.currentPackage$.getValue() || this.route.snapshot.data['payments'];
    this.init();
    this.canBuyMoreProducts = this.currentPackage.isTrialling || this.currentPackage.isActive;
  }

  private init(): void {
    if (!this.currentPackage.stripe_status) {
      return;
    }
    this.contentLoading = true;

    this.paymentsService
      .getAdditionalProducts()
      .pipe(
        tap((res) => (this.availableProductKeys = Object.keys(res))),
        tap((res) => (this.additonalProductResponse = res)),
        tap(() => this.createForm()),
        tap(() => (this.contentLoading = false)),
        tap(() => this.listenToFormChange()),
        tap(() => this.setMax()),
      )
      .subscribe();
  }

  private createForm() {
    const group: any = {};
    this.availableProductKeys.forEach((key) => {
      group[key] = new UntypedFormGroup({
        package: new UntypedFormControl(null),
        amount: new UntypedFormControl(0),
      });
    });

    this.form = new UntypedFormGroup(group);
  }

  getFormGroup(key: string) {
    return this.form.get(key) as UntypedFormGroup;
  }

  setCurrency(currency: string) {
    this.currency = currency;
  }

  private setMax() {
    let count = 0;
    this.currentPackage.items.forEach((el) => (count += el.count));
    this.max = 20 - count;
    this.stillAbleToChoose = this.max;
  }

  private listenToFormChange() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        map((values) => Object.values(values).map((res: any) => res.amount)),
        map((values) => Number(values.reduce((a: number, b: number) => a + b))),
        tap((sum) => (this.stillAbleToChoose = this.max - sum)),
        tap(() => this.calcCurrentPrice()),
      )
      .subscribe();
  }

  private calcCurrentPrice() {
    this.currentAmount = 0;
    Object.values(this.form.value)
      .filter((value: any) => value.package)
      .forEach((value: any) => (this.currentAmount += value.package.price * value.amount));
  }

  onSubmit() {
    if (this.currentAmount === 0) {
      this.notificationService.warning('no_products');
      return;
    }
    if (this.form.invalid) {
      return;
    }
    const config = {
      data: {
        key: 'confirmations.add_products',
      },
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig;

    const dialog = this.dialog.open(ConfirmModalComponent, config);

    const products = Object.values(<number>this.form.value)
      .map((el) => (el.package ? { id: el.package.id, count: el.amount } : null!))
      .filter((el) => el && el.count > 0);

    dialog
      .afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe(() => this.addProducts(products));
  }

  private addProducts(products: { id: number; count: number }[]) {
    this.isLoading = true;
    this.paymentsService
      .addProducts(products)
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(() => this.paymentsService.getCurrentPackage()),
      )
      .subscribe(
        () => {
          this.notificationService.success('products_added');
          this.isLoading = false;
          this.routerService.navigate(routerConfig.payments);
        },
        () => {
          this.notificationService.error('products_added');
          this.isLoading = false;
        },
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
