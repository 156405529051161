import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import apiUrlsConfig from 'src/app/configs/api-urls.config';
import { ConnectionModel } from 'src/app/shared/model/connections.model';
import { ResponseV2Interface } from 'src/app/shared/model/response.model';
import { BingAccount, BingCustomer } from '../../connections.model';
import { AvailableServicesEnum } from '../../../summaries/summaries.enum';
import { ExternalServiceCampaignEnum } from '../../../campaign/enums/ExternalServiceCampaignEnum';
import { ConnectionsService } from '../../../../../shared/service/connections.service';

@Injectable({
  providedIn: 'root',
})
export class BingConnectionsService {
  private http = inject(HttpClient);
  private connectionsService = inject(ConnectionsService);

  getCustomers(connection: number): Observable<BingCustomer[]> {
    return this.http
      .get<ResponseV2Interface<BingCustomer[]>>(apiUrlsConfig.bingCustomer.prepareUrl({ connection }))
      .pipe(map((res) => res.data));
  }

  getAccounts(connection: number, customer: number): Observable<BingAccount[]> {
    return this.http
      .get<ResponseV2Interface<BingAccount[]>>(apiUrlsConfig.bingAccounts.prepareUrl({ connection, customer }))
      .pipe(map((res) => res.data));
  }

  attachConnection(
    projectId: number,
    connection: ConnectionModel,
    customer: BingCustomer,
    account: BingAccount,
    projectConnectionId?: number | null,
  ): Observable<ConnectionModel> {
    const url = this.connectionsService.generateProjectConnectionUrl(projectId, projectConnectionId);

    return this.http.post<ConnectionModel>(url, {
      connection_id: connection.id,
      service: projectConnectionId ? AvailableServicesEnum.BING : ExternalServiceCampaignEnum.Bing,
      customer_id: customer.id,
      account_id: account.id,
      account_name: account.name,
    });
  }
}
