<div class="validation-error">
  <mat-icon>warning</mat-icon>
  @if (invalidFiles.length === 1) {
    @switch (invalidFiles[0].error) {
      @case ('resolutionMin') {
        <p>{{ 'guides.asset_library.min_resolution_error' | translate }}</p>
      }
      @case ('resolutionMax') {
        <p>{{ 'guides.asset_library.max_resolution_error' | translate }}</p>
      }
      @case ('sizeMax') {
        <p>{{ 'guides.asset_library.size_error' | translate }}</p>
      }
    }
  }

  @if (invalidFiles.length > 1) {
    <div class="files-wrapper">
      <p>{{ invalidFiles.length }} {{ 'guides.asset_library.info' | translate }}:</p>
      <ul>
        <li *ngFor="let file of invalidFiles">
          {{ file.name }} -
          @switch (file.error) {
            @case ('resolutionMin') {
              {{ 'guides.asset_library.min_resolution_error_short' | translate }}
            }
            @case ('resolutionMax') {
              {{ 'guides.asset_library.max_resolution_error_short' | translate }}
            }
            @case ('sizeMax') {
              {{ 'guides.asset_library.size_error_short' | translate }}
            }
          }
        </li>
      </ul>
      <p class="info-2">{{ 'guides.asset_library.info_2' | translate }}</p>
    </div>
  }

  <mat-icon class="close-icon" (click)="closeNotification.emit()">close</mat-icon>
</div>
