import { Subject } from 'rxjs';
import { ActionTypesEnum } from '../../../../action/ActionTypesEnum';
import { filterTypesGroups, mainTableColumnDefaultGroup } from '../../../../enums';
import { FilterTypesEnum } from '../../../../filters/FilterTypesEnum';
import { CustomButtonPerItemInterface } from '../../../../models/CustomButton.model';
import { ObjectMapping } from '../../../../models/Mapping.model';
import { ToolInterface } from '../../../../models/TableConfigurationInterface.model';
import { AgregationInterface } from '../../../../models/agregation.model';
import { ColumnTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnComponentModel, ColumnModel } from '../column.model';

export class AutocompleteColumn extends ColumnModel {
  override type = ColumnTypesEnum.AUTOCOMPLETE;
  override valueMapping: ObjectMapping;
  override autocomplete$?: Subject<any[]>;
  override onlyFromAutocomplete: boolean;

  constructor(config: {
    ableToFilter?: boolean;
    actionsAvailable?: ActionTypesEnum[];
    aggregate?: boolean;
    aggregateBySelect?: AgregationInterface;
    alias?: string;
    canBeSorted?: boolean;
    component?: ColumnComponentModel;
    customButton?: CustomButtonPerItemInterface[];
    editable?: ToolInterface;
    filtersAvailable?: FilterTypesEnum[];
    group?: string;
    grouping?: boolean;
    onlyFromAutocomplete?: boolean;
    param?: string;
    required?: boolean;
    tooltip?: string;
    valueMapping?: ObjectMapping;
    disabledIn?: {
      duplicatePopup?: boolean;
      editPopup?: boolean;
      addPopup?: boolean;
      usingAsFilterInMassOperations?: boolean;
    };

    baseColumnName?: string;
    defaultFilterType?: FilterTypesEnum;
    responseMapping?: any;
  }) {
    super();
    this.ableToFilter = config.ableToFilter;
    this.actionsAvailableTypes = config.actionsAvailable ? config.actionsAvailable : this.actionsAvailableTypes;
    this.aggregate = config.aggregate;
    this.aggregateBySelect = config.aggregateBySelect;
    this.alias = config.alias || '';
    this.autocomplete$ = new Subject();
    this.canBeSorted = config.canBeSorted;
    this.customButton = config.customButton;
    this.disabledIn = config.disabledIn;
    this.editable = config.editable;
    this.filterAvailableTypes = config.filtersAvailable ? config.filtersAvailable : filterTypesGroups.allFilters;
    this.group = config.group || mainTableColumnDefaultGroup;
    this.grouping = config.grouping;
    this.onlyFromAutocomplete = config.onlyFromAutocomplete!;
    this.param = config.param!;
    this.required = config.required!;
    this.tooltip = config.tooltip;
    this.valueMapping = config.valueMapping!;

    this.baseColumnName = config.baseColumnName!;
    this.defaultFilterType = config.defaultFilterType;
    this.responseMapping = config.responseMapping!;

    if (config.component) {
      this.setComponent(config.component);
    }
  }
}
