import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Output, ViewChild, inject, signal, input, } from '@angular/core';
import { FilterService } from '../../filters/filters.service';
import { ItemModel } from '../../item.model';
import { CustomButtonPerItemInterface } from '../../models/CustomButton.model';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { AutocompleteService } from '../../services/autocomplete.service';
import { ChangesService } from '../../services/changes.service';
import { ColumnsService } from '../../services/columns.service';
import { CommunicationService } from '../../services/communication.service';
import { HelperService } from '../../services/helper.service';
import { PopupService } from '../../services/popup.service';
import { SelectedService } from '../../services/selected.service';
import { ColumnTypesEnum } from '../ColumnTypesEnum';
import { ColumnModel } from './columns/column.model';
import { ColumnsComponentInterface } from './columns/editable-column.component';
import { InnerColumnComponent } from './columns/inner-column/inner-column.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sem-columns-switch',
  templateUrl: './columns-switch.component.html',
  styleUrls: ['./columns-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsSwitchComponent {
  @ViewChild('container') containerRef!: ElementRef;
  @ViewChild('columnReference') columnReference!: ColumnsComponentInterface;
  @ViewChild('innerColumn') innerColumnReference!: InnerColumnComponent;
  @Output() actionPanelOpened: EventEmitter<void> = new EventEmitter();
  @Output() aiGeneratedClicked: EventEmitter<void> = new EventEmitter();
  @Output() customButtonClicked: EventEmitter<CustomButtonPerItemInterface> = new EventEmitter();
  @Output() emitCustomEventOnColumn: EventEmitter<{ columnName: string; mode: 'edit' | 'display' }> = new EventEmitter();
  @Output() hideColumnChange: EventEmitter<boolean> = new EventEmitter();
  @Output() infoCellClicked: EventEmitter<void> = new EventEmitter();
  @Output() infoItemClicked: EventEmitter<void> = new EventEmitter();
  @Output() initFilter: EventEmitter<void> = new EventEmitter();
  @Output() navigationClicked: EventEmitter<void> = new EventEmitter();
  @Output() nextColumn: EventEmitter<void> = new EventEmitter();
  @Output() nextRow: EventEmitter<void> = new EventEmitter();
  @Output() openPhotoFromColumn: EventEmitter<string> = new EventEmitter();
  @Output() prevColumn: EventEmitter<void> = new EventEmitter();
  @Output() prevRow: EventEmitter<void> = new EventEmitter();
  @Output() selectWithShift: EventEmitter<ItemModel> = new EventEmitter();
  @Output() selected: EventEmitter<ItemModel> = new EventEmitter();
  @Output() showSnackBar: EventEmitter<string> = new EventEmitter();
  @Output() unSelected: EventEmitter<ItemModel> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  public popupService = inject(PopupService);
  public changesService = inject(ChangesService);
  public helperService = inject(HelperService);
  public columnsService = inject(ColumnsService);
  public selectedService = inject(SelectedService);
  public autocompleteService = inject(AutocompleteService);
  public filterService = inject(FilterService);
  public communicationService = inject(CommunicationService);
  columnTypes = ColumnTypesEnum;
  public id = signal<number | null>(null);
  actionBtnDisplay = input<boolean>(false);
  checked = input<boolean>(false);
  colorCell = input<string>('');
  colorItem = input<string>('');
  column = input<ColumnModel | null>(null);
  config = input<TableConfigurationModel | null>(null);
  customButtons = input<CustomButtonPerItemInterface[]>([]);
  index = input<number>(0);
  isEditable = input<boolean>(false);
  isNavigationValue = input<boolean>(false);
  item = input<any | null>(null);
  labelCell = input<string>('');
  labelItem = input<string[]>([]);
  parentId = input<number>(0);
  shouldCheckEmpty = input<boolean>(true);
  value = input<any>(null);
  isEditableModeSig = signal<boolean>(false);
  private translate = inject(TranslateService);
  private clipboard = inject(Clipboard);
  private clickCount = 0;

  get param(): string | undefined {
    return this.column()?.param;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    switch ( event.key ) {
      case 'Enter':
        event.preventDefault();
        this.enterEditMode();
        break;
      case 'Tab':
        event.preventDefault();
        this.nextColumn.emit();
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.prevRow.emit();
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.nextRow.emit();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        this.prevColumn.emit();
        break;
      case 'ArrowRight':
        event.preventDefault();
        this.nextColumn.emit();
        break;
    }
  }

  emitCustomButtonEvent(button: CustomButtonPerItemInterface): void {
    this.customButtonClicked.emit(button);
  }

  onClickContainer(): void {
    this.clickCount++;
    setTimeout(() => {
      this.clickCount === 1 ? this.onSingleClick() : this.onDoubleClick();
      this.clickCount = 0;
    }, 250);
  }

  openPanel(item: ItemModel): void {
    this.innerColumnReference.openPanel(item);
  }

  setFocus(): void {
    this.containerRef.nativeElement.focus();
  }

  setBlur(): void {
    this.containerRef.nativeElement.blur();
  }

  editableModeChanged(status: boolean): void {
    this.isEditableModeSig.set(status);
  }

  enterEditMode(): void {
    if (this.isEditable() && this.columnReference) {
      this.columnReference.enterEditableMode();
    }
  }

  enterForcedEditableMode(): void {
    if (this.isEditable() && this.columnReference?.enterForcedEditableMode) {
      this.columnReference.enterForcedEditableMode();
    }
  }

  copyContent(): void {
    const textToCopy = String(this.value());
    this.clipboard.copy(textToCopy);
    const message = this.translate.instant('general.cell_copied');
    this.showSnackBar.emit(message);
  }

  onHideColumnChange(hide: boolean): void {
    this.hideColumnChange.emit(hide);
  }

  private onDoubleClick(): void {
    this.enterEditMode();
  }

  private onSingleClick(): void {
    if (this.isNavigationValue()) {
      this.navigationClicked.emit();
    }
  }
}
