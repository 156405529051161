import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import apiUrlsConfig from '../../configs/api-urls.config';
import { isPresent } from '../helpers/utils';
import { ResponseV2Interface } from '../model/response.model';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TemplateErrorsDialogComponent } from '../../dashboard/project/statistics-dashboard/template-errors-dialog/template-errors-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CardModel } from '../../dashboard/project/statistics-dashboard/card/card.model';
import { LangEnum } from './translation.service';
import { SaveReportTemplateConfig } from '../../dashboard/project/statistics-dashboard/statistics-dashboard.models';

interface GetConfig {
  accountId?: number;
  projectId?: number;
  name: string;
}

interface SaveConfig<T> extends GetConfig {
  data: T;
}

interface SaveUserConfig<T> {
  data: T;
  name: string;
}

interface ErrorDialogData {
  message: string;
  errors: Record<string, string[]>;
}

@Injectable({
  providedIn: 'root',
})
export class CustomSettingsService {
  private readonly http = inject(HttpClient);
  private readonly dialog = inject(MatDialog);
  private readonly translate = inject(TranslateService);

  get<T>(config: GetConfig) {
    let params = new HttpParams().set('name', config.name);
    if (isPresent(config.projectId)) {
      params = params.set('project_id', config.projectId);
    }
    if (isPresent(config.accountId)) {
      params = params.set('account_id', config.accountId);
    }
    return this.http.get<ResponseV2Interface<{ data?: T }>>(apiUrlsConfig.settingsGetStorage, { params });
  }

  save<T>(config: SaveConfig<T>) {
    return this.http.post<void>(apiUrlsConfig.settingsSetStorage, {
      data: config.data,
      name: config.name,
      project_id: config.projectId,
      account_id: config.accountId,
    });
  }

  saveForUser<T>(config: SaveUserConfig<T>) {
    return this.http.post<void>(apiUrlsConfig.settingsSetStorage, {
      data: config.data,
      name: config.name,
    });
  }

  saveReportTemplate(config: SaveReportTemplateConfig) {
    const url = apiUrlsConfig.storeReportTemplate.prepareUrl({ project: config.projectId });

    const currentLang = config.userLang ?? this.toLangEnum(this.translate.currentLang);

    return this.http
      .post<void>(url, {
        uuid: config.uuid,
        name: config.name,
        cards: config.cards,
      })
      .pipe(
        catchError((error) => {
          const payload = error?.payload;

          if (payload?.errors) {
            const remapped = this.remapValidationErrors(payload.errors, config.cards, currentLang);

            const validationErrorMsg = this.translate.instant('dashboard.report_template.validation_error');

            this.openErrorsDialog({
              message: payload.message ?? validationErrorMsg,
              errors: remapped,
            });
          } else {
            const unknownErr = this.translate.instant('dashboard.report_template.unknown_error');
            const somethingWrong = this.translate.instant('dashboard.report_template.something_went_wrong');

            this.openErrorsDialog({
              message: payload?.message || unknownErr,
              errors: { '': [payload?.message ?? somethingWrong] },
            });
          }

          return throwError(() => error);
        }),
      );
  }

  updateReportTemplate<T>(projectId: number, uuid: string, data: T) {
    const url = apiUrlsConfig.updateReportTemplate.prepareUrl({ project: projectId, uuid: uuid });
    return this.http.put<void>(url, data);
  }

  removeReportTemplate(projectId: number, id: string): Observable<void> {
    const url = apiUrlsConfig.updateReportTemplate.prepareUrl({ project: projectId, uuid: id });
    return this.http.delete<void>(url);
  }

  private openErrorsDialog(errorData: ErrorDialogData) {
    this.dialog.open(TemplateErrorsDialogComponent, {
      data: errorData,
      width: '650px',
    });
  }

  private remapValidationErrors(rawErrors: Record<string, string[]>, cards: CardModel[], userLang: LangEnum): Record<string, string[]> {
    const remapped: Record<string, string[]> = {};

    Object.entries(rawErrors).forEach(([errorKey, messages]) => {
      const newKey = this.transformErrorKeyToReadableName(errorKey, cards, userLang);
      remapped[newKey] = messages;
    });

    return remapped;
  }

  private transformErrorKeyToReadableName(errorKey: string, cards: CardModel[], userLang: LangEnum): string {
    const parts = errorKey.split('.');

    const cardIndex = parseInt(parts[1] ?? '-1', 10);
    const rowIndex = parseInt(parts[3] ?? '-1', 10);
    const widgetIndex = parseInt(parts[5] ?? '-1', 10);

    const rest = parts.slice(6).join('.');

    const cardLabel = this.translate.instant('dashboard.report_template.card');
    const widgetLabel = this.translate.instant('dashboard.report_template.widget');

    let cardName = `(Card #${cardIndex})`;
    const cardObj = cards?.[cardIndex];
    if (cardObj?.name) {
      cardName = cardObj.name[userLang] || cardObj.name.en || cardName;
    }

    let widgetName = `(Widget #${widgetIndex})`;
    const widgetObj = cardObj?.widgetsRow?.[rowIndex]?.widgets?.[widgetIndex];
    if (widgetObj) {
      const wName = widgetObj.data?.name;
      if (wName?.[userLang]) {
        widgetName = wName[userLang]!;
      } else if (wName?.en) {
        widgetName = wName.en!;
      } else if (widgetObj.type) {
        widgetName = widgetObj.type;
      }
    }

    return `${cardLabel}: "${cardName}", ${widgetLabel}: "${widgetName}" → ${rest}`;
  }

  private toLangEnum(lang: string): LangEnum {
    if (Object.values(LangEnum).includes(lang as LangEnum)) {
      return lang as LangEnum;
    }
    return LangEnum.pl;
  }
}
