<button
  mat-raised-button
  [color]="color"
  [type]="type"
  class="button-with-spinner"
  [disabled]="loading || disabled"
  (click)="onClick()"
  [id]="id"
  [ngStyle]="{ margin: marginSize }"
  [ngClass]="{
    'full-width': fullWidth,
    'button-small': size === 'small',
    'button-medium': size === 'medium',
    'button-large': size === 'large',
    'button-xl': size === 'xl',
  }"
  [attr.data-testid]="testId()"
>
  <span [ngClass]="{ 'is-loading': loading }">{{ text }}</span>
  @if (icon) {
    <mat-icon [ngClass]="{ 'is-loading': loading }">{{ icon }}</mat-icon>
  }

  @if (loading) {
    <div class="spinner-wrapper">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
  }
</button>
