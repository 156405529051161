<ul class="providers-wrapper" [class]="additionalClass()">
  @for (provider of providers$ | async; track provider) {
    <li class="provider">
      @if (canBeInteracted()) {
        <a href="{{ provider.url }}">
          <mat-icon svgIcon="{{ (providerNameMap && providerNameMap[provider.name]) || provider.name }}"></mat-icon>
          {{ (providerNameMap && providerNameMap[provider.name]) || provider.name }}
        </a>
      } @else {
        <button (click)="actionHandler()">
          <mat-icon svgIcon="{{ (providerNameMap && providerNameMap[provider.name]) || provider.name }}"></mat-icon>
          {{ (providerNameMap && providerNameMap[provider.name]) || provider.name }}
        </button>
      }
    </li>
  }
</ul>
