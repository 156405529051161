import { Component, Input } from '@angular/core';
import { EditableColumnComponent } from '../../sem-table/table-display/columns-switch/columns/editable-column.component';
import { BADGE_SETS } from './badge-sets';
import { BadgeInterface, BadgeTypeEnum, SetType } from './badge.model';

const DEFAULT_IS_OUTLINED = false;
const DEFAULT_TYPE = BadgeTypeEnum.dark;

type BadgeEditingListItem = { key: string; translateKey: string | undefined }[];

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent extends EditableColumnComponent {
  @Input() isOutlined: boolean = DEFAULT_IS_OUTLINED;
  @Input() translateKey!: string;
  @Input() type: BadgeTypeEnum = DEFAULT_TYPE;
  @Input() nullable: boolean = false;

  editingList!: BadgeEditingListItem;
  override isColumnInEditState = false;
  valueKey!: string;
}

@Component({
  selector: 'app-badge-set',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeSetComponent extends EditableColumnComponent {
  editingList!: BadgeEditingListItem;
  isOutlined: boolean = DEFAULT_IS_OUTLINED;
  type: BadgeTypeEnum = DEFAULT_TYPE;
  valueKey!: string;

  private _translateKey!: string | null;

  @Input() set!: SetType;

  @Input() nullable: boolean = false;

  @Input() set key(key: string) {
    this._translateKey = null;
    this.valueKey = key;
    const setData = BADGE_SETS[this.set] || null;
    this.editingList = Object.keys(setData).map((dataKey) => ({ key: dataKey, translateKey: setData[dataKey].translateKey }));

    const badgeData: BadgeInterface = (setData && setData[key]) || null;

    if (badgeData) {
      const { isOutlined, translateKey, type } = badgeData;

      if (isOutlined) {
        this.isOutlined = isOutlined;
      }

      this._translateKey = translateKey || key;

      if (type) {
        this.type = type;
      }
    }
  }

  @Input() forcedTranslateKey!: string;

  get translateKey(): string {
    return this.forcedTranslateKey || this._translateKey || '';
  }
}
