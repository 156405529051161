import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ProjectModel } from 'src/app/dashboard/project/project.model';
import { ProjectService } from 'src/app/dashboard/project/project.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';

@Injectable()
export class ProjectResolver implements Resolve<ProjectModel> {
  constructor(
    private projectService: ProjectService,
    private routerService: RouterService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (Number.isNaN(route.params['id'])) {
      this.routerService.navigate(routerConfig.projectNotFound);
      return throwError(null);
    }

    const activeProject = this.projectService.activeProject$.getValue();
    if (activeProject && activeProject.id === +route.params['id']) {
      return of(activeProject);
    }

    return this.projectService.getProject(route.params['id']).pipe(
      tap((project) => this.projectService.setActiveProject(project)),
      catchError((error) => {
        this.routerService.navigate(routerConfig.projectNotFound);
        return throwError(error);
      }),
    );
  }
}
