<apx-chart
  [annotations]="chartOptions().annotations"
  [chart]="chartConfig()"
  [dataLabels]="chartOptions().dataLabels"
  [fill]="chartOptions().fill"
  [grid]="chartOptions().grid"
  [labels]="chartOptions().labels"
  [legend]="chartOptions().legend"
  [markers]="chartOptions().markers"
  [plotOptions]="chartOptions().plotOptions"
  [responsive]="chartOptions().responsive"
  [series]="chartOptions().series"
  [states]="chartOptions().states"
  [stroke]="chartOptions().stroke"
  [theme]="chartOptions().theme"
  [tooltip]="chartOptions().tooltip"
  [xaxis]="chartOptions().xaxis"
  [yaxis]="chartOptions().yaxis"
></apx-chart>
