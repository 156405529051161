<div class="guide-wrapper">
  <div class="title-box">
    @if (backButton && showBackButton) {
      <mat-icon
        [appRouter]="backButton"
        [urlVars]="{ project: projectId }"
        aria-hidden="false"
        aria-label="back icon"
        fontIcon="chevron_left"
        (click)="handleBackButtonClick()"
      ></mat-icon>
    }
    <h2 class="box-title">{{ guideTitle }}</h2>
    <ng-content select="[title]"></ng-content>
  </div>
  <app-button (click)="toggle()" [text]="'guides.general.text_btn' | translate" icon="school" />
</div>

<mat-card [@openClose]="isOpen ? 'open' : 'closed'" appearance="outlined" class="guide-card">
  <ng-content></ng-content>
</mat-card>
