import { Pipe, PipeTransform } from '@angular/core';
import { ConnectionModel } from 'src/app/shared/model/connections.model';

@Pipe({
  name: 'connectionLabel',
})
export class ConnectionPipe implements PipeTransform {
  transform(connection: ConnectionModel): string {
    let label = '';
    let { data, name, id } = connection;

    // eslint-disable-next-line @typescript-eslint/dot-notation
    const accountName = data?.['account_name' as keyof typeof data];
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const accountId = data?.['account_id' as keyof typeof data];
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const connectionUrl = data?.['url' as keyof typeof data];
    const connectionId = id ? ` (${id})` : '';

    label = [accountName, accountId && `#${accountId}`, connectionUrl, name].filter(Boolean).join(', ').trim() + connectionId;

    return label;
  }
}
