<span [ngSwitch]="data.type" class="title" mat-dialog-title>
  <span *ngSwitchCase="'edit'">{{ 'sem_table.item_popup.edit' | translate }}</span>
  <span *ngSwitchCase="'duplicate'">{{ 'sem_table.item_popup.duplicate' | translate }}</span>
  <span *ngSwitchCase="'add'">{{ 'sem_table.item_popup.add' | translate }}</span>
</span>
<button class="close-button" mat-dialog-close mat-icon-button tabindex="-1">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<mat-dialog-content>
  <form #formRef="ngForm" (ngSubmit)="onSubmit()" [formGroup]="form" autocomplete="off" class="form">
    <div *ngIf="data.columnNames.length > 4" class="search-container">
      <form [formGroup]="searchForm" class="search-form">
        <mat-form-field appearance="outline">
          <input
            #inputEl
            [formControl]="searchCtrl"
            [placeholder]="'sem_table.search_column' | translate"
            matInput
            name="search"
            type="text"
          />
        </mat-form-field>
        <mat-checkbox [formControl]="requireCtrl">{{ 'sem_table.item_popup.only_required' | translate | titlecase }} </mat-checkbox>
      </form>
      <mat-divider></mat-divider>
    </div>

    <div class="inputs">
      <ng-container *ngFor="let columnName of columnsToDisplay$ | async; let i = index" [ngSwitch]="data.config.columns[columnName].type">
        <ng-container *ngIf="data.config.columns[columnName] as column">
          <div #inputEl *ngSwitchCase="columnTypes.CUSTOM" [attr.data-id]="i" class="custom-column">
            <div (click)="columnReference.click()" class="column-alias">
              {{ column.alias }}
            </div>
            <sem-custom-column
              #columnReference
              (valueChanged)="catchChange($event, columnName)"
              [autocompleteService]="data.autocompleteService"
              [column]="$any(column)"
              [id]="data.item.id"
              [value]="form.get(columnName)!.value"
              class="columnComponent"
            >
            </sem-custom-column>
          </div>

          <div *ngSwitchCase="columnTypes.AUTOCOMPLETE" class="column">
            <mat-form-field class="input">
              <input
                #inputEl
                [attr.data-id]="i"
                [formControlName]="columnName"
                [matAutocomplete]="autocomplete"
                [placeholder]="column.alias"
                [required]="column.required"
                matInput
                type="text"
              />

              <mat-autocomplete
                #autocomplete="matAutocomplete"
                [autoActiveFirstOption]="column.onlyFromAutocomplete"
                [displayWith]="displayFn.bind(column)"
              >
                <mat-option *ngFor="let value of column.autocomplete$ | async" [value]="value">
                  {{ value | mapColumnValue: column }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngFor="let error of form.controls[columnName].errors?.['apiError']">{{ error }}</mat-error>
              <mat-error *ngIf="form.controls[columnName].errors?.['required']">{{ 'sem_table.field_is_required' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div
            *ngSwitchCase="columnTypes.SELECT"
            [ngClass]="column.description && form.get(columnName)!.value !== column.defaultValue ? 'description-block' : ''"
            class="column"
          >
            <mat-form-field *ngIf="column.editable" class="input">
              <mat-label
                >{{ column.alias }}
                <mat-icon
                  *ngIf="column.tooltip"
                  [matTooltipPosition]="'above'"
                  [matTooltip]="column.tooltip"
                  class="group-icon-on-top"
                  matTooltipClass="label-tooltip"
                  >help
                </mat-icon>
              </mat-label>

              <mat-select #inputEl [attr.data-id]="i" [formControlName]="columnName" [required]="column.required">
                <mat-option *ngFor="let value of column.valueMapping!.getValues()" [value]="value">
                  {{ value | mapColumnValue: column }}
                </mat-option>
              </mat-select>
              <mat-error *ngFor="let error of form.controls[columnName].errors?.['apiError']">{{ error }}</mat-error>
              <mat-error *ngIf="form.controls[columnName].errors?.['required']">{{ 'sem_table.field_is_required' | translate }}</mat-error>
            </mat-form-field>
            <div *ngIf="column.description && form.get(columnName)!.value !== column.defaultValue" class="description-info">
              <mat-icon>info</mat-icon>
              <p>{{ column.description }}</p>
            </div>
          </div>

          <div *ngSwitchCase="columnTypes.BOOLEAN" class="slide-container">
            <div class="column-alias">{{ column.alias }}</div>

            <span *ngIf="column.editable" class="input">
              <mat-slide-toggle [formControlName]="columnName" color="primary" matNativeControl name="value"> </mat-slide-toggle>
            </span>
          </div>

          <div *ngSwitchCase="columnTypes.DATE" class="date-container">
            <div class="column-alias">{{ column.alias }}</div>

            <sem-date-column
              #inputEl
              (valueChanged)="form.get(columnName)!.setValue($event)"
              [attr.data-id]="i"
              [column]="$any(column)"
              [displayOnlyEditable]="column.editable!.activeAlways!"
              [value]="$any(data.item)[columnName]"
              class="columnComponent"
            >
            </sem-date-column>
          </div>

          <div *ngSwitchCase="columnTypes.SIMPLE" class="column">
            <mat-form-field class="input">
              <input
                #inputEl
                [attr.data-id]="i"
                [formControlName]="columnName"
                [placeholder]="column.alias"
                [required]="column.required"
                [type]="column.valueType === columnValueTypes.number ? 'number' : 'text'"
                matInput
              />
              <mat-error *ngFor="let error of form.controls[columnName].errors?.['apiError']">{{ error }}</mat-error>
              <mat-error *ngIf="form.controls[columnName].errors?.['required']">{{ 'sem_table.field_is_required' | translate }}</mat-error>
              <span
                *ngIf="$any(column)['lettersLimit']"
                [ngClass]="{
                  warn: inputEl.value.length > $any(column)['lettersLimit'],
                }"
                class="limitter"
                matSuffix
              >
                {{ inputEl.value.length }} / {{ $any(column)['lettersLimit'] }}</span
              >
            </mat-form-field>
          </div>

          <ng-template #notEditable>
            <div class="containerNotEditable">
              <div class="param">
                {{ column.alias }}
              </div>
              <div class="value">
                {{ $any(data.item)[columnName] | mapColumnValue: column }}
              </div>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="onSubmit()" class="submit" color="primary" mat-raised-button>{{ 'sem_table.save' | translate }}</button>
</mat-dialog-actions>
