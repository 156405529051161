import { Component, EventEmitter, inject, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../../shared/service/auth.service';
import { ProfileService } from '../../../profile.service';
import { NotificationService } from '../../../../../notification/notification.service';
import { phoneNumberValidator } from '../../../../../shared/validators/phone-validator';

@Component({
  selector: 'app-change-phone',
  templateUrl: './change-phone.component.html',
  styleUrl: './change-phone.component.scss',
})
export class ChangePhoneComponent {
  @Output() closePanel = new EventEmitter<void>();
  private authService = inject(AuthService);
  private profileService = inject(ProfileService);
  private notificationService = inject(NotificationService);
  private fb = inject(FormBuilder);
  form: FormGroup = this.fb.group({
    phone: [this.authService.authUser?.phone || '', [Validators.required, phoneNumberValidator]],
  });

  submitForm() {
    if (this.form.invalid) {
      return;
    }
    this.profileService.updateProfile({ phone: this.form.value.phone }).subscribe({
      next: () => {
        this.notificationService.success('phone_change');
        this.closePanel.emit();
      },
      error: () => this.notificationService.error('phone_change'),
    });
  }
}
