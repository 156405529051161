<app-dialog [dialogRef]="dialogRef">
  <h2 *ngIf="currentService">
    {{ 'projects.connections.connect_popup.' + (currentService.connection_id ? 'change_connection' : 'title') | translate }} {{ currentService | connectionServiceLabel }}
    <app-beta-label *ngIf="currentService._isBeta" [isStatic]="true"></app-beta-label>
  </h2>
</app-dialog>

<ng-container>
  <mat-dialog-content>
    <ng-container *appSpinner="!serviceComponent?.service || !isLoaded">
      <div *ngIf="!areConnections || integrationSectionVisible; else integrateBtn" class="integrate">
        <span>{{ 'projects.connections.connect_popup.integrate_desc' | translate }}:</span>
        @if (currentService.slug === servicesSlugsEnum.facebookBusiness) {
          <div class="warning">
            <mat-icon>warning</mat-icon>
            <span>
              {{ 'projects.connections.connect_popup.integrate_facebook_warning' | translate }}
              <a href="https://www.facebook.com/business/help/2169003770027706" target="_blank">
                {{ 'homepage.features.items.help' | translate }}
              </a>
            </span>
          </div>
        }
        <div class="connection-integration-section">
          <app-connection-integration-section [service]="serviceComponent.service" [url]="url"></app-connection-integration-section>
        </div>

        <hr *ngIf="areConnections" />
      </div>

      <ng-template #integrateBtn>
        <div class="show-integrate-link">
          <a (click)="integrationSectionVisible = true">
            {{ 'projects.connections.form.fields.connection.new_info' | translate }}
          </a>
        </div>
      </ng-template>
    </ng-container>

    <div class="connect" [ngClass]="{ 'no-connections': !areConnections || !isLoaded }">
      <ng-template #serviceComponentRef></ng-template>

      <div>
        <app-connection-new-services-account [service]="currentService"></app-connection-new-services-account>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="areConnections && isLoaded">
    <app-button
      (click)="submitForm()"
      [activatedAction$]="tutorialService.activatedAction$"
      [disabled]="!isFormValid"
      [loading]="isLoading"
      [text]="'projects.connections.form.submit_btn_txt' | translate"
      appTutorialElement="tutorial-attach-connection-submit"
      class="button"
      color="primary"
    ></app-button>
  </mat-dialog-actions>
</ng-container>
