import { inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

interface Breadcrumb {
  label: string;
  url: string;
  campaignName?: string;
}

const ChannelSpecialPaths = new Set([
  'ad-group',
  'search-term',
  'shopping-performance',
  'keyword',
  'google-adgroups',
  'google-search-terms',
  'google-shopping-performance',
  'google-keywords',
]);

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService implements OnDestroy {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();
  campaignName$ = new BehaviorSubject('');
  private destroy$ = new Subject<void>();
  private router = inject(Router);

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs = this.createBreadcrumbs(root);
        if (JSON.stringify(this.breadcrumbsSubject.getValue()) !== JSON.stringify(breadcrumbs)) {
          this.breadcrumbsSubject.next(breadcrumbs);
        }
      });
    const root = this.router.routerState.snapshot.root;
    const breadcrumbs = this.createBreadcrumbs(root);
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  private createBreadcrumbs(route: ActivatedRouteSnapshot, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    if (!route) {
      return breadcrumbs;
    }

    const routeUrl = this.getRouteUrl(route);
    const nextUrl = this.buildNextUrl(url, routeUrl);

    if (route.data?.['breadcrumb']) {
      const breadcrumb = this.getBreadcrumb(route);

      if (this.isChannelsSpecialPath(route)) {
        breadcrumbs.push(this.createChannelsBreadcrumb(route));
      }

      breadcrumbs.push({ label: breadcrumb, url: nextUrl });
    }

    return route.firstChild ? this.createBreadcrumbs(route.firstChild, nextUrl, breadcrumbs) : breadcrumbs;
  }

  private getRouteUrl(route: ActivatedRouteSnapshot): string {
    return route.url.map((segment) => segment.path).join('/');
  }

  private buildNextUrl(baseUrl: string, routeUrl: string): string {
    return routeUrl ? `${baseUrl}/${routeUrl}` : baseUrl;
  }

  private getBreadcrumb(route: ActivatedRouteSnapshot): string {
    return typeof route.data['breadcrumb'] === 'function' ? route.data['breadcrumb'](route.params) : route.data['breadcrumb'];
  }

  private isChannelsSpecialPath(route: ActivatedRouteSnapshot): boolean {
    return ChannelSpecialPaths.has(route.routeConfig?.path || '');
  }

  private createChannelsBreadcrumb(route: ActivatedRouteSnapshot): Breadcrumb {
    const channelsReturnPath = `dashboard/project/${route.params['id']}/channels/google-campaigns`;
    const absoluteUrl = this.router.serializeUrl(this.router.createUrlTree([channelsReturnPath]));

    return {
      label: 'channels',
      url: absoluteUrl,
      campaignName: this.campaignName$.getValue(),
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
