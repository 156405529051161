import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-beta-label',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule],
  templateUrl: './beta-label.component.html',
  styleUrls: ['./beta-label.component.scss'],
})
export class BetaLabelComponent {
  @Input() isStatic: boolean = false;
}
