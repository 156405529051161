/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, Subject, takeUntil } from 'rxjs';
import { RightSidebarService } from '../../../service/right-sidebar.service';
import { ActionService } from '../../action/action.service';
import { mainTableColorItem, mainTableLabelItem } from '../../enums';
import { FilterLogicOperatorEnum } from '../../filters/FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../../filters/FilterTypesEnum';
import { FilterService } from '../../filters/filters.service';
import { FilterFactoryService } from '../../filters/filtersFactory.service';
import { ItemModel } from '../../item.model';
import { CustomButtonPerItemInterface } from '../../models/CustomButton.model';
import { TableConfigurationModel } from '../../models/TableConfiguration.model';
import { ListingType, PaginatorInterface } from '../../models/TableConfigurationInterface.model';
import { PaginationInterface } from '../../models/entryPagination.model';
import { AgregationService } from '../../services/agregation.service';
import { ChangesService } from '../../services/changes.service';
import { ColumnsService } from '../../services/columns.service';
import { CommunicationService } from '../../services/communication.service';
import { ConfigService } from '../../services/config.service';
import { EventBroadcasterService } from '../../services/event-broadcaster.service';
import { ExpansionRowService } from '../../services/expansion-row.service';
import { HelperService } from '../../services/helper.service';
import { PopupService } from '../../services/popup.service';
import { RowItemModel, RowItemsService } from '../../services/row-items.service';
import { SelectedService } from '../../services/selected.service';
import { SortingService } from '../../services/sorting.service';
import { ColumnTypesEnum, ColumnValueTypesEnum, innerColumnName } from '../ColumnTypesEnum';
import { ColumnsMovingService } from '../columns-moving.service';
import { ColumnsSwitchComponent } from '../columns-switch/columns-switch.component';
import { ActionColumn } from '../columns-switch/columns/action-column/ActionColumn';
import { ImageColumn } from '../columns-switch/columns/image-column/ImageColumn';

export const TABLE_ROW_ID_PREFIX = 'sem-table-row-';

@Component({
  selector: 'sem-table-displayer',
  templateUrl: './table-displayer.component.html',
  styleUrls: ['./table-displayer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDisplayerComponent implements OnInit, OnDestroy {
  @Input() listingParentId!: number | string;
  @Input() defaultDataForNewItemRow!: object;
  @Input() paginator: PaginatorInterface | null = null;
  @Input() shouldCheckEmpty: boolean = true;
  @ViewChildren('columnsSwitch') columnReferences!: QueryList<ColumnsSwitchComponent>;
  @ViewChildren('resizableCell') tableCells!: QueryList<ElementRef<HTMLTableCellElement>>;
  @ViewChildren('tableRows', { read: ElementRef }) rowsReferences!: QueryList<ElementRef<HTMLTableRowElement>>;
  public actionService = inject(ActionService);
  public agregationService = inject(AgregationService);
  public changesService = inject(ChangesService);
  public columnsMovingService = inject(ColumnsMovingService);
  public columnsService = inject(ColumnsService);
  public helperService = inject(HelperService);
  readonly rowIdPrefix = TABLE_ROW_ID_PREFIX;
  actionColumn: ActionColumn;
  config!: TableConfigurationModel;
  displayedColumns$ = this.columnsService.displayedColumns$;
  hideColumn = false;
  innerColumnName = innerColumnName;
  listingType!: ListingType;
  mainTableColorItem = mainTableColorItem;
  mainTableLabelItem = mainTableLabelItem;
  filteredDisplayedColumns$ = this.displayedColumns$.pipe(
    map((columns) => columns.filter((column) => !(column === this.innerColumnName && this.hideColumn))),
  );
  protected readonly expansionRowService = inject(ExpansionRowService, { optional: true });
  protected readonly rowsService = inject(RowItemsService, { optional: true });
  private communicationService = inject(CommunicationService);
  private configService = inject(ConfigService);
  private popupService = inject(PopupService);
  private selectedService = inject(SelectedService);
  selectAllGlobal$ = this.selectedService.selectedGlobally$;
  selectedItems$ = this.selectedService.selectedItems$;
  private snackBar = inject(MatSnackBar);
  private sortingService = inject(SortingService);
  sorting$ = this.sortingService.sorting$;
  private filterFactoryService = inject(FilterFactoryService);
  private filterService = inject(FilterService);
  private onDestroy$: Subject<void> = new Subject();
  private readonly rightSidebarService = inject(RightSidebarService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly semtableEventBroadcasterService = inject(EventBroadcasterService);

  constructor() {
    this.actionColumn = new ActionColumn();
    this.setConfig(this.configService.config);
    this.configService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => this.setConfig(config));
    this.rowsService?.scrollToRowItem$.pipe(takeUntil(this.onDestroy$)).subscribe((item: ItemModel) => this.scrollToRowItem(item));
    this.rowsService?.editModeForItemCell$.pipe(takeUntil(this.onDestroy$)).subscribe((data: RowItemModel) => {
      const { columnName, item } = data;

      if (item && columnName) {
        this.setFocusOnColumn(item, columnName);
        this.enterEditModeOnColumn(item, columnName);

        if (data.scrollTo) {
          this.scrollToRowItem(item);
        }
      }
    });
  }

  private _dataToDisplay: PaginationInterface<ItemModel> | null = null;

  get dataToDisplay(): PaginationInterface<ItemModel> | null {
    return this._dataToDisplay;
  }

  @Input()
  set dataToDisplay(data: PaginationInterface<ItemModel> | null) {
    this._dataToDisplay = data;
  }

  ngOnInit() {
    if (this.configService.config.configuration?.listingType) {
      this.listingType = this.configService.config?.configuration?.listingType;
      this.semtableEventBroadcasterService
        .eventBroadcaster$(this._dataToDisplay!.data, this.cd, this.listingType, this.listingParentId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();
    }
  }

  onHideColumnChange(hide: boolean): void {
    this.hideColumn = hide;
    this.filteredDisplayedColumns$ = this.displayedColumns$.pipe(
      map((columns) => columns.filter((column) => !(column === this.innerColumnName && this.hideColumn))),
    );
  }

  catchChange(value: any, item: ItemModel | null, columnName: string): void {
    if (item) {
      if (this.config.columns[columnName].editable?.singleChange) {
        const change = this.changesService.createSingleChange(item, columnName, value);
        this.changesService.singleChange$.next(change);
      } else {
        const change = this.changesService.createChange([item], columnName, [value]);
        this.changesService.newChange$.next(change);
      }
    }
  }

  enterEditModeOnColumn(item: ItemModel, columnName: string) {
    setTimeout(() => {
      // @TODO: Zastąpić timeouta jakąś alternatywą..
      const target = this.findColumnInTable(item, columnName);
      if (target) {
        target.enterEditMode();
      }
    }, 0);
  }

  getStylesForItem(item: ItemModel): Record<string, any> | null {
    return this.config.itemRowStyle?.(item) || null;
  }

  newItemRowClick(data: unknown[] | null = null, parentId: number | null = null) {
    if (!this.config.newItemRow?.columnName) {
      return;
    }

    const listing = (data || this.dataToDisplay?.data) as ItemModel[];

    const newItem: ItemModel = {
      ...{ id: 0, parent_id: parentId || null, _isDraft: true },
      ...(this.defaultDataForNewItemRow || {}),
    };

    if (listing!.some((item) => item._isDraft)) {
      this.rowsService?.setEditModeForCell(newItem, this.config.newItemRow.columnName);

      return;
    }

    listing!.unshift(newItem);
    this.cd.detectChanges();
    this.rowsService?.setEditModeForCell(newItem, this.config.newItemRow.columnName);
  }

  setFocusOnColumn(item: ItemModel, columnName: string) {
    const target = this.findColumnInTable(item, columnName);
    if (target) {
      target.setFocus();
    }
  }

  select(item: ItemModel) {
    this.selectedService.selectItem(item);
  }

  unSelect(item: ItemModel) {
    this.selectedService.unselectItem(item);
  }

  selectWithShift(clickedItem: ItemModel) {
    let clickedIndex: number = 0;
    let anySelectedOnPage = false;
    let firstSelectedIndex = 9999999;
    let lastSelectedIndex = 0;
    this.dataToDisplay!.data.forEach((item, index) => {
      if (item.id === clickedItem.id) {
        clickedIndex = index;
      } else if (this.helperService.isInArray(item, this.selectedService.selectedItems)) {
        firstSelectedIndex = firstSelectedIndex < index ? firstSelectedIndex : index;
        lastSelectedIndex = lastSelectedIndex > index ? lastSelectedIndex : index;
        anySelectedOnPage = true;
      }
    });

    let arr: ItemModel[] = [];
    if (anySelectedOnPage && clickedIndex < firstSelectedIndex) {
      arr = this.dataToDisplay!.data.slice(clickedIndex, firstSelectedIndex);
    } else if (anySelectedOnPage && clickedIndex > lastSelectedIndex) {
      arr = this.dataToDisplay!.data.slice(lastSelectedIndex + 1, clickedIndex + 1);
    } else {
      this.select(this.dataToDisplay!.data[clickedIndex]);
    }

    arr.forEach((c) => this.select(c));
  }

  onCellInfoClick(item: ItemModel, columnName: string) {
    this.communicationService.cellClicked$.next({ item, columnName });
  }

  onItemInfoClick(item: ItemModel) {
    this.communicationService.itemInfoClicked$.next(item);
  }

  trackByFn(_index: number, item: ItemModel) {
    return item.id;
  }

  expansionClick(event: MouseEvent, item: ItemModel) {
    this.expansionRowService?.expand({ event, item });
  }

  filterByClick(item: ItemModel, columnName: string) {
    const itemColumnName = columnName as keyof ItemModel;
    const column = this.config.columns[columnName];
    const isBoolean = column.type === ColumnTypesEnum.BOOLEAN;
    let type: FilterTypesEnum;
    let value: any;
    if (column.type === ColumnTypesEnum.IMAGE) {
      value = null;
      type = item[itemColumnName] ? FilterTypesEnum.not_empty : FilterTypesEnum.is_empty;
    } else if (column.type === ColumnTypesEnum.SIMPLE && column.valueType === ColumnValueTypesEnum.number) {
      value = item[itemColumnName];
      type = value ? FilterTypesEnum.num_equal : FilterTypesEnum.is_empty;
    } else {
      value = item[itemColumnName];
      type = value || isBoolean ? FilterTypesEnum.equal : FilterTypesEnum.is_empty;
    }
    const filterObj = this.filterFactoryService.createFilter(column, type, value, FilterLogicOperatorEnum.and, false);

    this.filterService.addFilterToLastGroup(filterObj);
  }

  openPhoto(columnName: string, item: ItemModel) {
    const column: ImageColumn = this.config.columns[columnName] as ImageColumn;
    if (column.editable && !column.editInCustomLogic && !this.config.readOnly) {
      this.popupService
        .openPhotoEditPopup(columnName, item)
        .subscribe((mainImage: any) => this.catchChange(mainImage.thumb_path, item, columnName));
    } else if (column.editable && column.editInCustomLogic && !this.config.readOnly) {
      this.communicationService.openPhotoCustomEdit$.next({ item, columnName });
    } else {
      this.popupService.openPhotoDisplayPopup(columnName, item);
    }
  }

  handleCustomEventOnColumn(data: { columnName: string; mode: 'edit' | 'display' }, item: ItemModel) {
    this.communicationService.customEventOnColumn$.next({ ...data, item });
  }

  handleCustomButtonClicked(event: CustomButtonPerItemInterface, item: ItemModel, columnName: string) {
    this.communicationService.customButtonClicked$.next({ type: event.actionType, data: item, columnName });
  }

  handleAiGeneratedClicked(item: ItemModel, columnName: string) {
    this.communicationService.aiGeneratedClicked$.next({ item, columnName });
  }

  handleNavigationClicked(item: ItemModel, columnName: string) {
    this.rightSidebarService.closeContainerIfUnpinned();

    if (!item._isNotNavigationValue) {
      this.communicationService.navigationClicked$.next({ item, columnName });
    }
  }

  showSnackBar(text: string) {
    this.snackBar.open(text, undefined, {
      duration: 3000,
      horizontalPosition: 'center',
      panelClass: 'table-col-snack-bar',
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private scrollToRowItem(item: ItemModel): void {
    const rowElem = this.findRowInTable(item);
    rowElem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  private setConfig(config: TableConfigurationModel) {
    if (config?.navigationColumn && config.columns && config.columns[config.navigationColumn]) {
      config.columns[config.navigationColumn]._isNavigationColumn = true;
    }

    this.config = config;
  }

  private findColumnInTable(item: ItemModel, columnName: string): ColumnsSwitchComponent | undefined {
    return this.columnReferences.find(
      (component) => component.id() === item.id && component.parentId() === item.parent_id && component.column()?.param === columnName,
    );
  }

  private findRowInTable(item: ItemModel): ElementRef<HTMLTableRowElement> {
    const itemId = item.id;
    let target;

    if (itemId) {
      const rowId = `${this.rowIdPrefix}${itemId}`.toString();
      target = this.rowsReferences.find((row) => row.nativeElement.id === rowId);
    }

    return target!;
  }
}
