/* eslint-disable no-underscore-dangle */
import { Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TutorialService } from '../../tutorial.service';

export abstract class InnerAction {
  protected _isDone$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isVisible = true;
  eventListened: string;
  isDone$: Observable<boolean> = this._isDone$.asObservable();

  constructor(
    public text: string,
    protected renderer?: Renderer2,
    public idToHighlight?: string,
    eventListened: string = 'click',
  ) {
    if (idToHighlight && !renderer) {
      throw Error('Renderer must be present if you pass idToHighlight');
    }
    this.text = text;
    this.eventListened = eventListened;
  }

  markAsDone() {
    this._isDone$.next(true);
  }

  checkIfIsCompleted(): boolean {
    return this._isDone$.getValue();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  activate(_tutorialService: TutorialService): void {
    // if (this.idToHighlight === 'tutorial-attach-connection-submit') {
    //   console.log('activate', this.idToHighlight);
    // } else {
    // }
  }

  deactivate(): void {}
}
