export const QUICKSTART_TRANSLATION_KEY = {
  BASIC_SETUP_PACKAGE: 'basic_setup.package',
  WORKSPACE_CREATE_FIRST_TASK: 'workspace.create_first_task',
  WORKSPACE_SET_UP_PROCESSES: 'workspace.set_up_processes',
  WORKSPACE_VISIT_DASHBOARD: 'workspace.visit_dashboard',
  WORKSPACE_INVITE_TEAM_MEMBERS: 'workspace.invite_team_members',
  POSITION_TRACKING_ADD_FIRST_PHRASE_TO_TRACK: 'position_tracking.add_first_phrase_to_track',
  PRODUCTS_ADD_PRODUCT_FEED: 'products.add_product_feed',
  PRODUCTS_EXPORT_FEED_TO_XML: 'products.export_feed_to_xml',
  PRODUCTS_CREATE_FIRST_RULE: 'products.create_first_rule',
  PRODUCTS_CHECK_PERFORMANCE_DATA: 'products.check_performance_data',
  PRODUCTS_ADD_FIRST_PRODUCTS_TO_TRACK: 'products.add_first_products_to_track',
  PRODUCTS_ADD_FIRST_PRODUCTS_TO_TRACK_COMPETITOR_PRICES: 'products.add_first_products_to_track_competitor_prices',
  CAMPAIGNS_CREATE_FIRST_CAMPAIGN: 'campaigns.create_first_campaign',
  ADVANCED_CREATE_FIRST_PRODUCT_DUPLICATE: 'advanced.create_first_product_duplicate',
  ADVANCED_CHECK_PAGES_TECHNICAL_SCORE: 'advanced.check_pages_technical_score',
} as const;
