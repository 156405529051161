import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InvalidFileInterface } from '../../../../shared/model/file.model';

@Component({
  selector: 'app-validation-error-display',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
  templateUrl: './validation-error-display.component.html',
  styleUrl: './validation-error-display.component.scss',
})
export class ValidationErrorDisplayComponent {
  @Input() invalidFiles: InvalidFileInterface[] = [];
  @Output() closeNotification = new EventEmitter<void>();
}
