@let columnItem = column();
@let labelCellItem = labelCell();

<div
  #container
  (click)="onClickContainer()"
  [ngClass]="{ inner: columnItem?.type === columnTypes.INNER, notEditable: !columnItem?.editable, 'navigation-column': isNavigationValue() }"
  [tabindex]="columnItem?.type === columnTypes.INNER ? -1 : 0"
  class="container"
>
  @if (columnItem?.type !== columnTypes.INNER) {
    <div class="item-info">
      <mat-icon
        (click)="infoCellClicked.next()"
        *ngIf="labelCellItem"
        [matTooltip]="labelCellItem"
        [ngStyle]="{ color: colorCell() }"
        matTooltipClass="label-tooltip"
        matTooltipPosition="right"
      >info
      </mat-icon>
    </div>
  } @else {
    <div></div>
  }

  @if (labelCellItem) {
    <div
      (click)="infoCellClicked.next()"
      [matTooltip]="labelCellItem"
      [ngStyle]="{ 'border-top-color': colorCell() }"
      class="label-cell"
      matTooltipClass="label-tooltip"
      matTooltipPosition="right"
    ></div>
  }

  @if (columnItem && !columnItem.component) {
    @switch (columnItem.type) {

      <!--------------------           INNER column                 ---------------------->
      @case (columnTypes.INNER) {
        <span>
          <sem-inner-column
            #innerColumn
            (actionPanelOpened)="actionPanelOpened.emit()"
            (emitCustomButtonEvent)="emitCustomButtonEvent($event)"
            (hideColumn)="onHideColumnChange($event)"
            (infoItemClicked)="infoItemClicked.emit()"
            (selectWithShift)="selectWithShift.emit()"
            (selected)="selected.emit()"
            (unSelected)="unSelected.emit()"
            [actionBtnDisplay]="actionBtnDisplay()"
            [checked]="checked()"
            [colorItem]="colorItem()"
            [column]="$any(columnItem)"
            [config]="$any(config())"
            [item]="item()"
            [labelItem]="labelItem()"
            [shouldCheckEmpty]="shouldCheckEmpty()"
          />
        </span>
      }

      <!--------------------           image column                 ---------------------->
      @case (columnTypes.IMAGE) {
        <span class="value-container image">
        <sem-image-column
          #columnReference
          (editStateEntered)="editableModeChanged(true)"
          (editStateExited)="editableModeChanged(false)"
          (openPhotoFromColumn)="openPhotoFromColumn.emit($event)"
          (valueChanged)="valueChanged.emit($event)"
          [column]="$any(columnItem)"
          [displayNotEditable]="!isEditable()"
          [value]="value()"
          class="column-component"
        >
        </sem-image-column>
      </span>
      }

      <!--------------------           boolean columns                 ---------------------->
      @case (columnTypes.BOOLEAN) {
        <span class="value-container">
          <sem-boolean-column
            #columnReference
            (editStateEntered)="editableModeChanged(true)"
            (editStateExited)="editableModeChanged(false)"
            (valueChanged)="valueChanged.emit($event)"
            [column]="$any(columnItem)"
            [displayNotEditable]="!isEditable()"
            [value]="value()"
            class="column-component"
          />
        </span>
      }
      <!--------------------           autocomplete columns                 ---------------------->
      @case (columnTypes.AUTOCOMPLETE) {
        <span class="value-container">
          <sem-autocomplete-column
            #columnReference
            (autocompleteChanged)="autocompleteService.initAutocompleteChange$.next($event)"
            (editStateEntered)="editableModeChanged(true)"
            (editStateExited)="editableModeChanged(false)"
            (valueChanged)="valueChanged.emit($event)"
            [column]="$any(columnItem)"
            [displayNotEditable]="!isEditable()"
            [value]="value()"
            class="column-component"
          >
          </sem-autocomplete-column>
        </span>
      }

      <!--------------------           custom columns                 ---------------------->
      @case (columnTypes.CUSTOM) {
        <span class="value-container">
          <sem-custom-column
            #columnReference
            (editStateEntered)="editableModeChanged(true)"
            (editStateExited)="editableModeChanged(false)"
            (emitCustomEvent)="emitCustomEventOnColumn.emit($event)"
            (valueChanged)="valueChanged.emit($event)"
            [autocompleteService]="autocompleteService"
            [column]="$any(columnItem)"
            [displayNotEditable]="!isEditable()"
            [popupService]="popupService"
            [value]="value()"
            class="column-component"
          >
          </sem-custom-column>
        </span>
      }

      <!--------------------           Select columns                 ---------------------->
      @case (columnTypes.SELECT) {
        <span class="value-container">
          <sem-select-column
            #columnReference
            (editStateEntered)="editableModeChanged(true)"
            (editStateExited)="editableModeChanged(false)"
            (valueChanged)="valueChanged.emit($event)"
            [column]="$any(columnItem)"
            [displayNotEditable]="!isEditable()"
            [value]="value()"
            [withNullValue]="true"
            class="column-component"
          >
          </sem-select-column>
        </span>
      }

      <!--------------------           Date columns                 ---------------------->
      @case (columnTypes.DATE) {
        <span class="value-container">
          <sem-date-column
            #columnReference
            (editStateEntered)="editableModeChanged(true)"
            (editStateExited)="editableModeChanged(false)"
            (valueChanged)="valueChanged.emit($event)"
            [column]="$any(columnItem)"
            [displayNotEditable]="!isEditable()"
            [value]="value()"
            class="column-component"
          >
          </sem-date-column>
        </span>
      }

      <!--------------------           Simple columns                 ---------------------->
      @case (columnTypes.SIMPLE) {
        <span class="value-container can-selected">
          <sem-simple-column
            #columnReference
            (editStateEntered)="editableModeChanged(true)"
            (editStateExited)="editableModeChanged(false)"
            (navigationColumn)="navigationClicked.emit()"
            (valueChanged)="valueChanged.emit($event)"
            [column]="$any(columnItem)"
            [displayNotEditable]="!isEditable()"
            [value]="value()"
            class="column-component"
          >
          </sem-simple-column>
        </span>
      }
      @case (columnTypes.FILESIZE) {
        <span class="value-container can-selected">
        <sem-filesize-column #columnReference [value]="value()" class="column-component"> </sem-filesize-column>
      </span>
      }
    }

  } @else {
    <!--------------------           Component columns                 ---------------------->
    <span class="value-container component-column">
      <sem-component-column
        #columnReference
        (editStateEntered)="editableModeChanged(true)"
        (editStateExited)="editableModeChanged(false)"
        (valueChanged)="valueChanged.emit($event)"
        [column]="$any(columnItem)"
        [component]="$any(columnItem)?.component"
        [data]="{ item: item(), value: value() }"
        class="column-component"
      />
    </span>
  }

  @if (!isEditableModeSig()) {
    @if (isNavigationValue()) {
      <div class="navigation-btn helping-icons">
        <div class="content">
          @if (isEditable()) {
            <mat-icon (click)="$event.stopPropagation(); enterEditMode()">edit</mat-icon>
          }
          @if (item()?.id) {
            <mat-icon class="strong">chevron_right</mat-icon>
          }
        </div>
      </div>
    } @else {
      @if (columnItem?.type !== columnTypes.INNER && ((customButtons() && customButtons().length > 0) || (columnItem?.ableToFilter && value()) || isEditable())) {
        <div class="helping-icons">
          <div class="content">
            @for (button of customButtons(); track $index) {
              <mat-icon
                (click)="emitCustomButtonEvent(button)"
                [svgIcon]="button.svgIcon || null!"
                class="icon"
              >{{ button.icon || '' }}
              </mat-icon>
            }
            @if (columnItem?.canCopy || columnItem?.type === columnTypes.SIMPLE) {
              <mat-icon (click)="copyContent()">content_copy</mat-icon>
            }
            @if (columnItem?.canAiGenerated) {
              <mat-icon (click)="aiGeneratedClicked.emit()" class="icon" svgIcon="ai"></mat-icon>
            }
            @if (columnItem?.ableToFilter && value() && !columnItem?.disableDirectFilter) {
              <mat-icon (click)="initFilter.emit()">filter_alt</mat-icon>
            }
            @if (isEditable()) {
              <mat-icon (click)="$event.stopPropagation(); enterEditMode()">edit</mat-icon>
            }
          </div>
        </div>
      }
    }
  }
  <div class="empty"></div>
</div>
