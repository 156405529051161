/* eslint-disable no-underscore-dangle */
import { ElementRef } from '@angular/core';
import { TutorialElementDirective } from '../../tutorial-element.directive';
import { TutorialService } from '../../tutorial.service';
import { InnerAction } from './InnerAction';

export class ClickSemTableInnerAction extends InnerAction {
  tutorialDirective!: TutorialElementDirective;

  override activate(tutorialService: TutorialService): void {
    super.activate(tutorialService);
    setTimeout(() => this.findElementToAttach(tutorialService), 500);
  }

  private findElementToAttach(tutorialService: TutorialService) {
    const a: HTMLCollection = document.getElementsByClassName(this.idToHighlight!);
    const el: ElementRef = { nativeElement: a[0] };

    if (el.nativeElement) {
      this.attachDirectiveToElement(el, tutorialService);
    } else {
      setTimeout(() => this.findElementToAttach(tutorialService), 200);
    }
  }

  protected attachDirectiveToElement(el: ElementRef, tutorialService: TutorialService) {
    this.tutorialDirective = new TutorialElementDirective(el, this.renderer!);
    this.tutorialDirective.actionId = this.idToHighlight!;
    this.tutorialDirective.activatedAction$ = tutorialService.activatedAction$;
    this.tutorialDirective.ngOnInit();
  }

  override deactivate(): void {
    if (this.tutorialDirective) {
      this.tutorialDirective.ngOnDestroy();
    }
    this.tutorialDirective = null!;
  }
}
