import type { ConfigKeys, VisitConfig } from '../models/visit-config.model';

export const QUICKSTART_CONFIG: Record<ConfigKeys, keyof VisitConfig> = {
  ADVANCED_CHECK_PAGES: 'advancedCheckPages',
  ADVANCED_FIRST_PROCESS: 'advancedFirstProcess',
  ADVANCED_PRODUCT_DUPLICATE: 'advancedProductDuplicate',
  BASIC_CHOOSE_PACKAGE: 'basicChoosePackage',
  BASIC_FIRST_CONNECTION: 'basicFirstConnection',
  BASIC_FIRST_PROJECT: 'basicFirstProject',
  BASIC_ONBOARDING: 'basicOnboarding',
  BASIC_VISIT_COMMUNITY: 'basicVisitCommunity',
  CAMPAIGN_CREATE_FIRST: 'campaignCreateFirst',
  PRODUCTS_CHECK_PERFORMANCE: 'productsCheckPerformance',
  PRODUCTS_EXPORT_FEED: 'productsExportFeed',
  PRODUCTS_FIRST_RULE: 'productsFirstRule',
  PRODUCTS_IMPORT_FEED: 'productsImportFeed',
  PRODUCTS_TRACK_COMPETITOR: 'productsTrackCompetitor',
  PRODUCTS_TRACK_POSITION: 'productsTrackPosition',
  TRACKING_FIRST_PHASE: 'trackingFirstPhase',
  WORKSPACE_FIRST_TASK: 'workspaceFirstTask',
  WORKSPACE_INVITE_MEMBER: 'workspaceInviteMember',
  WORKSPACE_SET_UP_PROCESS: 'workspaceSetUpProcess',
  WORKSPACE_VISIT_DASHBOARD: 'workspaceVisitDashboard',
} as const;
