import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-prompt-template',
  templateUrl: './prompt-template.component.html',
  styleUrls: ['./prompt-template.component.scss'],
})
export class PromptTemplateComponent {
  @Input() id!: string;
  @Input() message!: string;
  @Output() responsed: EventEmitter<boolean> = new EventEmitter();

  constructor(private angularNotifierService: NotifierService) {}

  private close() {
    this.angularNotifierService.hide(this.id);
  }

  onAgree() {
    this.close();
    this.responsed.emit(true);
  }

  onDisagree() {
    this.close();
    this.responsed.emit(false);
  }
}
