export function sanitizeNumber(value: any): number {
  if (typeof value !== 'string') {
    return typeof value === 'number' ? value : 0;
  }

  value =
    value
      .replace(/^[^0-9]+/, '') // Usunięcie niepoprawnych znaków na początku
      .replace(/,/g, '.') // Zamiana przecinków na kropki
      .match(/\d+(\.\d+)?/)?.[0] || ''; // Pobranie pierwszej poprawnej liczby

  return value ? parseFloat(value) : 0;
}
