import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { ChartResultTypeInterface } from '../../model/monitor.model';
import { ChartService } from '../../sem-chart/chart.service';
import { ChartType } from '../../sem-chart/configuration/sem-chart-config';
import { DateRangeEnum, DateRangeService } from '../../service/date-range.service';
import { ChartApexOptions } from '../apex-chart/apex-chart.component';

@Component({
  selector: 'app-timeseries-chart-select',
  templateUrl: './timeseries-chart-select.component.html',
  styleUrls: ['./timeseries-chart-select.component.scss'],
  providers: [ChartService],
})
export class TimeseriesChartSelectComponent implements OnInit {
  @Output() dateRangeChange = new EventEmitter<{ start: Moment; end: Moment }>();
  protected selectedResultType!: ChartResultTypeInterface;
  protected chartOptions: Partial<ChartApexOptions> = {};
  protected selected: string | undefined;
  protected activeOptionButton = DateRangeEnum.ThreeMonths;
  protected range: FormGroup<{ start: FormControl<Moment | null>; end: FormControl<Moment | null> }>;
  protected readonly ChartType = ChartType;
  protected rangeEnum = DateRangeEnum;
  private fb = inject(FormBuilder);
  private translate = inject(TranslateService);
  private onDestroy$ = new Subject<void>();
  private readonly yAxisBuffer = 1.1;
  private dateRangeService = inject(DateRangeService);
  private chartService = inject(ChartService);

  isLoading: boolean = true;

  constructor() {
    const { minDate, maxDate } = this.dateRangeService.getDateRange(this.activeOptionButton);
    this.range = this.fb.group({
      start: this.fb.control<Moment | null>(moment(minDate)),
      end: this.fb.control<Moment | null>(moment(maxDate)),
    });
  }

  private _resultTypes!: ChartResultTypeInterface[];

  get resultTypes(): ChartResultTypeInterface[] {
    return this._resultTypes;
  }

  @Input() set resultTypes(data: ChartResultTypeInterface[]) {
    this._resultTypes = data;
    if (data.length) {
      this.selected = data[0].value;
      this.updateCharts(false);
    }
  }

  ngOnInit(): void {
    this.range.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        filter((rangeValues) => !!rangeValues.start && !!rangeValues.end),
        distinctUntilChanged(
          (prev, curr) => moment(prev.start).isSame(moment(curr.start), 'day') && moment(prev.end).isSame(moment(curr.end), 'day'),
        ),
      )
      .subscribe(() => {
        this.dateRangeChange.emit({ start: this.range.value.start!, end: this.range.value.end! });
      });
  }

  getRangeEnum(): { key: string; value: DateRangeEnum }[] {
    return Object.entries(this.rangeEnum).map(([key, value]) => ({ key, value }));
  }

  updateCharts(reloadChart: boolean = true) {
    if (!this.resultTypes || !this.selected) {
      console.error('No resultTypes or selected type available.');
      return;
    }

    const lang = this.translate.currentLang || 'en';
    const dateFormatter = new Intl.DateTimeFormat(lang, { day: '2-digit', month: 'short', year: 'numeric' });

    this.selectedResultType = this.resultTypes.find((resultType) => resultType.value === this.selected)!;

    if (!this.selectedResultType || !this.selectedResultType.dataset) {
      return;
    }

    const rawData = this.selectedResultType.dataset;
    let seriesData;

    switch (this.selectedResultType.value) {
      case 'avg': {
        seriesData = [
          {
            name: this.chartService.sanitizeString(this.selectedResultType.yAxisName),
            data: rawData.map((item) => ({
              x: moment(item.date).valueOf(),
              y: item.avg,
            })),
          },
        ];
        break;
      }
      default: {
        const valueKey = this.selectedResultType.value;
        if (valueKey) {
          const seriesMap: { [key: string]: { name: string; data: { x: number; y: number }[] } } = {};

          rawData.forEach((item) => {
            const seriesKey = this.chartService.sanitizeString(item.key);
            if (!seriesMap[seriesKey]) {
              seriesMap[seriesKey] = {
                name: seriesKey,
                data: [],
              };
            }
            seriesMap[seriesKey].data.push({
              x: moment(item.date).valueOf(),
              y: item[valueKey],
            });
          });

          seriesData = Object.values(seriesMap);
        } else {
          console.error(`Unsupported result type: ${this.selectedResultType.value}`);
        }
        break;
      }
    }

    let strokeSettings: { width: number; dashArray: number; curve: string }[] | undefined;

    if (seriesData) {
      strokeSettings = seriesData.map((_series: any, index: number) => ({
        width: 2,
        dashArray: index >= 2 ? 3 : 0,
        curve: 'smooth',
      }));
    }

    const minDate = this.range.value.start ? moment(this.range.value.start).valueOf() : undefined;
    const maxDate = this.range.value.end ? moment(this.range.value.end).valueOf() : undefined;

    const maxYValue = Math.max(...rawData.map((item) => item[this.selectedResultType.value]));
    const yAxisMax = maxYValue * this.yAxisBuffer;

    this.chartOptions = {
      series: this.chartService.normalizeSeriesData(seriesData!),
      chart: {
        type: ChartType.line,
        height: 350,
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
        animations: {
          enabled: reloadChart,
        },
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: 'Date',
        },
        min: minDate,
        max: maxDate,
        labels: {
          formatter: (value: string) => {
            const timestamp = parseInt(value, 10);
            return dateFormatter.format(moment(timestamp).toDate());
          },
        },
      },
      stroke: strokeSettings
        ? {
            width: strokeSettings.map((setting) => setting.width),
            curve: 'smooth',
            dashArray: strokeSettings.map((setting) => setting.dashArray),
          }
        : undefined,
      yaxis: {
        title: {
          text: this.selectedResultType.yAxisName,
        },
        max: yAxisMax,
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: false,
        x: {
          formatter: (val: number) => dateFormatter.format(moment(val).toDate()),
        },
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'solid',
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
    };

    if (!reloadChart) {
      this.disableLoadingState();
    }
  }

  public updateOptions(range: DateRangeEnum): void {
    const { minDate, maxDate } = this.dateRangeService.getDateRange(range);

    this.range.setValue(
      {
        start: minDate ? moment(minDate) : null,
        end: moment(maxDate),
      },
      { emitEvent: false },
    );

    this.activeOptionButton = range;
  }

  enableLoadingState() {
    this.isLoading = true;
  }

  disableLoadingState() {
    this.isLoading = false;
  }
}
