import { Component, Input, OnChanges, inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TriggerDefaultParamsInterface } from '../../../dashboard/tasks/triggers/triggers.model';
import { InputForm } from '../../validators/input-validation';

export enum ParamTypeEnum {
  float = 'float',
  int = 'int',
  string = 'string',
}

export enum ParamFieldEnum {
  input = 'input',
  select = 'select',
  multiselect = 'multiselect',
}

export interface FormFieldParamDataInterface {
  id: string | number;
  name: string | number;
  value: any;
}

export interface FormFieldParamInterface {
  data?: FormFieldParamDataInterface[];
  field: ParamFieldEnum;
  type: ParamTypeEnum; // NULL for select & multiselect
  value?: any;
  isRequired: boolean;
  symbol: string | null;
}

interface ItemInterface {
  key?: string; // @TODO: enum
  data: FormFieldParamInterface | string;
  isText: boolean;
}

@Component({
  selector: 'app-form-fields-in-text',
  templateUrl: './form-fields-in-text.component.html',
  styleUrls: ['./form-fields-in-text.component.scss'],
})
export class FormFieldsInTextComponent implements OnChanges {
  @Input() text!: string;
  @Input() params!: FormFieldParamInterface;
  @Input() defaultValues!: TriggerDefaultParamsInterface;
  form!: FormGroup;
  items: ItemInterface[] = [];
  paramFieldEnum = ParamFieldEnum;
  inputTypeAttribute = {
    [ParamTypeEnum.float]: 'number',
    [ParamTypeEnum.int]: 'number',
    [ParamTypeEnum.string]: 'text',
  };

  private formBuilder = inject(UntypedFormBuilder);

  ngOnChanges() {
    this.prepareFormFieldsInText();
  }

  onChange(item1: FormFieldParamDataInterface, item2: FormFieldParamDataInterface) {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  getStep(type: ParamTypeEnum): string | null {
    switch (type) {
      case ParamTypeEnum.float:
        return '0.1';
      case ParamTypeEnum.int:
        return '1';
      default:
        return null;
    }
  }

  compareWithFn(item1: FormFieldParamDataInterface, item2: FormFieldParamDataInterface) {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  getPlaceholder(type: ParamTypeEnum): string {
    switch (type) {
      case ParamTypeEnum.float:
        return '0.0';
      case ParamTypeEnum.int:
        return '0';
      default:
        return '';
    }
  }

  private prepareFormFieldsInText() {
    this.form = this.formBuilder.group({});
    this.items = [];

    const separator = '##';
    let preparedTexts = this.text;
    const params = this.params || {};

    params &&
      Object.keys(params).length &&
      Object.entries(params).forEach(([key]) => {
        preparedTexts = preparedTexts.replace(`:${key}`, `${separator}${key}${separator}`);
      });

    preparedTexts.split(separator).forEach((item: string) => {
      const data: FormFieldParamInterface = params[item as keyof typeof params] || null;
      let itemObj: ItemInterface = { data: item, key: null!, isText: true };

      if (data) {
        itemObj = { key: item, data, isText: false };

        const defaultValue = this.getDefaultValue(item, data.type, data.value);

        const formControl = new FormControl(defaultValue, [data.isRequired ? Validators.required : Validators.nullValidator]);

        if (formControl) {
          switch (data.type) {
            case ParamTypeEnum.float:
              formControl.addValidators(InputForm.isFloat());
              break;
            case ParamTypeEnum.int:
              formControl.addValidators(InputForm.isInteger());
              break;
          }
        }
        this.form.addControl(item, formControl);
      }

      this.items.push(itemObj);
    });
  }

  private getDefaultValue(_item: string, type: ParamTypeEnum, fieldValue: any): any {
    if (fieldValue !== undefined && fieldValue !== null) {
      return fieldValue;
    }

    switch (type) {
      case ParamTypeEnum.int:
        return this.defaultValues?.days_amount;
      case ParamTypeEnum.float:
        return this.defaultValues?.ctr_threshold;
      default:
        return '';
    }
  }
}
