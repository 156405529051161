import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class IsEmptyFilterModel extends FilterModel {
  override symbol: FilterTypesEnum = FilterTypesEnum.is_empty;

  isValid() {
    return true;
  }
}
