import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { ActionTypesEnum } from '../../../../action/ActionTypesEnum';
import { filterTypesGroups, mainTableColumnDefaultGroup } from '../../../../enums';
import { FilterTypesEnum } from '../../../../filters/FilterTypesEnum';
import { ItemModel } from '../../../../item.model';
import { CustomButtonPerItemInterface } from '../../../../models/CustomButton.model';
import { ToolInterface } from '../../../../models/TableConfigurationInterface.model';
import { AgregationInterface } from '../../../../models/agregation.model';
import { ColumnTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnModel } from '../column.model';

export class DateColumn extends ColumnModel {
  datePipe: DatePipe = new DatePipe('en-US');
  override filterAvailableTypes: FilterTypesEnum[] = filterTypesGroups.default;
  format: string;
  pickerType: 'both' | 'calendar' | 'timer' = 'both';
  rangeType: boolean;
  override type = ColumnTypesEnum.DATE;
  checkOverdue?: boolean;

  constructor(config: {
    ableToFilter?: boolean;
    actionsAvailable?: ActionTypesEnum[];
    aggregate?: boolean;
    aggregateBySelect?: AgregationInterface;
    alias?: string;
    canBeSorted?: boolean;
    customButton?: CustomButtonPerItemInterface[];
    editable?: ToolInterface;
    format?: string;
    group?: string;
    isValueToTranslate?: boolean;
    rangeType?: boolean;
    required?: boolean;
    tooltip?: string;
    disabledIn?: {
      duplicatePopup?: boolean;
      editPopup?: boolean;
      addPopup?: boolean;
      usingAsFilterInMassOperations?: boolean;
    };
    checkOverdue?: boolean;
  }) {
    super();
    this.ableToFilter = config.ableToFilter;
    this.actionsAvailableTypes = config.actionsAvailable ? config.actionsAvailable : this.actionsAvailableTypes;
    this.aggregate = config.aggregate;
    this.aggregateBySelect = config.aggregateBySelect;
    this.alias = config.alias || '';
    this.canBeSorted = config.canBeSorted;
    this.customButton = config.customButton;
    this.disabledIn = config.disabledIn;
    this.editable = config.editable;
    this.format = config.format || 'HH:mm dd/MM/yyyy';
    this.group = config.group || mainTableColumnDefaultGroup;
    this.isValueToTranslate = config.isValueToTranslate || this.isValueToTranslate;
    this.pickerType = this.format.toLowerCase().includes('h') ? 'both' : 'calendar'; // W razie potrzeby dorobić wariant 'timer'
    this.rangeType = !!config.rangeType;
    this.required = config.required!;
    this.tooltip = config.tooltip;
    this.checkOverdue = config.checkOverdue ?? false;
  }

  returnValueForDisplay(item: ItemModel): string | null {
    return this.datePipe.transform(item[this.param as keyof typeof item] as string, this.format);
  }

  returnValue(item: ItemModel): string {
    return item[this.param as keyof typeof item] as string;
  }

  returnPossibleValues(): Observable<any> {
    return of(null);
  }

  override map(value: any): string {
    let transformValue = '';

    if (value) {
      if (Array.isArray(value)) {
        const range = value.map((val) => this.datePipe.transform(val, this.format));
        transformValue = range[0] + ' - ' + range[1];
      } else {
        transformValue = this.datePipe.transform(value, this.format)!;
      }
    }

    return transformValue;
  }
}
