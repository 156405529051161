import { Injectable, OnDestroy, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

export interface RightSidebarDataType {
  id?: number;
  scrollToElemId?: string;
  titleKey?: string;
  type: 'task-card' | 'task-templates-list' | 'summary';
}

@Injectable({
  providedIn: 'root',
})
export class RightSidebarService implements OnDestroy {
  data$ = new BehaviorSubject<RightSidebarDataType | null>(null);
  updated$ = new Subject<RightSidebarDataType>();

  readonly openedState = signal(false);
  readonly opened = this.openedState.asReadonly();
  readonly pinnedContainerState = signal(false);
  readonly isPinned = this.pinnedContainerState.asReadonly();
  private currentDataTag!: string | null;
  private onDestroy$ = new Subject<void>();

  clearData() {
    this.setData(null);
  }

  closeSidenav() {
    this.openedState.set(false);
  }

  showTask(id: number, scrollToElemId: string = ''): Observable<RightSidebarDataType> {
    const data: RightSidebarDataType = { type: 'task-card', id, scrollToElemId };
    this.setData(data);
    return this.observeDataUpdates(data);
  }

  showTemplatesList(): Observable<RightSidebarDataType> {
    const data: RightSidebarDataType = { type: 'task-templates-list', titleKey: 'tasks.templates.title' };
    this.setData(data);
    return this.observeDataUpdates(data);
  }

  toggleSidenav() {
    this.openedState.set(!this.openedState());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggle() {
    const data = this.data$.getValue();
    this.setData(data);
  }

  showSummary(data: RightSidebarDataType): Observable<RightSidebarDataType> {
    this.setData(data);
    return this.observeDataUpdates(data);
  }

  private observeDataUpdates(data: RightSidebarDataType): Observable<RightSidebarDataType> {
    return this.updated$.asObservable().pipe(
      takeUntil(this.onDestroy$),
      takeWhile(() => this.prepareDataTag(data) === this.currentDataTag),
    );
  }

  private setData(data: RightSidebarDataType | null) {
    this.openedState.set(data !== null);
    this.currentDataTag = this.prepareDataTag(data!);
    this.data$.next(data);

    data?.scrollToElemId && setTimeout(() => this.scrollToElementId((data.scrollToElemId || '').trim()), 2500);
    // @TODO: Zastąpić timeouta jakąś alternatywą..
  }

  private prepareDataTag(data: RightSidebarDataType): string | null {
    if (!data?.type) {
      return null;
    }

    const { id, type } = data;
    return type + (id ? `-${id}` : '');
  }

  private scrollToElementId(elemId: string) {
    if (elemId) {
      const el = document?.getElementById(elemId);
      el && el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }

  handlePinContainer(): void {
    this.pinnedContainerState.set(!this.pinnedContainerState());
  }

  closeContainerIfUnpinned(): void {
    if (!this.isPinned() && this.opened()) {
      this.closeSidenav();
    }
  }
}
