import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import apiUrlsConfig from 'src/app/configs/api-urls.config';
import { ProjectService } from '../../project.service';

type ImageAsset = any;

@Injectable({
  providedIn: 'root',
})
export class ImageUploaderService {
  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
  ) {}

  uploadImages(files: File[]): Observable<ImageAsset> {
    const project = this.projectService.activeProject$.getValue()!.id;
    const data = new FormData();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(files).forEach(([_key, file]) => data.append('images[]', file, file.name || null!));

    return this.http.post<void>(apiUrlsConfig.projectStoreImageAsset.prepareUrl({ project }), data);
  }
}
