import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ItemModel } from '../item.model';

export interface RowItemModel {
  columnName: string | null;
  item: ItemModel;
  scrollTo?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RowItemsService {
  readonly editModeForItemCell$ = new Subject<RowItemModel>();
  readonly scrollToRowItem$ = new Subject<ItemModel>();

  scrollToRowItem(data: any) {
    const item = data as ItemModel;
    this.scrollToRowItem$.next(item);
  }

  setEditModeForCell(data: object, columnName: string, scrollTo = false) {
    const item = data as ItemModel;
    this.editModeForItemCell$.next({ item, columnName, scrollTo });
  }
}
