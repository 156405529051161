import { Injectable, inject } from '@angular/core';
import { isShopifyEmbedded } from '@shopify/app-bridge-utils';
import { ProjectService } from '../../dashboard/project/project.service';

export const sessionStorageKey = {
  firstLogin: 'firstLogin',
  locale: 'locale',
  pagesAnalyticsData: 'pagesAnalyticsData',
  paymentPlanParams: 'paymentPlanParams',
  productsAnalyticsData: 'productsAnalyticsData',
  rightSidebarWidth: 'rightSidebarWidth',
  startPage: 'startPage',
  token: 'token',
  welcomeDialog: 'welcomeDialog',
};

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private isPluginMode = isShopifyEmbedded();
  private readonly unremovableKeys = [sessionStorageKey.locale];
  private readonly projectService = inject(ProjectService);

  public clear() {
    const unremovableItems = this.unremovableKeys.map((key) => [key, this.get(key)]);
    sessionStorage.clear();
    unremovableItems.forEach(([key, item]) => this.save(key, item));
  }

  public get(name: string) {
    if (this.isPluginMode) {
      return null;
    }
    return JSON.parse(sessionStorage.getItem(name)!);
  }

  public remove(name: string) {
    if (this.isPluginMode) {
      return;
    }
    sessionStorage.removeItem(name);
  }

  public save(name: string, data: any) {
    Object.keys(data).length ? sessionStorage.setItem(name, JSON.stringify(data)) : this.remove(name);
  }

  public getForCurrentProject(name: string) {
    const currentProjectId = this.projectService.activeProject$.getValue()!.id.toString();
    return this.getNested(name, currentProjectId);
  }

  public saveForCurrentProject(name: string, data: any) {
    const currentProjectId = this.projectService.activeProject$.getValue()!.id.toString();
    return this.saveNested(name, currentProjectId, data);
  }

  public removeForCurrentProject(name: string) {
    const currentProjectId = this.projectService.activeProject$.getValue()!.id.toString();
    return this.removeNested(name, currentProjectId);
  }

  private getNested(name: string, nested: string) {
    if (this.isPluginMode) {
      return null;
    }

    const data = this.get(name);

    if (data && data[nested]) {
      return data[nested];
    }
    return null;
  }

  private removeNested(name: string, nested: string) {
    if (this.isPluginMode) {
      return;
    }

    let obj = {};
    if (this.exist(name)) {
      obj = this.get(name);
      delete obj[nested as keyof typeof obj];

      this.save(name, obj);
    }
  }

  private saveNested(name: string, nested: string, data: any) {
    if (this.isPluginMode) {
      return null;
    }
    let obj = {};
    if (this.exist(name)) {
      obj = this.get(name);
    }

    (obj[nested as keyof typeof obj] as any) = data;

    return this.save(name, obj);
  }

  private exist(name: string): boolean {
    if (this.isPluginMode) {
      return false;
    }
    return sessionStorage.getItem(name) !== null;
  }
}
