import { ItemModel } from '../../item.model';
import { DynamicValue } from '../../models/DynamicValue.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class OverwriteExtendedAction extends ActionModel {
  override type = ActionTypesEnum.generateValue;
  override value!: DynamicValue[];

  isValid(): boolean {
    return !!this.columnName && !!this.value && !!this.value[0];
  }

  use(item: ItemModel) {
    let res = '';
    this.value.forEach((data) => {
      if (data.fromItem) {
        res += item[data.value as keyof typeof item];
      } else {
        res += data.value;
      }
    });
    return res;
  }

  returnForSend() {
    const values = this.value.map((value) => (value.fromItem ? { param: value.value } : { value: value.value }));
    return {
      action: this.type,
      param: this.columnName,
      values,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
    this.value = item.values;
  }
}
