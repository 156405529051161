import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, of } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { ActionPopupComponent } from '../action/action-popup/action-popup.component';
import { ActionService } from '../action/action.service';
import { ActionModel } from '../action/models/ActionModel';
import { ActionPopupDataInterface } from '../action/models/ActionPopupData.interface';
import { ActionFormEmittedDataInterface } from '../action/models/ActionPopupEmittedData.interface';
import { FilterService } from '../filters/filters.service';
import { FilterFactoryService } from '../filters/filtersFactory.service';
import { FiltersManagerPopupComponent } from '../filters/modals/filters-manager-popup/filters-manager-popup.component';
import { ItemModel } from '../item.model';
import { DuplicateByComponent } from '../modals/duplicate-by/duplicate-by.component';
import { ItemPopupComponent } from '../modals/item-popup/item-popup.component';
import { PhotoPopupComponent } from '../modals/photo-popup/photo-popup.component';
import { SaveAsPopupComponent, SaveAsPopupData } from '../modals/save-as-popup/save-as-popup.component';
import { UploadPhotoPopupComponent } from '../modals/upload-photo-popup/upload-photo-popup.component';
import { FilterManagerPopupData } from '../models/modals/FilterManagerPopupDataInterface';
import { ItemPopupDataInterface } from '../models/modalsData.model';
import { ImageColumn } from '../table-display/columns-switch/columns/image-column/ImageColumn';
import { AutocompleteService } from './autocomplete.service';
import { ColumnsService } from './columns.service';
import { CommunicationService } from './communication.service';
import { ConfigService } from './config.service';
import { HelperService } from './helper.service';
import { SelectedService } from './selected.service';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private autocompleteService: AutocompleteService,
    private columnsService: ColumnsService,
    private configService: ConfigService,
    private filterService: FilterService,
    private filterFactoryService: FilterFactoryService,
    private actionService: ActionService,
    private selectService: SelectedService,
    public helperService: HelperService,
    private translateService: TranslateService,
  ) {}

  openAddItem(waitForApiResponse: boolean = true): Observable<ItemModel> {
    const {
      configService: { config },
      communicationService,
      autocompleteService,
    } = this;
    const columnNames = Object.keys(config.columns).filter((columnName) => !config.columns[columnName].disabledIn?.addPopup);
    const itemToEmit$: Subject<ItemModel> = new Subject();
    const type = 'add';
    const data = {
      item: { id: null! },
      type,
      config,
      itemToEmit$,
      columnNames,
      autocompleteService,
      communicationService,
      waitForApiResponse,
      popupService: this,
    } as ItemPopupDataInterface;

    const dialogRef = this.dialog.open(ItemPopupComponent, {
      data,
      panelClass: 'no-dialog-spinner',
      width: '90vw',
      autoFocus: true,
    });
    return itemToEmit$.pipe(takeUntil(dialogRef.afterClosed()));
  }

  openEditItem(item: ItemModel, waitForApiResponse: boolean = false): Observable<ItemModel> {
    const {
      configService: { config },
      columnsService: { allColumns },
      communicationService,
      autocompleteService,
    } = this;

    const itemToEmit$: Subject<ItemModel> = new Subject();
    const type = 'edit';
    const columnNames = allColumns
      .filter((columnName) => config.columns[columnName].isEditableInItem(item))
      .filter((columnName) => !config.columns[columnName].disabledIn?.editPopup);
    const data = {
      item,
      type,
      config,
      itemToEmit$,
      columnNames,
      autocompleteService,
      communicationService,
      waitForApiResponse,
      popupService: this,
    } as ItemPopupDataInterface;

    const dialogRef = this.dialog.open(ItemPopupComponent, {
      data,
      panelClass: 'no-dialog-spinner',
      width: '90vw',
      autoFocus: true,
    });
    return itemToEmit$.pipe(takeUntil(dialogRef.afterClosed()));
  }

  openDuplicateItem(item: ItemModel, waitForApiResponse: boolean = true): Observable<ItemModel> {
    const {
      configService: { config },
      columnsService: { allColumns },
      communicationService,
      autocompleteService,
    } = this;
    const itemToEmit$: Subject<ItemModel> = new Subject();
    const type = 'duplicate';
    const columnNames = allColumns
      .filter((columnName) => config.columns[columnName].isEditableInItem(item))
      .filter((columnName) => !config.columns[columnName].disabledIn?.duplicatePopup);
    const data = {
      item,
      type,
      config,
      itemToEmit$,
      columnNames,
      autocompleteService,
      communicationService,
      waitForApiResponse,
      popupService: this,
    } as ItemPopupDataInterface;

    const dialogRef = this.dialog.open(ItemPopupComponent, {
      data,
      panelClass: 'no-dialog-spinner',
      width: '90vw',
      autoFocus: true,
    });
    return itemToEmit$.pipe(takeUntil(dialogRef.afterClosed()));
  }

  openDuplicateBy(columnName: string): Observable<string[]> {
    const config = {
      data: this.configService.config.columns[columnName],
      minWidth: '400px',
      panelClass: 'no-dialog-spinner',
      width: 'auto',
    } as MatDialogConfig;
    const dialogRef = this.dialog.open(DuplicateByComponent, config);

    return dialogRef.afterClosed().pipe(filter((data) => data));
  }

  openSaveRulePopup(action?: ActionModel): void {
    const {
      actionService,
      autocompleteService,
      communicationService,
      configService: { config },
      filterService,
      selectService,
    } = this;
    const data: ActionPopupDataInterface = {
      actionPopupState: {
        saveRule: true,
        useAction: false,
        saveAndUse: false,
      },
      actionService,
      autocompleteService,
      communicationService,
      config,
      filterService,
      popupService: this,
      selectService,
      waitForApiResponse: true,
      initAction: action,
    };

    this.dialog.open(ActionPopupComponent, { panelClass: 'no-dialog-spinner', data });
  }

  openActionPopup(): Observable<ActionFormEmittedDataInterface> {
    if (this.selectService.isSelectedGlobally && !this.filterService.checkIfFiltersAreAllowedInMassOperations()) {
      this.communicationService.customMessage$.next({ type: 'error', case: 'DISABLED_IN_MASS_OPERATIONS' });
      return of();
    }

    const {
      actionService,
      autocompleteService,
      communicationService,
      configService: { config },
      filterService,
      selectService,
    } = this;
    const data: ActionPopupDataInterface = {
      actionPopupState: {
        saveRule: false,
        useAction: true,
        saveAndUse: false,
      },
      actionService,
      autocompleteService,
      communicationService,
      config,
      filterService,
      popupService: this,
      selectService,
    };
    const dialogRef = this.dialog.open(ActionPopupComponent, { data, minWidth: '500px' });
    return dialogRef.afterClosed().pipe(filter((res) => res));
  }

  openPhotoDisplayPopup(columnName: string, item: ItemModel): Observable<void> {
    const {
      configService: { config },
    } = this;
    const additionalData: unknown[] = [];
    const column = config.columns[columnName] as ImageColumn;
    column.imageData!.forEach((param) => additionalData.push(item[param as keyof typeof item]));
    const photo = item[column.imageToDisplay as keyof typeof item];

    const dialogRef = this.dialog.open(PhotoPopupComponent, {
      data: { photo, additionalData },
    });

    return dialogRef.afterClosed();
  }

  openPhotoEditPopup(_columnName: string, item: ItemModel): Observable<any> {
    const dialogRef = this.dialog.open(UploadPhotoPopupComponent, {
      data: item,
      disableClose: true,
      panelClass: 'no-dialog-spinner',
    });

    return dialogRef.afterClosed().pipe(filter((f) => !!f));
  }

  openStorageSaveAsPopup(type: 'column' | 'filters'): Observable<string> {
    const data: SaveAsPopupData = {
      title:
        type === 'column'
          ? this.translateService.instant('sem_table.storage_save_column_title')
          : this.translateService.instant('sem_table.storage_save_filters_title'),
      subtitle: this.translateService.instant('sem_table.storage_save_subtitle'),
      type,
    };
    const config = {
      width: '600px',
      panelClass: 'no-dialog-spinner',
      data,
    } as MatDialogConfig;
    const dialogRef = this.dialog.open(SaveAsPopupComponent, config);

    return dialogRef.afterClosed().pipe(filter((f) => !!f));
  }

  openSaveFilterStoragePopup(): Observable<{ name: string; saveAsTab: boolean }> {
    const data: SaveAsPopupData = {
      title: this.translateService.instant('sem_table.storage_save_filters_title'),
      subtitle: this.translateService.instant('sem_table.storage_save_subtitle'),
      type: 'filters',
    };
    const config = {
      width: 'auto',
      panelClass: 'no-dialog-spinner',
      data,
    } as MatDialogConfig;
    const dialogRef = this.dialog.open(SaveAsPopupComponent, config);

    return dialogRef.afterClosed().pipe(filter((f) => !!f));
  }

  openFilterManagerPopup() {
    const {
      configService,
      filterService,
      helperService,
      actionService,
      columnsService,
      selectService,
      autocompleteService,
      filterFactoryService,
      communicationService,
    } = this;

    const data = {
      configService,
      autocompleteService,
      actionService,
      filterService,
      selectService,
      helperService,
      columnsService,
      popupService: this,
      filterFactoryService,
      communicationService,
    } as FilterManagerPopupData;

    const dialogRef = this.dialog.open(FiltersManagerPopupComponent, {
      data,
      minWidth: '600px',
      panelClass: 'no-dialog-spinner',
    });

    return dialogRef.afterClosed().pipe(
      first(),
      tap(() => this.selectService.unselectAll()),
    );
  }
}
