<form [formGroup]="form" (ngSubmit)="submitForm()">
  <mat-form-field>
    <mat-label>
      {{ 'profile.settings.phone.label' | translate }}
    </mat-label>
    <input id="phone" type="tel" formControlName="phone" matInput/>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>
  <button type="submit" [disabled]="form.invalid || form.pristine" color="primary" mat-raised-button>
    {{ 'general.save' | translate }}
  </button>
</form>
