import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UrlVariablesType } from '../../model/helpers.model';
import { RouteInterface } from '../../model/router-config.model';
import { NavigateParams, RouterService } from '../../service/router.service';
import { BetaLabelComponent } from '../beta-label/beta-label.component';
import { GuidePopupComponent } from '../guide-popup/guide-popup.component';

@Component({
  selector: 'app-page-wrapper',
  standalone: true,
  imports: [BetaLabelComponent, GuidePopupComponent, MatCardModule, MatIconModule, TranslateModule],
  styleUrls: ['./page-wrapper.component.scss'],
  templateUrl: './page-wrapper.component.html',
})
export class PageWrapperComponent {
  @Input() isBeta = false;
  @Input() subtitle!: string;
  @Input() title!: string;
  @Input() backButton: {
    route: RouteInterface;
    urlVariables?: UrlVariablesType;
    params?: NavigateParams;
  };

  constructor(private routerService: RouterService) {
    this.backButton = { route: null! };
  }

  onBackClick() {
    const { route, urlVariables = null, params = new NavigateParams() } = this.backButton;

    if (route) {
      this.routerService.navigate(route, urlVariables!, params);
    }
  }
}
