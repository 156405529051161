<div class="tree-list">
  <ul *ngIf="items" class="parent">
    @for (item of items; track $index) {
      <li>
        <ng-container [ngTemplateOutletContext]="{ item }" [ngTemplateOutlet]="itemTemp"></ng-container>
      </li>
    } @empty {
      <li class="no-items">
        {{ 'general.no_data' | translate }}
      </li>
    }
  </ul>
  <div *appSpinner="!items; diameter: 18"></div>
</div>

<ng-template #itemTemp let-childCounter="item.children?.length || 0" let-item="item">
  @if (isVisible(item)) {
    <div
      (click)="clickItem(item)"
      *appHasPermissions="item.route?.permissions || []; superior: item.route?.superiorPermission || null"
      [appRouter]="item.route?.key || null"
      [childRouteKeys]="item.route?.childKeys || null"
      [ngClass]="{ active: item.isActive }"
      [routeActiveClass]="item.noRouteActiveClass ? null! : item.children ? 'parent-active' : 'active'"
      [urlVars]="item.urlVars || null"
      class="item"
    >
      <app-icon *ngIf="item.icon?.name" [isSvg]="item.icon?.isSvg || false" [name]="item.icon.name" class="icon"></app-icon>

      <div class="labels">
        <span class="label">
          {{ item.withoutTranslation ? item.label : (item.label | translate) }}
          <strong *ngIf="item.showChildCounter && childCounter" class="counter">({{ childCounter }})</strong>
        </span>
        <span *ngIf="item.subLabel" class="sub-label">{{ item.withoutTranslation ? item.subLabel : (item.subLabel | translate) }}</span>
      </div>

      <div class="expand">
        <mat-icon *ngIf="childCounter">
          {{ item.isExpanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </div>
      @let content = itemTemplate();
      @if (content) {
        <ng-container [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="content"></ng-container>
      }
    </div>

    @if (item.isExpanded && childCounter) {
      <ul class="child">
        @for (childItem of item.children; track $index) {
          <li class="parent">
            <ng-container [ngTemplateOutletContext]="{ item: childItem }" [ngTemplateOutlet]="itemTemp"></ng-container>
          </li>
        }
      </ul>
    }
  }
</ng-template>
