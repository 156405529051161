import { CdkTrapFocus } from '@angular/cdk/a11y';
import { Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { NotificationService } from 'src/app/notification/notification.service';
import { AppFormFieldErrorDisplayComponent } from 'src/app/shared/components/app-form-field-error-display/app-form-field-error-display.component';
import { ButtonComponent } from 'src/app/shared/components/button-with-spinner/button.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { passwordStrengthValidator } from '../../shared/helpers/utils';
import { FormApiValidationError } from '../../shared/model/errors/formApiError.model';
import { BrandLogosComponent } from '../brand-logos/brand-logos.component';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  standalone: true,
  imports: [
    BrandLogosComponent,
    TranslatePipe,
    ButtonComponent,
    MatFormFieldModule,
    MatIcon,
    MatInput,
    AppFormFieldErrorDisplayComponent,
    ReactiveFormsModule,
    CdkTrapFocus,
    MatIconButton,
  ],
})
export class SetPasswordComponent implements OnInit {
  protected isLoading = false;
  protected form: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required, passwordStrengthValidator]),
    password_confirmation: new UntypedFormControl('', [Validators.required, passwordStrengthValidator]),
  });

  protected isPasswordVisible = false;
  protected isPasswordConfirmationVisible = false;
  private token!: string;
  private notificationService = inject(NotificationService);
  private authService = inject(AuthService);
  private routerService = inject(RouterService);
  private route = inject(ActivatedRoute);

  protected get password() {
    return this.form.get('password') as UntypedFormControl;
  }

  protected get passwordConfirmation() {
    return this.form.get('password_confirmation') as UntypedFormControl;
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams['token'];
  }

  protected onSubmit() {
    if (this.form.invalid) {
      this.notificationService.error('invalid_form');
      return;
    }

    if (this.password.value !== this.passwordConfirmation.value) {
      this.notificationService.error('the_same_passwords');
      return;
    }
    this.isLoading = true;

    this.authService.resetPassword(this.token, this.password.value, this.passwordConfirmation.value).subscribe(
      () => {
        this.notificationService.success('reset_password');
        this.isLoading = false;
        this.routerService.navigate(routerConfig.authLogin);
      },
      (er: FormApiValidationError) => {
        this.isLoading = false;
        er.setOnForm && er.setOnForm(this.form);
        this.notificationService.error('reset_password');
      },
    );
  }
}
