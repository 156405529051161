import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export interface ModifyLinkActionPayload {
  name: string;
  value: string;
  delete: boolean;
  skip_existing: boolean;
}

export class ModifyLinkAction extends ActionModel {
  override type = ActionTypesEnum.modifyLink;
  override value: ModifyLinkActionPayload = {
    name: '',
    value: '',
    delete: false,
    skip_existing: false,
  };

  isValid(): boolean {
    if (!this.value) {
      return false;
    }

    const { name, value, delete: deleteValue } = this.value;
    return !!this.columnName && deleteValue ? !!name : !!(name && value);
  }

  use(item: ItemModel) {
    try {
      const currentUrl = item[this.columnName as keyof typeof item] as string;
      const urlObject = new URL(currentUrl);
      return this.value.delete ? this.removeParamLogic(urlObject, currentUrl) : this.addParamLogic(urlObject, currentUrl);
    } catch (_) {
      return item[this.columnName as keyof typeof item];
    }
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
  }

  private removeParamLogic(url: URL, currentValue: string) {
    const domain = this.getDomainFromUrl(url);
    const currentParametr = this.getParamsFromUrl(url);
    if (currentParametr[this.value.name as keyof typeof currentParametr]) {
      delete currentParametr[this.value.name as keyof typeof currentParametr];
    }
    let newParameters = Object.values(currentParametr).length ? '?' : '';
    Object.keys(currentParametr).forEach(
      (key: string, i: number) =>
        (newParameters = `${newParameters}${i > 0 ? '&' : ''}${key}=${currentParametr[key as keyof typeof currentParametr]}`),
    );
    return currentValue ? domain + newParameters : '';
  }

  private addParamLogic(url: URL, currentValue: string) {
    const domain = this.getDomainFromUrl(url);
    const currentParametr = this.getParamsFromUrl(url);
    if (!this.value.skip_existing || !currentParametr[this.value.name as keyof typeof currentParametr]) {
      (currentParametr[this.value.name as keyof typeof currentParametr] as string) = this.value.value;
    }
    let newParameters = '?';
    Object.keys(currentParametr).forEach(
      (key: string, i: number) =>
        (newParameters = `${newParameters}${i > 0 ? '&' : ''}${key}=${currentParametr[key as keyof typeof currentParametr]}`),
    );
    return currentValue ? domain + newParameters : '';
  }

  private getDomainFromUrl(url: URL) {
    const { protocol, host, pathname } = url;
    return `${protocol}//${host}${pathname}`;
  }

  private getParamsFromUrl(url: URL) {
    const params = {};
    url.searchParams.forEach((val, key) => ((params[key as keyof typeof params] as string) = val));
    return params;
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      value: [this.value],
    };
  }
}
