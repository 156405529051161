import {
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexStroke,
  ApexTooltip,
  ApexLegend,
  ApexGrid,
  ApexTheme,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { Y_LINES_COLORS, ChartType } from './sem-chart-config';

const COMMON_FONT_STYLE = {
  fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
  fontWeight: 400,
};

const COMMON_TITLE_STYLE = {
  fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
  fontSize: '14px',
  fontWeight: '500',
};

export const DEFAULT_APEX_CHART_CONFIG = {
  chart: {
    type: ChartType.line,
    height: 400,
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
    },
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true,
    },
    animations: {
      enabled: true,
      easing: 'linear',
      speed: 500,
    },
  } as ApexChart,
  xaxis: {
    type: 'category',
    labels: {
      style: {
        fontSize: '12px',
        ...COMMON_FONT_STYLE,
      },
    },
  } as ApexXAxis,
  yaxis: {
    labels: {
      style: {
        fontSize: '12px',
        ...COMMON_FONT_STYLE,
      },
    },
    title: {
      text: 'Y-Axis',
      style: {
        ...COMMON_TITLE_STYLE,
      },
    },
  } as ApexYAxis,
  stroke: {
    width: 3,
    curve: 'smooth',
    lineCap: 'round',
  } as ApexStroke,
  tooltip: {
    enabled: true,
    shared: true,
    followCursor: true,
  } as ApexTooltip,
  legend: {
    show: true,
    position: 'bottom',
    horizontalAlign: 'center',
  } as ApexLegend,
  grid: {
    show: true,
    borderColor: '#e7e7e7',
    strokeDashArray: 5,
  } as ApexGrid,
  theme: {
    mode: 'light',
  } as ApexTheme,
  title: {
    align: 'left',
    style: {
      color: '#263238',
      ...COMMON_TITLE_STYLE,
      fontSize: '20px',
    },
  } as ApexTitleSubtitle,
  colors: Y_LINES_COLORS,
  fill: {
    type: 'solid',
  },
};
