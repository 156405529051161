import { Component, input } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';

/**
 * Komponent `SemSkeletonComponent` służy do wyświetlania animowanego szkieletu,
 * który może być używany jako zastępczy element interfejsu podczas ładowania danych.
 *
 * ## Właściwości
 *
 * - **shape** (`'rectangle' | 'circle'`): Określa kształt elementu. Dostępne opcje to `'rectangle'` (domyślnie) i `'circle'`.
 * - **size** (`string`): Rozmiar elementu. Używany, gdy `shape` jest ustawiony na `'circle'`. Domyślna wartość to `'100%'`.
 * - **width** (`string`): Szerokość elementu. Używana, gdy `shape` jest ustawiony na `'rectangle'`. Domyślna wartość to `'100%'`.
 * - **height** (`string`): Wysokość elementu. Używana, gdy `shape` jest ustawiony na `'rectangle'`. Domyślna wartość to `'1rem'`.
 * - **borderRadius** (`string`): Promień zaokrąglenia krawędzi elementu. Domyślnie pusty, co oznacza brak zaokrąglenia lub wartość zdefiniowaną w motywie.
 * - **styleClass** (`string`): Dodatkowe klasy CSS do zastosowania w elemencie. Domyślnie pusty string.
 *
 * ## Przykład użycia
 *
 * ```html
 * <!-- Prostokątny szkielet o szerokości 200px i wysokości 20px -->
 * <app-sem-skeleton width="200px" height="20px"></app-sem-skeleton>
 *
 * <!-- Okrągły szkielet o średnicy 50px -->
 * <app-sem-skeleton shape="circle" size="50px"></app-sem-skeleton>
 * ```
 */

@Component({
  selector: 'app-sem-skeleton',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './sem-skeleton.component.html',
})
export class SemSkeletonComponent {
  shape = input<'rectangle' | 'circle'>('rectangle');
  size = input<string>('100%');
  width = input<string>('100%');
  height = input<string>('1rem');
  borderRadius = input<string>('');
  styleClass = input<string>('');

  get skeletonClasses() {
    //@TODO do odkomentowania jak wejdzie dark-mode
    // return `tw-animate-pulse tw-bg-gray-200 dark:tw-bg-gray-700 ${this.styleClass()}`;
    return `tw-animate-pulse tw-bg-gray-200 ${this.styleClass()}`;
  }

  get skeletonStyles() {
    return {
      width: this.shape() === 'circle' ? this.size() : this.width(),
      height: this.shape() === 'circle' ? this.size() : this.height(),
      borderRadius: this.shape() === 'circle' ? '50%' : this.borderRadius(),
    };
  }
}
