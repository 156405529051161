import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { orderBy } from 'lodash';
import { catchError, forkJoin, Observable, of, tap } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import ApiUrls from 'src/app/configs/api-urls.config';
import { ERROR_NOTIFICATION_SKIP } from 'src/app/interceptors/error-interceptor.service';
import { AdditionalFiltersInterface } from 'src/app/shared/model/data-table.model';
import { PaginationInterface, ResponseV2Interface } from 'src/app/shared/model/response.model';
import { UserModel } from 'src/app/shared/model/user.model';
import { SingleChangeInterface } from 'src/app/shared/sem-table/models/change.model';
import { TableChangeDataEmittedInterface } from 'src/app/shared/sem-table/models/TableChangeDataEmitted.model';
import { FilterGroupModel } from 'src/app/shared/sem-table/public-api';
import { CursorModel } from '../../shared/model/cursor.model';
import { ConnectionUrlInterface } from '../project/connections/connections.model';
import { ProjectService } from '../project/project.service';
import { TaskEventBroadcaster } from './tasks-event-broadcaster';
import { TasksFormService } from './tasks-form.service';
import { TaskStatusEnum } from './tasks.enum';
import { CommentAttachment, NewEmailCommentInterface, NewTaskCommentInterface, TaskCommentInterface, TaskInterface } from './tasks.model';

// @TODO: dodac typy do zwrotek!!
@Injectable({
  providedIn: 'root',
})
export class TasksService extends TasksFormService {
  private readonly http = inject(HttpClient);
  private readonly projectService = inject(ProjectService);
  private readonly taskEventBroadcaster = inject(TaskEventBroadcaster);

  constructor() {
    super();

    this.taskEventBroadcaster.parentBroadcaster$.pipe(takeUntilDestroyed()).subscribe(({ parentId }) => {
      this.getTask(parentId).subscribe((task) => {
        if (task?.id) {
          this.taskEventBroadcaster.update({ id: parentId, parentId: task.parent_id || null }, task);
        }
      });
    });
  }

  addCollaborator(taskId: number): Observable<void> {
    return this.http.get<void>(ApiUrls.tasksCollaboratorsAdd.prepareUrl({ taskId }));
  }

  // @TODO: Jak wyjdziemy zupełnie z aktualnego draftowania to metoda ta będzie do usunięcia
  createDraftTask(taskData: Partial<TaskInterface> = {}): Observable<TaskInterface> {
    const defaultData: Partial<TaskInterface> = {
      status: TaskStatusEnum.new,
    };

    if (!taskData.parent_id) {
      defaultData.project_id = this.projectService.activeProject$.getValue()?.id || null;
    }

    const task: Partial<TaskInterface> = {
      ...defaultData,
      ...taskData,
    };

    const formData: FormData = this.createTaskFormData(task);
    return this.http.post<TaskInterface>(ApiUrls.tasks, formData);
  }

  createTask(task: Partial<TaskInterface>): Observable<TaskInterface> {
    if (!task.status) {
      task.status = TaskStatusEnum.new;
    }

    const formData: FormData = this.createTaskFormData(task);
    return this.http.post<TaskInterface>(ApiUrls.tasks, formData).pipe(
      tap((updatedTask) => {
        const parentId = task.parent_id || null;
        this.taskEventBroadcaster.create({ parentId }, this.prepareTask(updatedTask));
      }),
    );
  }

  createTaskComment(taskId: number, data: NewTaskCommentInterface): Observable<TaskCommentInterface> {
    const formData: FormData = this.createTaskCommentFormData(data);
    return this.http.post<TaskCommentInterface>(ApiUrls.tasksByIdComments.prepareUrl({ taskId }), formData);
  }

  editTaskComment(taskId: number, commentId: number, data: NewTaskCommentInterface): Observable<TaskCommentInterface> {
    const formData: FormData = this.createTaskCommentFormData(data);
    return this.http.post<TaskCommentInterface>(
      ApiUrls.tasksByIdCommentsById.prepareUrl({
        taskId,
        commentId,
      }),
      formData,
    );
  }

  editTask(taskId: number, task: Partial<TaskInterface>): Observable<TaskInterface> {
    const formData: FormData = this.createTaskFormData(task);
    formData.append('_method', 'PATCH'); // @TODO: wynika to z problemu po stronie PHP, gdzie form-data nie może lecieć put'em oraz patch'em
    return this.http.post<TaskInterface>(ApiUrls.tasksById.prepareUrl({ taskId }), formData);
    // .pipe(
    //   tap((updatedTask) => {
    //     if (updatedTask.id) {
    //       const parentId = task.parent_id || null;
    //       this.taskEventBroadcaster.update({ id: updatedTask.id, parentId }, updatedTask);
    //     }
    //   }),
    // );
  }

  editTaskInline(singleChange: SingleChangeInterface): Observable<TaskInterface | null> {
    const { changedData, revertSingleChange, task } = this.catchSingleTaskChange(singleChange);

    return this.editTask(task.id!, changedData).pipe(
      catchError(() => {
        revertSingleChange();
        return of(null);
      }),
    );
  }

  editTasks(tasks: TaskInterface[]): Observable<TaskInterface[]> {
    return forkJoin(tasks.map((task) => this.editTask(task.id!, task)));
  }

  deleteTask(taskId: number, parentId: number | null = null): Observable<void> {
    return this.http
      .delete<void>(ApiUrls.tasksById.prepareUrl({ taskId }))
      .pipe(tap(() => this.taskEventBroadcaster.delete({ id: taskId, parentId })));
  }

  deleteComment(taskId: number, commentId: number): Observable<void> {
    return this.http.delete<void>(ApiUrls.tasksByIdCommentsById.prepareUrl({ taskId, commentId }));
  }

  deleteAttachment(attachmentPath: string): Observable<unknown> {
    return this.http.delete(attachmentPath);
  }

  getContributors(search: string | null = null, projectId: number | null = null, name: string | null = null) {
    let params = new HttpParams();

    if (search) {
      params = params.set('search', search);
    }

    if (name) {
      params = params.set('name', name);
    }

    if (projectId) {
      params = params.set('project_id', projectId);
    }

    return this.http.get<ResponseV2Interface<UserModel[]>>(ApiUrls.tasksContributors, { params }).pipe(map((res) => res.data));
  }

  getKanbanTasks(
    filterGroups: FilterGroupModel[] = [],
    additionalFilters: AdditionalFiltersInterface | null = null,
  ): Observable<PaginationInterface<TaskInterface>> {
    // @TODO: obsłużyć paginację - do ustalenia jak...
    const params = { filterGroups };

    if (additionalFilters) {
      (params['additional_filters' as keyof typeof params] as unknown) = additionalFilters;
    }

    return this.http.post<PaginationInterface<TaskInterface>>(ApiUrls.tasksKanban, params).pipe(
      map((res) => {
        res.data = orderBy(res.data, ['order'], ['desc']);
        return res;
      }),
    );
  }

  getTask(taskId: number): Observable<TaskInterface> {
    const context = new HttpContext().set(ERROR_NOTIFICATION_SKIP, [404]);

    return this.http.get<TaskInterface>(ApiUrls.tasksById.prepareUrl({ taskId }), { context }).pipe(map((task) => this.prepareTask(task)));
  }

  getTaskComments(taskId: number, params: Partial<CursorModel>): Observable<TaskCommentInterface[]> {
    return this.http
      .get<ResponseV2Interface<TaskCommentInterface[]>>(ApiUrls.tasksByIdComments.prepareUrl({ taskId }), { params })
      .pipe(map((res) => res.data));
  }

  getTasks(
    tableParams: Partial<TableChangeDataEmittedInterface> | null,
    additionalFilters: AdditionalFiltersInterface | null = null,
    additionalfilterGroups: FilterGroupModel[] | null = null,
  ): Observable<PaginationInterface<TaskInterface>> {
    const { currentPage, filterGroups, itemsPerPage, sorting } = tableParams || {};

    const params = {
      direction: sorting?.direction,
      filterGroups: filterGroups || [],
      order: sorting?.columnName,
      page: currentPage,
      per_page: itemsPerPage,
    };

    if (additionalFilters) {
      (params['additional_filters' as keyof typeof params] as unknown) = additionalFilters;
    }

    params.filterGroups = [...params.filterGroups, ...(additionalfilterGroups || [])];

    return this.http.post<PaginationInterface<TaskInterface>>(ApiUrls.tasksSearch, params).pipe(
      map((res) => {
        res.data.map((task) => this.prepareTask(task));

        return res;
      }),
    );
  }

  // @TODO: Czy to jest zbędne i każda zmaina statusu powinna iść akcjami?
  // setStatus(taskId: number, status: TaskStatusEnum): Observable<TaskInterface> {
  //   return this.http.patch<TaskInterface>(ApiUrls.tasksSetStatus.prepareUrl({ taskId }), { status });
  // }

  getGmailConnections(): Observable<ConnectionUrlInterface> {
    let params: HttpParams = new HttpParams();
    params = params.set('redirect', 'CONNECTIONS');
    params = params.set('type', 'GOOGLE');
    params = params.set('services', '10');

    return this.http
      .get<{ data: { item: ConnectionUrlInterface } }>(ApiUrls.connections + '/url', { params })
      .pipe(map((response: { data: { item: ConnectionUrlInterface } }) => response.data.item));
  }

  downloadAttachment(attachment: CommentAttachment): Observable<Blob> {
    const url: string = attachment.path;
    const context = new HttpContext().set(ERROR_NOTIFICATION_SKIP, true);
    return this.http.get(url, { context, responseType: 'blob' });
  }

  prepareDefaultDataForNewTask(data: Partial<TaskInterface> = {}): Partial<TaskInterface> {
    const defaultDataForNewTask: Partial<TaskInterface> = { status: TaskStatusEnum.new, _type: 'manual' };

    if (this.authService?.authUser) {
      defaultDataForNewTask.author = this.authService.authUser;
    }

    if (!data.parent_id) {
      const currentProject = this.projectService.activeProject$?.getValue();
      if (currentProject) {
        defaultDataForNewTask.project_id = currentProject.id;
        defaultDataForNewTask._project_name = currentProject.name;
      }
    }

    return { ...defaultDataForNewTask, ...data };
  }

  removeCollaborator(taskId: number): Observable<void> {
    return this.http.get<void>(ApiUrls.tasksCollaboratorsRemove.prepareUrl({ taskId }));
  }

  private createTaskCommentFormData(data: NewTaskCommentInterface | NewEmailCommentInterface): FormData {
    const formData: FormData = new FormData();
    formData.append('comment', data.comment);
    if (data.attachments && data.attachments.length) {
      data.attachments.forEach((attachment: File) => {
        formData.append('attachments[]', attachment);
      });
    }

    if (data.parentId) {
      formData.append('parent_id', data.parentId.toString());
    }

    if ('external_mail' in data) {
      formData.append('external_mail', String(data.external_mail));
      formData.append('mail_from', data.mail_from);
      formData.append('mail_to', data.mail_to);
      formData.append('subject', data.subject);
    }

    return formData;
  }
}
