import { FilterGroupModel } from '../../filters/models/filter.model';
import { ColumnTypesEnum } from '../../table-display/ColumnTypesEnum';
import { ColumnModel } from '../../table-display/columns-switch/columns/column.model';
import { SelectColumn } from '../../table-display/columns-switch/columns/select-column/SelectColumn';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class OverwriteAction extends ActionModel {
  override type = ActionTypesEnum.override;
  override value: any;

  constructor(column: ColumnModel, filterGroups: FilterGroupModel[], isGlobal: boolean) {
    super(column, filterGroups, isGlobal);
    if (column.type === ColumnTypesEnum.BOOLEAN) {
      this.value = false;
    }

    if (column.type === ColumnTypesEnum.SELECT && (column as SelectColumn).defaultValue) {
      this.value = (column as SelectColumn).defaultValue;
    }
  }

  isValid(): boolean {
    return !!this.columnName && (!!this.value || this.value === 0 || this.columnType === ColumnTypesEnum.BOOLEAN);
  }

  use() {
    return this.value;
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      value: this.value,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
    this.value = item.value;
  }
}
