<div class="language-container">
  <label for="language">{{ 'auth.language' | translate }}</label>
  <mat-form-field>
    <mat-select id="language" (selectionChange)="changeLanguage()" [formControl]="language" id="language" data-testid="language">
      @for (lang of languages; track lang) {
        <mat-option [value]="lang.value">{{ lang.key | translate }}</mat-option>
      }
    </mat-select>
    <app-form-field-error-display matError></app-form-field-error-display>
  </mat-form-field>
</div>
