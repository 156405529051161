import { computed, inject, Injectable, signal } from '@angular/core';
import type { QuickStartCategory } from './models/quick-start-category.model';
import type { QuickStartItem } from './models/quick-start-item.model';
import { ProjectService } from '../project/project.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { QuickstartModalComponent } from './quickstart-modal/quickstart-modal.component';
import type { TranslationKey } from './quickstart-modal/quickstart-modal-translation-key.model';
import { HttpClient } from '@angular/common/http';
import apiUrlsConfig from 'src/app/configs/api-urls.config';
import { Observable, tap } from 'rxjs';
import type { VisitConfig } from './models/visit-config.model';
import type { QuickstartApi } from './models/quickstart-api.model';
import { CustomSettingsService } from '../../shared/service/custom-settings.service';
import { ActivatedRoute } from '@angular/router';
import type { ModalPayload } from './models/modal-payload.model';
import commonUrlsConfig from 'src/app/configs/common-urls.config';

@Injectable({ providedIn: 'root' })
export class QuickstartService {
  private readonly project = inject(ProjectService);
  private readonly http = inject(HttpClient);
  private readonly activeProject = toSignal(this.project.activeProject$);
  private readonly activeProjectId = computed(() => this.activeProject()?.id);
  private readonly matDialog = inject(MatDialog);
  private readonly customSettingsService = inject(CustomSettingsService);
  private readonly activatedRoute = inject(ActivatedRoute);

  readonly categories = signal<QuickStartCategory[]>([
    {
      title: 'basic_setup',
      isCompleted: false,
      id: 1,
      children: [
        {
          title: 'basic_setup.create_first_project',
          progressValue: 5,
          isCompleted: true,
          directLink: '/dashboard/project/add',
          apiKey: 'basicFirstProject',
        },
        {
          title: 'basic_setup.add_first_connection',
          progressValue: 7,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/connections',
          apiKey: 'basicFirstConnection',
        },
        {
          title: 'basic_setup.visit_sembot_community',
          progressValue: 5,
          isCompleted: false,
          isManualCompletion: true,
          links: [
            {
              label: 'basic_setup.visit_sembot_community_link_label',
              link: commonUrlsConfig.community,
              isExternal: true,
            },
            {
              label: 'basic_setup.visit_sembot_knowledge_base_link_label',
              link: commonUrlsConfig.knowledgeBase,
              isExternal: true,
            },
          ],
          apiKey: 'basicVisitCommunity',
        },
        {
          title: 'basic_setup.set_up_onboarding_call',
          description: 'basic_setup.set_up_onboarding_call_description',
          progressValue: 10,
          isCompleted: false,
          links: [
            {
              label: 'schedule',
              link: commonUrlsConfig.calendly,
              isExternal: true,
              completeAction: true,
            },
          ],
          apiKey: 'basicOnboarding',
        },
        {
          title: 'basic_setup.choose_your_initial_package',
          progressValue: 3,
          isCompleted: true,
          directLink: '/dashboard/payments/plan',
          apiKey: 'basicChoosePackage',
        },
      ],
    },
    {
      title: 'workspace',
      isCompleted: false,
      id: 2,
      children: [
        {
          title: 'workspace.create_first_task',
          progressValue: 5,
          isCompleted: false,
          directLink: '/dashboard/tasks/list',
          fragment: 'board',
          apiKey: 'workspaceFirstTask',
        },
        {
          title: 'workspace.set_up_processes',
          progressValue: 10,
          isCompleted: false,
          directLink: '/dashboard/automation/start',
          apiKey: 'workspaceSetUpProcess',
        },
        {
          title: 'workspace.visit_dashboard',
          progressValue: 3,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/dashboard',
          apiKey: 'workspaceVisitDashboard',
        },
        {
          title: 'workspace.invite_team_members',
          progressValue: 5,
          isCompleted: false,
          directLink: '/dashboard/teams',
          apiKey: 'workspaceInviteMember',
        },
      ],
    },
    {
      title: 'position_tracking',
      isCompleted: false,
      id: 3,
      children: [
        {
          title: 'position_tracking.add_first_phrase_to_track',
          progressValue: 8,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/monitor/organic',
          apiKey: 'trackingFirstPhase',
        },
      ],
    },
    {
      title: 'products',
      isCompleted: false,
      id: 4,
      children: [
        {
          title: 'products.add_product_feed',
          progressValue: 4.5,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/feed',
          apiKey: 'productsImportFeed',
          fragment: 'import',
        },
        {
          title: 'products.export_feed_to_xml',
          progressValue: 4.5,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/feed',
          apiKey: 'productsExportFeed',
          fragment: 'export',
        },
        {
          title: 'products.create_first_rule',
          progressValue: 3.5,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/rules',
          apiKey: 'productsFirstRule',
        },
        {
          title: 'products.check_performance_data',
          progressValue: 3.5,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/products',
          apiKey: 'productsCheckPerformance',
          fragment: 'performance',
        },
        {
          title: 'products.add_first_products_to_track',
          progressValue: 5,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/monitor/product-ads',
          apiKey: 'productsTrackPosition',
        },
        {
          title: 'products.add_first_products_to_track_competitor_prices',
          progressValue: 5,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/monitor/shopping',
          apiKey: 'productsTrackCompetitor',
        },
      ],
    },
    {
      title: 'campaigns',
      isCompleted: false,
      id: 5,
      children: [
        {
          title: 'campaigns.create_first_campaign',
          progressValue: 5,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/campaign',
          apiKey: 'campaignCreateFirst',
        },
      ],
    },
    {
      title: 'advanced',
      isCompleted: false,
      id: 6,
      children: [
        {
          title: 'advanced.create_first_product_duplicate',
          progressValue: 5,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/products',
          fragment: 'duplicate',
          apiKey: 'advancedProductDuplicate',
        },
        {
          title: 'advanced.check_pages_technical_score',
          progressValue: 3,
          isCompleted: false,
          directLink: '/dashboard/project/' + this.activeProjectId() + '/pages',
          apiKey: 'advancedCheckPages',
        },
      ],
    },
  ]);

  readonly activeCategory = signal<QuickStartCategory>(this.categories()[0]);
  readonly activeCategoryIdx = computed(() => this.activeCategory().id || 0);
  readonly activeCategoryItems = computed(() => this.activeCategory().children || []);
  readonly completion = signal<number>(0);

  readonly workspaceActivityConfig = signal<VisitConfig | null>(null);
  readonly userVisitationConfig = signal<Partial<VisitConfig> | null>(null);

  readonly isModalVisible = signal<boolean>(false);

  changeActiveCategory(category: QuickStartCategory): void {
    this.activeCategory.set(category);
  }

  completeItem(idx: number): void {
    this.updateActiveCategory(idx);
    this.updateCompletion(idx);
    this.updateCategories();
    this.updateUserQuickstartActivityConfig(idx);
    this.checkIfAllCategoryItemsAreCompleted();
  }

  checkIfAllCategoryItemsAreCompleted(): void {
    const activeCategory = this.activeCategory();
    const areActiveCategoryItemsCompleted = activeCategory.children.every((item) => item.isCompleted);
    if (areActiveCategoryItemsCompleted) {
      const id = activeCategory.id;
      const updatedCategories = this.categories().map((cat) => (cat.id === id ? { ...cat, isCompleted: true } : cat));
      this.categories.set(updatedCategories);
    }
  }

  defineCompletion(): void {
    let completion = 0;
    this.categories().forEach((cat) => {
      const finishedCategories = cat.children.filter((item) => item.isCompleted);
      finishedCategories.forEach((item) => (completion += item.progressValue));
    });
    this.completion.set(completion);
  }

  openModal(data: TranslationKey, flag: keyof VisitConfig) {
    if (this.isModalVisible()) {
      return;
    }

    this.isModalVisible.set(true);
    this.updateUserQuickstartVisitConfig(flag);
    return this.matDialog.open<QuickstartModalComponent>(QuickstartModalComponent, {
      exitAnimationDuration: 350,
      enterAnimationDuration: 300,
      maxWidth: '650px',
      data,
    });
  }

  closeModal(): void {
    this.isModalVisible.set(false);
  }

  getUserQuickstartActivityConfig(): Observable<QuickstartApi> {
    return this.http.get<QuickstartApi>(apiUrlsConfig.quickstart);
  }
  getUserQuickstartVisitConfig() {
    return this.customSettingsService.get<VisitConfig>({ name: 'popupStatus' });
  }

  updateUserQuickstartVisitConfig(prop: keyof VisitConfig): void {
    this.userVisitationConfig.update((v) => (v && { ...v, [prop]: true }) || { [prop]: true });
    this.customSettingsService
      .saveForUser({
        data: this.userVisitationConfig(),
        name: 'popupStatus',
      })
      .subscribe();
  }

  updateUserQuickstartActivityConfig(prop: keyof VisitConfig | number): void {
    if (typeof prop !== 'number') {
      this.workspaceActivityConfig.update((v) => v && { ...v, [prop]: true });
      this.http.post(apiUrlsConfig.quickstart, { setup_progress: { [prop]: true } }).subscribe();
    } else {
      const item = this.activeCategory().children.find((_, i) => i === prop)!.apiKey;
      this.workspaceActivityConfig.update((v) => v && { ...v, [item]: true });
      this.http.post(apiUrlsConfig.quickstart, { setup_progress: { [item]: true } }).subscribe();
    }
    this.apiDataToStateStructureMapper(this.workspaceActivityConfig() as VisitConfig);
  }

  apiDataToStateStructureMapper(config: VisitConfig): void {
    const updatedCategories = this.categories().map((topCat: QuickStartCategory) => {
      const updatedChildren = topCat.children.map((categoryItem) => ({ ...categoryItem, isCompleted: config[categoryItem.apiKey] }));
      const areAllDone = updatedChildren.every((child) => child.isCompleted);
      return { ...topCat, children: updatedChildren, isCompleted: areAllDone };
    });
    this.categories.set(updatedCategories);
    this.activeCategory.set(this.categories()[this.activeCategoryIdx() - 1]);
  }

  listenToFragments(payload: ModalPayload): Observable<any> {
    const { firstModalData, secondModalData } = payload;
    return this.activatedRoute.fragment.pipe(
      tap((fr) => {
        if (fr === firstModalData.fragment && !firstModalData.hasVisited) {
          this.openModal(firstModalData.translationKey, firstModalData.quickstartConfig);
        } else if (fr === secondModalData.fragment && !secondModalData.hasVisited) {
          this.openModal(secondModalData.translationKey, secondModalData.quickstartConfig);
        } else if (!fr) {
          if (!secondModalData.hasVisited) {
            this.openModal(secondModalData.translationKey, secondModalData.quickstartConfig)
              ?.afterClosed()
              .subscribe(() => {
                if (!firstModalData.hasVisited) {
                  this.openModal(firstModalData.translationKey, firstModalData.quickstartConfig);
                }
              });
          }
          if (!firstModalData.hasVisited) {
            this.openModal(firstModalData.translationKey, firstModalData.quickstartConfig)
              ?.afterClosed()
              .subscribe(() => {
                if (!secondModalData.hasVisited) {
                  this.openModal(secondModalData.translationKey, secondModalData.quickstartConfig);
                }
              });
          }
        }
      }),
    );
  }

  private updateCompletion(idx: number): void {
    const activeCategory = this.activeCategory();
    const val = (activeCategory.children.find((_, i) => i === idx) as QuickStartItem).progressValue;
    this.completion.update((v) => v + val);
  }

  private updateActiveCategory(idx: number): void {
    const activeCategory = this.activeCategory();
    const updatedItems = activeCategory.children.map((item, i) => (i === idx ? { ...item, isCompleted: true } : item));
    this.activeCategory.update((v) => ({ ...v, children: updatedItems }));
  }

  private updateCategories(): void {
    const activeCategory = this.activeCategory();
    const id = activeCategory.id;
    const updatedCategories = this.categories().map((cat) => (cat.id === id ? this.activeCategory() : cat));
    this.categories.set(updatedCategories);
  }
}
