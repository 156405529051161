import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ChartDataPoint {
  x: number;
  y: number | null;
}

export interface ChartSeries {
  name: string;
  data: ChartDataPoint[];
}

@Injectable()
export class ChartService {
  refreshChartData$: Subject<void> = new Subject();

  sanitizeString(str: string): string {
    return str
      .normalize('NFD') // Decomposes special characters into base letters + accents
      .replace(/[\u0300-\u036f]/g, '') // Removes diacritical marks (accents)
      .replace(/[^a-zA-Z0-9-_ ]/g, ' '); // Replaces other special chars
  }

  normalizeSeriesData(series: ChartSeries[]) {
    const allTimestamps = [...new Set(series.flatMap((s) => s.data.map((point) => point.x)))].sort((a, b) => a - b);

    return series.map((s) => {
      const dataMap = new Map(s.data.map((point) => [point.x, point.y]));

      const normalizedData: ChartDataPoint[] = allTimestamps.map((timestamp) => ({
        x: timestamp,
        y: dataMap.get(timestamp) ?? null,
      }));

      return { ...s, data: normalizedData };
    });
  }
}
