/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, inject, Injectable, OnDestroy } from '@angular/core';
import { catchError, EMPTY, filter, isObservable, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { NotificationService } from 'src/app/notification/notification.service';
import { ItemModel } from '../item.model';
import { ListingType } from '../models/TableConfigurationInterface.model';

export type InputRowEvent = Observable<unknown> | object;

export interface EventBroadcaster {
  rowId: number | null;
  parentRowId?: number | string | null;
  listingType?: ListingType;
}

interface UpdateRowOnListParams {
  rowId: number | null;
  listing: ItemModel[] | null;
  ref: ChangeDetectorRef;
  rowData: any;
}

interface RowUpdatedByObservableEvent extends EventBroadcaster {
  rowKind: 'rowUpdatedByObservable';
  observ: Observable<unknown>;
}

interface RowUpdatedByObjectEvent extends EventBroadcaster {
  rowKind: 'rowUpdatedByObject';
  object: any;
}

interface RowLoadingEvent extends EventBroadcaster {
  isLoading: boolean;
  rowKind: 'rowLoading';
}

interface RowDeletedEvent extends EventBroadcaster {
  rowKind: 'rowDeleted';
}

interface RowCreatedEvent extends EventBroadcaster {
  isLoading: boolean;
  object: any;
  rowKind: 'rowCreated';
}

export type BroadcasterEvents = RowCreatedEvent | RowDeletedEvent | RowLoadingEvent | RowUpdatedByObjectEvent | RowUpdatedByObservableEvent;

@Injectable({ providedIn: 'root' })
export class EventBroadcasterService implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  private readonly broadcasterSubject = new Subject<BroadcasterEvents>();
  private readonly notificationService = inject(NotificationService);
  readonly broadcaster$ = this.broadcasterSubject.asObservable().pipe();

  readonly eventBroadcaster$ = (
    listing: ItemModel[],
    ref: ChangeDetectorRef,
    listingType: ListingType,
    listingParentId: number | string | null = null,
  ) =>
    this.broadcaster$.pipe(
      takeUntil(this.onDestroy$),
      tap(() => {
        if (!listing || !ref || !listingType) {
          console.error(`[${listingType}] OF!!!`);
        }
      }),
      filter(
        (event): event is BroadcasterEvents =>
          listingType === event.listingType &&
          String(listingParentId || null) === String(event.parentRowId || null) &&
          (!event.rowId || listing!.some((item: ItemModel) => item.id === event.rowId)),
      ),
      switchMap((event: BroadcasterEvents) => {
        const { rowId, rowKind } = event;
        let observ!: Observable<unknown>;
        const params: UpdateRowOnListParams = { rowId, listing, ref, rowData: null };

        switch (rowKind) {
          case 'rowCreated':
            params.rowData = event.object;
            this.createRowOnList(params);
            break;

          case 'rowDeleted':
            this.deleteRowOnList(params);
            break;

          case 'rowUpdatedByObservable':
            observ = event.observ!.pipe(
              tap((data: any) => {
                if (data) {
                  params.rowData = data;
                  this.updateRowOnList(params);
                }
              }),
            );
            break;
          case 'rowUpdatedByObject':
            params.rowData = event.object;
            this.updateRowOnList(params);
            break;
          case 'rowLoading':
            params.rowData = { _isLoading: event.isLoading || false };
            this.updateRowOnList(params);
            break;
        }

        return observ
          ? observ.pipe(
              catchError(() => {
                this.notificationService.error('semtable_row_updated');
                return EMPTY;
              }),
            )
          : of();
      }),
    );

  createRow(event: EventBroadcaster, input?: InputRowEvent) {
    event = {
      ...event,
      ...{
        rowKind: 'rowCreated',
        object: input,
      },
    } as RowCreatedEvent;

    this.broadcasterSubject.next(event as BroadcasterEvents);
  }

  deleteRow(event: EventBroadcaster) {
    this.broadcasterSubject.next({
      ...event,
      ...{
        rowKind: 'rowDeleted',
      },
    });
  }

  loadingRow(event: EventBroadcaster, isLoading: boolean = true) {
    this.broadcasterSubject.next({
      ...event,
      ...{
        isLoading,
        rowKind: 'rowLoading',
      },
    });
  }

  updateRow(event: EventBroadcaster, input?: InputRowEvent) {
    if (input) {
      if (isObservable(input)) {
        event = {
          ...event,
          ...{
            rowKind: 'rowUpdatedByObservable',
            observ: input,
          },
        } as RowUpdatedByObservableEvent;
      } else {
        event = {
          ...event,
          ...{
            rowKind: 'rowUpdatedByObject',
            object: input,
          },
        } as RowUpdatedByObjectEvent;
      }
    }

    this.broadcasterSubject.next(event as BroadcasterEvents);
  }

  private createRowOnList(params: UpdateRowOnListParams) {
    if (!params.listing) {
      return;
    }

    let listing = params.listing;
    const { ref, rowData } = params;

    if (rowData) {
      const draftItemIndex = listing.findIndex((row) => row._isDraft);

      if (draftItemIndex >= 0) {
        listing.splice(draftItemIndex, 1); // Usunięcie draftów
      }

      listing.unshift(rowData);
      listing = [...listing];
    }

    ref.markForCheck();
  }

  private deleteRowOnList(params: UpdateRowOnListParams) {
    if (!params.listing) {
      return;
    }

    let listing = params.listing;
    const { ref, rowId } = params;

    const itemIndex = listing.findIndex((row) => row.id === rowId);

    if (itemIndex >= 0) {
      listing.splice(itemIndex, 1);
    }

    listing = [...listing];
    ref.markForCheck();
  }

  private updateRowOnList(params: UpdateRowOnListParams) {
    if (!params.listing) {
      return;
    }

    let listing = params.listing;
    const { rowId, ref, rowData } = params;

    (rowData || {})._isDraft = false;
    rowData._isLoading = rowData._isLoading || false;

    const itemIndex = listing.findIndex((item) => item.id === rowId);

    if (itemIndex >= 0) {
      listing[itemIndex] = { ...listing[itemIndex], ...rowData };
      listing = [...listing];
    }

    ref.markForCheck();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
