import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import RouterConfig, { RouterConfigKey } from '../../../configs/router.config';
import { ProjectService } from '../../../dashboard/project/project.service';
import { NotificationService } from '../../../notification/notification.service';
import { RouterDirective } from '../../directives/router.directive';
import { AuthService } from '../../service/auth.service';
import { LocalStorageService, storageKey } from '../../service/local-storage.service';
import { SessionStorageService } from '../../service/session-storage.service';
import { UsersService } from '../../service/users.service';
import { ButtonComponent } from '../button-with-spinner/button.component';

@Component({
  selector: 'app-guide-popup',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    MatCardModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    RouterDirective,
  ],
  templateUrl: './guide-popup.component.html',
  styleUrls: ['./guide-popup.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          opacity: 1,
          maxHeight: '500px',
          display: 'block',
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
          maxHeight: '0',
          display: 'none',
        }),
      ),
      transition('open => closed', [animate('500ms ease-out')]),
      transition('closed => open', [animate('800ms ease-out')]),
    ]),
  ],
})
export class GuidePopupComponent implements OnInit, OnDestroy {
  @Input() guideTitle!: string;
  @Input() guideUrl!: string;
  @Input() appContext?: string;
  @Input() backButton?: RouterConfigKey;
  @Input() showBackButton: boolean = true;

  @Output() backButtonClicked = new EventEmitter<void>();
  isOpen = false;
  projectId!: number;
  private userId: number | null = null;
  private toggleClicks = new Subject<void>();
  private destroy$ = new Subject<void>();
  private localStorageService = inject(LocalStorageService);
  private readonly projectService = inject(ProjectService);
  private readonly usersService = inject(UsersService);
  private readonly notificationService = inject(NotificationService);
  private readonly authService = inject(AuthService);
  private readonly sessionStorageService = inject(SessionStorageService);

  handleBackButtonClick() {
    this.backButtonClicked.emit();
  }

  ngOnInit() {
    this.validateBackButton();
    this.toggleClicks.pipe(throttleTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.isOpen = !this.isOpen;
    });
    this.projectId = this.projectService.activeProject$.getValue()!.id;
    this.fetchUserData();
  }

  toggle() {
    this.toggleClicks.next();
    const contextKey = `guide_closed_by_user_${this.appContext || 'default'}`;
    this.sessionStorageService.save(contextKey, !this.isOpen);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkIfNewUser(created_at: string): void {
    const contextKey = `guide_closed_by_user_${this.appContext || 'default'}`;
    const guideClosedByUser = this.sessionStorageService.get(contextKey) || false;
    if (guideClosedByUser) {
      this.isOpen = false;
      return;
    }

    const firstLoginDate = this.localStorageService.get(storageKey.firstLogin);

    const creationDate = moment(created_at);
    const currentDate = moment();
    const differenceInDays = currentDate.diff(creationDate, 'days');
    const differenceInDaysLogin = currentDate.diff(firstLoginDate, 'days');

    if (differenceInDays < 7 || (differenceInDays < 30 && differenceInDaysLogin < 30)) {
      this.isOpen = true;
    }
  }

  private validateBackButton(): void {
    if (this.backButton && !RouterConfig[this.backButton]) {
      console.error(`Invalid backButton value: ${this.backButton}`);
      this.backButton = undefined;
    }
  }

  private fetchUserData(): void {
    const userId = this.userId || this.authService.authUser!.id;
    this.usersService.getUser(userId!).subscribe({
      next: (user) => {
        this.checkIfNewUser(user.created_at!);
      },
      error: () => this.notificationService.error('fetch_user_data_error'),
    });
  }
}
