import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import routerConfig from 'src/app/configs/router.config';
import { NotificationService } from 'src/app/notification/notification.service';
import { DataLayerService } from 'src/app/shared/service/data-layer.service';
import { RouterService } from '../../../shared/service/router.service';
import { PaymentModel } from '../models/payments.model';
import { StripeStatusEnum } from '../models/stripe-status.enum';
import { PaymentsService } from '../payments.service';
import { CardAttachFormComponent, CardSetup } from './card-attach-form/card-attach-form.component';

@Component({
  selector: 'app-buy-subscription',
  templateUrl: './buy-subscription.component.html',
  styleUrls: ['./buy-subscription.component.scss'],
})
export class BuySubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild(CardAttachFormComponent) cardAttachForm!: CardAttachFormComponent;
  private onDestroy$ = new Subject<void>();

  chosenPackage!: PaymentModel;
  discountPrice: number;
  isThereCouponAndIsInvalid!: boolean;

  constructor(
    private dataLayerService: DataLayerService,
    private location: Location,
    private notificationService: NotificationService,
    private paymentsService: PaymentsService,
    private route: ActivatedRoute,
    private routerService: RouterService,
  ) {
    this.discountPrice = -1;
  }

  ngOnInit(): void {
    const packageId = +this.route.snapshot.params['id'];
    this.paymentsService.getAvailablePackageById(packageId).subscribe((chosen) => (this.chosenPackage = chosen));
  }

  back() {
    this.location.back();
  }

  buyPackage(cardSetup: CardSetup) {
    const paymentMethod = cardSetup.setupIntent?.payment_method || null;
    const coupon = cardSetup.coupon || null;

    if (!paymentMethod) {
      this.notificationService.error('subscription_buy');
      this.cardAttachForm.isLoading = false;
      return;
    }

    // if (coupon && !this.discountPrice) {
    //   // TODO: Check again if the coupon is valid?
    // }

    this.paymentsService
      .getCurrentPackage()
      .pipe(
        switchMap((currentPackage) => {
          if (currentPackage.stripe_status === StripeStatusEnum.incomplete) {
            this.notificationService.warning('subscription_buy_with_link', { link: currentPackage.customerPortalUrl });
            return EMPTY;
          }
          return this.paymentsService.buyPackage(this.chosenPackage.id, paymentMethod as string, coupon);
        }),
      )
      .subscribe(
        () => {
          this.cardAttachForm.isLoading = false;
          this.notificationService.success('subscription_buy');
          this.dataLayerService.startSubscription(this.chosenPackage);
          this.getCurrentPackage();
        },
        (err) => {
          this.cardAttachForm.isLoading = false;

          if (err.paymentId) {
            this.notificationService.warning('subscription_buy');
            this.routerService.navigate(routerConfig.paymentsConfirm, { paymentId: err.paymentId });
          } else {
            this.notificationService.error('subscription_buy');
          }
        },
      );
  }

  includeDiscount(coupon: string) {
    const packageId = this.chosenPackage?.id || null;

    if (coupon && packageId) {
      this.paymentsService.getSubscriptionPrice(coupon, [{ id: packageId }]).subscribe(
        (res) => {
          if (res.amount || res.amount == 0) {
            this.discountPrice = res.amount;
            this.notificationService.success('coupon_valid');
          } else {
            this.cancelDiscount();
          }
        },
        () => this.cancelDiscount(),
      );
    } else {
      this.cancelDiscount();
    }
  }

  private cancelDiscount() {
    this.notificationService.error('coupon_invalid');
    this.discountPrice = -1;
  }

  private getCurrentPackage() {
    this.paymentsService
      .getCurrentPackage()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.routerService.navigate(routerConfig.payments));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
