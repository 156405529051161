import { CdkTrapFocus } from '@angular/cdk/a11y';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TranslatePipe } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';
import { AppFormFieldErrorDisplayComponent } from 'src/app/shared/components/app-form-field-error-display/app-form-field-error-display.component';
import { ButtonComponent } from 'src/app/shared/components/button-with-spinner/button.component';
import { RouterDirective } from 'src/app/shared/directives/router.directive';
import { FormApiValidationError } from 'src/app/shared/model/errors/formApiError.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { RouterService, routerConfig } from 'src/app/shared/service/router.service';
import { CookiesStorageService, cookiesKey } from '../../shared/service/cookies-storage.service';
import { LocalStorageService, storageKey } from '../../shared/service/local-storage.service';
import { AuthProvidersComponent } from '../auth-providers/auth-providers.component';
import { BrandLogosComponent } from '../brand-logos/brand-logos.component';
import { DividerComponent } from '../divider/divider.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    BrandLogosComponent,
    TranslatePipe,
    ButtonComponent,
    MatFormFieldModule,
    MatIcon,
    MatInput,
    AppFormFieldErrorDisplayComponent,
    ReactiveFormsModule,
    CdkTrapFocus,
    AuthProvidersComponent,
    RouterDirective,
    MatIconButton,
    DividerComponent,
  ],
})
export class LoginComponent {
  protected isLoading!: boolean;
  protected isPasswordVisible = false;
  private authService = inject(AuthService);
  private formBuilder = inject(UntypedFormBuilder);
  private routerService = inject(RouterService);
  private storageHelperService = inject(LocalStorageService);
  private cookiesStorageService = inject(CookiesStorageService);

  protected form = this.formBuilder.group(
    {
      email: ['', [Validators.email, Validators.required, Validators.minLength(5)]],
      password: ['', [Validators.required]],
    },
    { updateOn: 'submit' },
  );

  get password() {
    return this.form.get('password') as UntypedFormControl;
  }

  get email() {
    return this.form.get('email') as UntypedFormControl;
  }

  onSubmit() {
    this.password.updateValueAndValidity();
    this.email.updateValueAndValidity();

    if (this.form.valid) {
      this.isLoading = true;
      this.authService
        .login(this.email.value, this.password.value)
        .pipe(delay(1000))
        .subscribe(
          () => {
            this.cookiesStorageService.deleteCookie(cookiesKey.resourceBanner);
            this.redirectToDashboard();
          },
          (er: FormApiValidationError) => {
            this.isLoading = false;
            er.setOnForm && er.setOnForm(this.form);
          },
        );
    }
  }

  private redirectToDashboard() {
    const { customPageAfterLogin } = this.authService;

    const firstLoginDate = this.storageHelperService.get('firstLogin');
    if (!firstLoginDate) {
      this.storageHelperService.save(storageKey.firstLogin, new Date().toISOString());
    }

    customPageAfterLogin ? (window.location.href = customPageAfterLogin) : this.routerService.navigate(routerConfig.home);
  }
}
