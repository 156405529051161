import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { TableConfigurationModel } from '../../../models/TableConfiguration.model';
import { FilterManagerPopupData } from '../../../models/modals/FilterManagerPopupDataInterface';
import { ColumnModel } from '../../../table-display/columns-switch/columns/column.model';
import { FilterLogicOperatorEnum } from '../../FilterLogicOperatorEnum';
import { FilterGroupModel, FilterInStorageModel, FilterModel, FiltersModel } from '../../models/filter.model';
import { ColumnValueTypesEnum } from '../../../table-display/ColumnTypesEnum';
import { sanitizeNumber } from '../../../../validators/number-validation';
import { FilterTypesEnum } from '../../FilterTypesEnum';

interface FilterGroupForm {
  filters: FormArray<FormGroup>;
  operator: FormControl<string | null>;
}

interface MainForm {
  filterGroups: FormArray<FormGroup<FilterGroupForm>>;
  filterManualChanges: FormControl<boolean | null>;
}

interface FilterData {
  additional_sources: string[];
  column: ColumnModel;
  operator: FilterLogicOperatorEnum;
  type: FilterTypesEnum;
  value: {
    config: boolean;
    data: any;
  };
}

@Component({
  selector: 'sem-filters-manager-popup',
  templateUrl: './filters-manager-popup.component.html',
  styleUrls: ['./filters-manager-popup.component.scss'],
})
export class FiltersManagerPopupComponent implements OnInit, OnDestroy {
  config!: TableConfigurationModel;
  columns!: ColumnModel[];
  mainForm: FormGroup<MainForm> = new FormGroup<MainForm>({
    filterGroups: new FormArray<FormGroup<FilterGroupForm>>([]),
    filterManualChanges: new FormControl<boolean | null>(null),
  });

  actualFilters!: FiltersModel;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<FiltersManagerPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilterManagerPopupData,
  ) {}

  get filterGroups(): FormArray<FormGroup<FilterGroupForm>> {
    return this.mainForm.controls.filterGroups;
  }

  get groupsFormArray(): FormArray<FormGroup<FilterGroupForm>> {
    return this.mainForm.get('filterGroups') as FormArray<FormGroup<FilterGroupForm>>;
  }

  get filterManualChangesControl(): FormControl<boolean | null> {
    return this.mainForm.controls.filterManualChanges;
  }

  ngOnInit() {
    this.config = this.data.configService.config;
    this.initColumns();
    this.actualFilters = cloneDeep(this.data.filterService.filters$.getValue());

    this.mainForm = new FormGroup<MainForm>({
      filterGroups: new FormArray<FormGroup<FilterGroupForm>>([]),
      filterManualChanges: new FormControl<boolean | null>(null),
    });

    if (this.actualFilters?.filterGroups.length) {
      this.actualFilters.filterGroups.forEach((group) => this.addGroup(group));
    } else {
      this.addGroup();
    }

    if (this.actualFilters?.filterManualChanges) {
      this.filterManualChangesControl.setValue(this.actualFilters?.filterManualChanges);
    }

    this.mainForm.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        filter(() => this.mainForm.valid),
      )
      .subscribe(() => (this.actualFilters.filterGroups = this.mapFilters()));
  }

  addGroup(group?: FilterGroupModel) {
    const filters = group
      ? group.filters.map(
          (f) =>
            new FormGroup({
              column: new FormControl(this.config.columns[f.param], Validators.required),
              type: new FormControl(f.symbol, Validators.required),
              operator: new FormControl(f.operator),
              value: new FormGroup({
                data: new FormControl(f.value),
                config: new FormControl(f.case_sensitive),
              }),
              additional_sources: new FormControl(f.additional_sources),
            }),
        )
      : [];

    const isFirst = this.mainForm.controls.filterGroups.controls.length === 0;
    const operator = isFirst ? FilterLogicOperatorEnum.none : FilterLogicOperatorEnum.and;

    const groupForm = new FormGroup<FilterGroupForm>({
      filters: new FormArray(filters),
      operator: new FormControl(group ? group.operator : operator),
    });

    this.mainForm.controls.filterGroups.push(groupForm);
  }

  getGroupFormGroup(index: number): FormGroup {
    return this.groupsFormArray.at(index) as FormGroup;
  }

  setFilter(filterGroups: FilterInStorageModel) {
    this.actualFilters = cloneDeep(filterGroups.filters);
    this.mainForm = new FormGroup<MainForm>({
      filterGroups: new FormArray<FormGroup<FilterGroupForm>>([]),
      filterManualChanges: new FormControl<boolean | null>(null),
    });
    (this.mainForm as any).filterManualChanges = new FormControl(false);
    this.filterManualChangesControl.setValue(filterGroups.filters.filterManualChanges);
    this.actualFilters.filterGroups.forEach((group) => this.addGroup(group));
  }

  catchGroupRemove(index: number) {
    this.groupsFormArray.removeAt(index);
  }

  deleteFiltersFromStorage(filterInStorage: FilterInStorageModel, event: any) {
    event.stopPropagation();
    this.data.filterService.deleteFilterInStorage(filterInStorage);
  }

  openSaveAs(): void {
    this.data.popupService
      .openSaveFilterStoragePopup()
      .pipe(
        takeUntil(this.onDestroy$),
        tap((data) => data.saveAsTab && this.addTab(data.name)),
      )
      .subscribe((data) => this.data.filterService.saveFilterToStorage(data.name, this.actualFilters));
  }

  drop(event: CdkDragDrop<string[]>, array: FilterModel[]) {
    moveItemInArray(array, event.previousIndex, event.currentIndex);
  }

  close() {
    this.dialogRef.close();
  }

  onApproveClick() {
    if (this.data.actionService.globalActions[0]) {
      window.alert('You have to save before change filters');
      return;
    }

    if (this.mainForm.invalid) {
      this.mainForm.markAllAsTouched();
      return;
    }

    this.actualFilters.filterManualChanges = this.filterManualChangesControl.value;
    this.data.filterService.setFilters(this.actualFilters);
    this.close();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private initColumns() {
    const columns = Object.values(this.config.columns);

    this.columns = columns.filter((column) => column.ableToFilter);
  }

  private addTab(name: string) {
    this.config.tools.tabs!.push({
      name,
      filterGroup: this.actualFilters.filterGroups,
      filterManualChanges: this.actualFilters.filterManualChanges,
      position: 'left',
      visible: true,
    });
    this.data.communicationService.tabsChanged$.next();
  }

  private mapFilters(): FilterGroupModel[] {
    const { filterGroups } = this.mainForm.value;

    if (!filterGroups || !Array.isArray(filterGroups)) {
      return [];
    }

    return filterGroups
      .filter((group) => group.filters?.length)
      .map((group) => ({
        operator: group.operator!,
        filters: this.mapFilterInGroup(group.filters!),
      }));
  }

  private mapFilterInGroup(form: FilterData[]): FilterModel[] {
    return form.map((filterData: FilterData) => {
      const value =
        filterData.column.valueType === ColumnValueTypesEnum.number ? sanitizeNumber(filterData.value.data) : filterData.value.data;

      return this.data.filterFactoryService.createFilter(
        filterData.column,
        filterData.type,
        value,
        filterData.operator,
        filterData.value.config,
        filterData.additional_sources,
      );
    });
  }
}
