import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnDestroy, Output, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnsService } from '../../../services/columns.service';
import { ConfigService } from '../../../services/config.service';
import { PopupService } from '../../../services/popup.service';
import { ColumnsSetModel, ColumnsStorageSetModel } from '../../../table-display/columns-switch/columns/column.model';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'sem-column-tool',
  templateUrl: './column-tool.component.html',
  styleUrls: ['./column-tool.component.scss'],
})
export class ColumnToolComponent implements OnDestroy {
  @Output() closed = new EventEmitter();
  public columnsService = inject(ColumnsService);
  public configService = inject(ConfigService);
  columnsToChoose = toSignal(this.columnsService.columnsToChoose$, { initialValue: [] });
  storageColumns = toSignal(this.columnsService.storageColumns$, { initialValue: [] });

  private onDestroy$ = new Subject<void>();
  private popupService = inject(PopupService);

  addColumn(column: string) {
    this.columnsService.addColumn(column);
  }

  removeColumn(column: string) {
    this.columnsService.removeColumn(column);
  }

  useColumnSet(columnSet: ColumnsSetModel | ColumnsStorageSetModel) {
    this.columnsService.setActiveStorage(columnSet);
  }

  deleteColumnSet(columnSet: ColumnsSetModel | ColumnsStorageSetModel, event: MouseEvent): void {
    event.stopPropagation();
    this.columnsService.deleteColumnFromStorage(columnSet);
  }

  openSaveAs(): void {
    this.popupService
      .openStorageSaveAsPopup('column')
      .subscribe((name) => this.columnsService.saveColumnsToStorage(name, this.columnsService.columnChosenToDisplay));
  }

  drop(event: CdkDragDrop<any, any, any>) {
    this.columnsService.changeColumnsOrder(event.currentIndex, event.previousIndex);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
