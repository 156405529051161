import { AsyncPipe, CommonModule, NgClass, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicIoModule } from 'ng-dynamic-component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { MaterialModule } from '../../material-module/material.module';
import { SharedModule } from '../../shared.module';
import { CustomDisplayComponent } from '../modals/custom-display/custom-display.component';
import { CustomEditorComponent } from '../modals/custom-editor/custom-editor.component';
import { MatCheckboxNoopClickDirective } from '../providers/mat-checkbox-noop-click.directive';
import { ColumnsSwitchComponent } from './columns-switch/columns-switch.component';
import { ActionColumnComponent } from './columns-switch/columns/action-column/action-column.component';
import { AutocompleteColumnComponent } from './columns-switch/columns/autocomplete-column/autocomplete-column.component';
import { BooleanColumnComponent } from './columns-switch/columns/boolean-column/boolean-column.component';
import { ChipColumnComponent } from './columns-switch/columns/chip-column/chip-column.component';
import { ComponentColumnComponent } from './columns-switch/columns/component-column.component';
import { CustomColumnComponent } from './columns-switch/columns/custom-column/custom-column.component';
import { DateColumnComponent } from './columns-switch/columns/date-column/date-column.component';
import { FilesizeColumnComponent } from './columns-switch/columns/filesize-column/filesize-column.component';
import { ImageColumnComponent } from './columns-switch/columns/image-column/image-column.component';
import { InnerColumnComponent } from './columns-switch/columns/inner-column/inner-column.component';
import { SelectColumnComponent } from './columns-switch/columns/select-column/select-column.component';
import { SimpleColumnComponent } from './columns-switch/columns/simple-column/simple-column.component';
import { ColumnDirective } from './directives/column.directive';
import { ResizeMaterialTableDirective } from './directives/resize-material-table.directive';
import { HeadersDisplayerComponent } from './headers-displayer/headers-displayer.component';
import { PaginationComponent } from './pagination/pagination.component';
import { CellColorPipe } from './table-displayer/cell-color.pipe';
import { CellLabelPipe } from './table-displayer/cell-label.pipe';
import { ColumnCustomButtonsPipe } from './table-displayer/column-custom-buttons.pipe';
import { CanExpand, DepthPipe, GetExpandedRowsPipe, IsExpandedPipe } from './table-displayer/pipes';
import { TableDisplayerComponent } from './table-displayer/table-displayer.component';
import { ToolActivenessManagerPipe } from './table-displayer/tool-activeness-manager.pipe';
import { OverdueDatePipe } from '../../../dashboard/tasks/pipes/overdue-date.pipe';

@NgModule({
  declarations: [
    ActionColumnComponent,
    AutocompleteColumnComponent,
    BooleanColumnComponent,
    CellColorPipe,
    CellLabelPipe,
    IsExpandedPipe,
    DepthPipe,
    CanExpand,
    GetExpandedRowsPipe,
    ColumnCustomButtonsPipe,
    ColumnDirective,
    ColumnsSwitchComponent,
    ComponentColumnComponent,
    CustomColumnComponent,
    CustomDisplayComponent,
    CustomEditorComponent,
    DateColumnComponent,
    FilesizeColumnComponent,
    HeadersDisplayerComponent,
    ImageColumnComponent,
    InnerColumnComponent,
    PaginationComponent,
    ResizeMaterialTableDirective,
    SelectColumnComponent,
    SimpleColumnComponent,
    TableDisplayerComponent,
    ToolActivenessManagerPipe,
    MatCheckboxNoopClickDirective,
    ChipColumnComponent,
  ],
  imports: [
    AsyncPipe,
    CommonModule,
    DirectivesModule,
    DynamicIoModule,
    FormsModule,
    MatMenuModule,
    MaterialModule,
    NgClass,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PipesModule,
    SharedModule,
    TitleCasePipe,
    TranslateModule,
    OverdueDatePipe,
  ],
  exports: [
    AutocompleteColumnComponent,
    ColumnDirective,
    ComponentColumnComponent,
    CustomColumnComponent,
    DateColumnComponent,
    DirectivesModule,
    FilesizeColumnComponent,
    PaginationComponent,
    PipesModule,
    SelectColumnComponent,
    SimpleColumnComponent,
    TableDisplayerComponent,
    ChipColumnComponent,
  ],
})
export class TableDisplayModule {
}
