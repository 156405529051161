@if (allLeftTabs[0] || rightTabs[0]) {
  <div class="container">
    @for (tab of leftTabs; track tab) {
      <div class="tab" [class.active]="tab.active" (click)="useTab(tab)">
        <span>{{ tab.name | titlecase }}</span>
      </div>
    }

    @if (rightTabs[0]) {
      <div class="divider"></div>
    }

    @for (tab of rightTabs; track tab) {
      <div class="tab" [class.active]="tab.active" (click)="useTab(tab)">
        <span>{{ tab.name | titlecase }}</span>
      </div>
    }

    @if (allLeftTabs[0]) {
      <button [matMenuTriggerFor]="tabsMenu" mat-button>
        <mat-icon>add</mat-icon>
      </button>
    }
  </div>
}

<mat-menu #tabsMenu="matMenu" xPosition="after">
  <ng-template matMenuContent>
    <ng-container *ngTemplateOutlet="menuItems; context: { tabs: allLeftTabs }"></ng-container>
    <ng-container *ngTemplateOutlet="menuItems; context: { tabs: rightTabs }"></ng-container>
  </ng-template>
</mat-menu>

<ng-template #menuItems let-tabs="tabs">
  @for (tab of tabs; track $index) {
    <div class="menu-item-wrapper">
      <button (click)="changeTabState(tab)" [ngClass]="{ active: tab.visible }" mat-menu-item>
        {{ tab.name }}
      </button>
      <button (click)="confirmDelete(tab)" *ngIf="!isDefaultTab(tab.name)" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
</ng-template>
