import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartLineChooserForm } from '../chart-line-chooser/chart-line-chooser-form';
import { ChartMapper } from './chart-mapper';

export type ChartAggregation = 'daily' | 'weekly' | 'monthly';

export enum ChartIndicators {
  CLICKS = 'clicks',
  AVERAGE_CPC = 'average_cpc',
  IMPRESSIONS = 'impressions',
  ROAS = 'roas',
  CONVERSIONS = 'conversions',
  CTR = 'conversion_rate',
  COST = 'cost',
  ALL_CONVERSIONS = 'all_conversions',
  AVERAGE_COST = 'average_cost',
  AVERAGE_CPM = 'average_cpm',
  AVERAGE_CPV = 'average_cpv',
  BUDGET = 'budget',
  CONVERSIONS_VALUE = 'conversions_value',
  COST_PER_CONVERSION = 'cost_per_conversion',
  COST_PER_ALL_CONVERSIONS = 'cost_per_all_conversions',
  INTERACTIONS = 'interactions',
  INTERACTION_RATE = 'interaction_rate',
  IMPRESSION_SHARE = 'impression_share',
  PTR = 'ptr',
  TOP_IMPRESSION_SHARE = 'top_impression_share',
  ABSOLUTE_TOP_IMPRESSION_SHARE = 'absolute_top_impression_share',
}

export enum ChartType {
  msline = 'msline',
  multiaxisline = 'multiaxisline',
  msarea = 'msarea',
  msbar2d = 'msbar2d',
  mscombidy2d = 'mscombidy2d',
  timeseries = 'stackedcolumn2d',
  line = 'line',
  area = 'area',
  donut = 'donut',
  bar = 'bar',
  column = 'column',
}

export const SEM_CHART_CONFIG = new InjectionToken<SemChartConfig>('SemChartConfig');
export const SEM_CHART_MAPPER_DIRECTOR = new InjectionToken<SemChartDirector>('SEM_CHART_MAPPER_DIRECTOR');
export const SEM_CHART_Y_LINE_FORM = new InjectionToken<ChartLineChooserForm>('SEM_CHART_Y_LINE_FORM');
export const SEM_CHART_DATA_STREAM = new InjectionToken<BehaviorSubject<any>[]>('SEM_CHART_DATA_STREAM');

export interface SemChartConfig {
  width?: string;
  height?: string;
  seriesName?: string;
  yLine?: string[];
  title?: string;
  xAxisName?: string;
  theme: 'apex';
  uniqueId?: string;
  stacked?: boolean;
  staticIndicators?: string[];
  aggregation?: 'daily' | 'weekly' | 'monthly';
}

export const Y_LINES_COLORS = ['#2C6ECB', '#5FC696', '#E11E03', '#FFB800'];
export const DOUGHNUT_CHART_COLORS = ['#2C6ECB', '#5FC696', '#E11E03', '#FFB800'];

export interface SemChartDirector<TData = any, TOptions = any> {
  construct: () => ChartMapper<any>;
  generateChartOptions?: (dataSource: TData) => Partial<TOptions>;
  toggleStacking?: () => void;
}

export const defaultSemChartConfig: SemChartConfig = {
  yLine: [ChartIndicators.CLICKS, ChartIndicators.AVERAGE_CPC, ChartIndicators.IMPRESSIONS, ChartIndicators.CONVERSIONS],
  height: '350',
  width: '100%',
  theme: 'apex',
};
