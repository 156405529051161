import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StripeService } from 'ngx-stripe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import routerConfig from 'src/app/configs/router.config';
import { NotificationService } from 'src/app/notification/notification.service';
import { RouterService } from '../../../../shared/service/router.service';
import { PaymentDataModel } from '../../models/payments.model';
import { PaymentsService } from '../../payments.service';
import { CardAttachFormComponent } from '../card-attach-form/card-attach-form.component';

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss'],
})
export class ConfirmPaymentComponent implements OnInit, OnDestroy {
  @ViewChild(CardAttachFormComponent) cardAttachForm!: CardAttachFormComponent;
  private onDestroy$ = new Subject<void>();
  paymentData!: PaymentDataModel;
  isLoading = false;
  status!: string;

  constructor(
    private paymentsService: PaymentsService,
    private route: ActivatedRoute,
    private stripeService: StripeService,
    private routerService: RouterService,
    private notifierSevice: NotificationService,
  ) {}

  ngOnInit(): void {
    this.paymentsService.getPaymentData(this.route.snapshot.params['id']).subscribe((res) => (this.paymentData = res));
  }

  onConfirm(): void {
    this.isLoading = true;
    if (this.paymentData.requiresAction || this.paymentData.requiresConfirmation) {
      this.stripeService
        .confirmCardPayment(this.paymentData.clientSecret)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          () => this.refreshCurrentPackage(),
          () => (this.isLoading = false),
        );
      return;
    }

    if (this.paymentData.requiresPaymentMethod) {
      this.confirmNewPaymentMethod();
    }
  }

  private confirmNewPaymentMethod() {
    const { name } = this.cardAttachForm.stripeForm.value;
    this.stripeService
      .confirmCardPayment(this.paymentData.clientSecret, {
        payment_method: {
          card: this.cardAttachForm.card.element,
          billing_details: {
            name,
          },
        },
      })
      .subscribe(
        (res) => {
          if ('error' in res) {
            this.notifierSevice.error('subscription_confirmation_error', { error: res.error!.message });
            this.isLoading = false;
            this.cardAttachForm.isLoading = false;
          } else {
            this.refreshCurrentPackage();
          }
        },
        () => (this.isLoading = false),
      );
  }

  private refreshCurrentPackage(): void {
    this.paymentsService.getCurrentPackage().subscribe(() => this.routerService.navigate(routerConfig.payments));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
