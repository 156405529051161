import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({
  name: 'overdueDate',
  standalone: true,
  pure: true
})
export class OverdueDatePipe implements PipeTransform {

  transform(
    value: Moment | string | null,
    format: string = 'DD/MM/yyyy'
  ): { formattedDate: string; isOverdue: boolean; className: string } {
    if (!value) {
      return { formattedDate: '', isOverdue: false, className: '' };
    }

    const momentFormat = this.convertAngularFormatToMoment(format);

    let date = moment(value, momentFormat, true);

    if (!date.isValid()) {
      date = moment(value);
    }

    if (!date.isValid()) {
      return { formattedDate: '', isOverdue: false, className: '' };
    }

    const diff = date.diff(moment().startOf('day'), 'days');

    let className = 'future-date';
    let isOverdue = false;
    if (diff < 0) {
      className = 'overdue-date';
      isOverdue = true;
    } else if (diff <= 1) {
      className = 'near-future-date'; // dziś (diff=0) lub jutro (diff=1)
    }

    const formattedDate = date.format(momentFormat);

    return { formattedDate, isOverdue, className };
  }

  /**
   * Pomocnicza metoda: jeśli ktoś podał 'dd/MM/yyyy' (Angular),
   * zamieniamy na 'DD/MM/YYYY' (moment.js). Analogicznie np. 'HH:mm dd/MM/yyyy' -> 'HH:mm DD/MM/YYYY'
   */
  private convertAngularFormatToMoment(angularFormat: string): string {
    return angularFormat
      // zamiana 'yyyy' -> 'YYYY'
      .replace(/y+/g, 'YYYY')
      // zamiana 'dd' -> 'DD'
      .replace(/d+/g, 'DD');
  }
}
