import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { map, merge, of, Subject, takeUntil, tap } from 'rxjs';
import { ItemModel } from '../../item.model';
import { ListingType } from '../../models/TableConfigurationInterface.model';
import { EventBroadcasterService } from '../../services/event-broadcaster.service';
import { ExpansionRowService } from '../../services/expansion-row.service';

@Pipe({
  name: 'isExpanded',
})
export class IsExpandedPipe implements PipeTransform {
  private readonly expansionRowService = inject(ExpansionRowService);

  transform(item: ItemModel) {
    return merge(
      of(this.expansionRowService.isExpanded(item)),
      this.expansionRowService.expansionStateChanged$.pipe(map(() => this.expansionRowService.isExpanded(item))),
    );
  }
}

@Pipe({
  name: 'getDepth',
})
export class DepthPipe implements PipeTransform {
  private readonly expansionRowService = inject(ExpansionRowService);

  transform(item: ItemModel): number {
    return this.expansionRowService.getDepth(item);
  }
}

@Pipe({
  name: 'canExpand',
})
export class CanExpand implements PipeTransform {
  private readonly expansionRowService = inject(ExpansionRowService);

  transform(item: ItemModel) {
    return this.expansionRowService.canExpand(item);
  }
}

@Pipe({
  name: 'getExpandedRows',
})
export class GetExpandedRowsPipe implements PipeTransform, OnDestroy {
  private readonly cd = inject(ChangeDetectorRef);
  private readonly expansionRowService = inject(ExpansionRowService);
  private readonly semtableEventBroadcasterService = inject(EventBroadcasterService);

  private onDestroy$ = new Subject<void>();

  transform(itemId: number, listingType: ListingType) {
    return this.expansionRowService.getExpandedItems(itemId).pipe(
      tap((rows) => {
        this.semtableEventBroadcasterService
          .eventBroadcaster$(rows as ItemModel[], this.cd, listingType, itemId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe();
      }),
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
