<div class="wrapper">
  @if (title()) {
    <p class="title">{{ title() }}</p>
  }
  <div
    (click)="fileInput.click()"
    (dragleave)="onDragLeave()"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
    [class.drag-over]="isDragOver() && !isCmpDisabled()"
    class="container"
    tabindex="0"
    (keypress)="fileInput.click()"
    enctype="multipart/form-data"
  >
    <input #fileInput (change)="onChange($event)" [accept]="acceptTypes()" [multiple]="isMultiple()" class="file-input" type="file" />
    <mat-icon>publish</mat-icon>
    <div class="placeholder">{{ placeholderContent() }}</div>
    @if (isLoading()) {
      <div class="is-loading">
        <mat-spinner></mat-spinner>
      </div>
    }
  </div>
  @if (invalidFiles().length) {
    <div class="validation-error-wrapper">
      <app-validation-error-display [invalidFiles]="invalidFiles()" (closeNotification)="invalidFilesHandler([])" />
    </div>
  }
</div>
