import { inject, Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import posthog from 'posthog-js';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PostHogInitializer {
  private readonly ngZone = inject(NgZone);
  constructor(router: Router) {
    this.ngZone.runOutsideAngular(() => {
      router.events
        .pipe(
          filter((event): event is NavigationEnd => event instanceof NavigationEnd),
          takeUntilDestroyed(),
        )
        .subscribe((event) =>
          posthog.capture('route_changed', {
            sembotUrl: event.url,
          }),
        );
    });
  }
}

export const initPostHog = (ngZone: NgZone) => {
  ngZone.runOutsideAngular(() => {
    posthog.init(environment.postHogKey, {
      api_host: 'https://eu.i.posthog.com',
      capture_pageview: false,
      capture_pageleave: true,
    });
  });
};
