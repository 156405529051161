<ng-container>
  <h2 class="title" mat-dialog-title>{{ 'sem_table.upload_photo' | translate }}</h2>
  <button class="close-button" mat-dialog-close mat-icon-button tabindex="-1">
    <mat-icon class="close-icon">close</mat-icon>
  </button>

  <mat-dialog-content>
    <div class="container">
      <div class="add-form">
        <app-image-uploader
          (filesToUpload)="sendImagesToApi()"
          [allowedTypes]="['image/jpeg', 'image/png']"
          [isDisabled]="disable"
          placeholder="{{ 'sem_table.upload_image_placeholder' | translate }}"
        />
      </div>

      <div class="images-container">
        <div *ngFor="let image of imagesInApi" [ngClass]="{ main: image.main }" class="image">
          <button
            (click)="deleteImage()"
            *ngIf="!image.from_feed"
            aria-label="delete photo icon"
            class="remove-photo-btn"
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete</mat-icon>
          </button>
          <img (click)="onClick()" alt="image of product" src="{{ image.thumb_path }}" />
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-container>
