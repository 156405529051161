import { Injectable } from '@angular/core';
import { InvalidFileInterface } from '../../../../shared/model/file.model';

@Injectable({
  providedIn: 'root',
})
export class ImageValidationService {
  private readonly MIN_WIDTH = 100;
  private readonly MIN_HEIGHT = 100;
  private readonly MAX_MEGAPIXELS = 64;
  private readonly MAX_FILE_SIZE_KB = 16 * 1024;

  async validateFile(file: File): Promise<string | null> {
    const image = new Image();
    const fileUrl = URL.createObjectURL(file);

    return new Promise((resolve) => {
      image.onload = () => {
        const { width } = image;
        const { height } = image;
        const sizeInKB = file.size / 1024;
        const megapixels = (width * height) / 1_000_000;

        URL.revokeObjectURL(fileUrl);

        if (width < this.MIN_WIDTH || height < this.MIN_HEIGHT) {
          resolve('resolutionMin');
        } else if (megapixels > this.MAX_MEGAPIXELS) {
          resolve('resolutionMax');
        } else if (sizeInKB > this.MAX_FILE_SIZE_KB) {
          resolve('sizeMax');
        } else {
          resolve(null);
        }
      };

      image.onerror = () => {
        URL.revokeObjectURL(fileUrl);
        resolve('invalidFile');
      };

      image.src = fileUrl;
    });
  }

  async validateFiles(files: File[]): Promise<{ validFiles: File[]; invalidFiles: InvalidFileInterface[] }> {
    const validFiles: File[] = [];
    const invalidFiles: InvalidFileInterface[] = [];

    for (const file of files) {
      const error = await this.validateFile(file);
      if (error) {
        invalidFiles.push({ name: file.name, error });
      } else {
        validFiles.push(file);
      }
    }

    return { validFiles, invalidFiles };
  }
}
