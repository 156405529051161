<div [ngClass]="{ 'background-active': isActive }" class="container">
  <div>
    @switch (mainToolbarService.currentState) {
      @case (possibleStates.column) {
        <sem-column-tool (closed)="disableColumnTool()"></sem-column-tool>
      }
      @case (possibleStates.select) {
        <sem-select-tool></sem-select-tool>
      }
    }
  </div>
</div>

<sem-tabs-tool></sem-tabs-tool>
