import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomDisplayComponent } from '../../../../modals/custom-display/custom-display.component';
import { CustomEditorComponent } from '../../../../modals/custom-editor/custom-editor.component';
import { CustomEditorPopupDataInterface } from '../../../../models/modals/CustomEditorPopupDataInterface';
import { AutocompleteService } from '../../../../services/autocomplete.service';
import { PopupService } from '../../../../services/popup.service';
import { ColumnsComponentInterface } from '../editable-column.component';
import { CustomColumn } from './CustomColumn';

@Component({
  selector: 'sem-custom-column',
  templateUrl: './custom-column.component.html',
  styleUrls: ['./custom-column.component.scss', '../shared-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomColumnComponent implements ColumnsComponentInterface {
  @Input() column!: CustomColumn;
  @Input() value: any;
  @Input() displayNotEditable!: boolean;
  @Input() autocompleteService!: AutocompleteService;
  @Input() popupService!: PopupService;
  @Output() editStateExited: EventEmitter<null> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() emitCustomEvent: EventEmitter<{ columnName: string; mode: 'edit' | 'display' }> = new EventEmitter();
  @ViewChild('input') input!: ElementRef;
  @Output() editStateEntered = new EventEmitter<unknown>();

  private dialog = inject(MatDialog);

  @HostListener('keydown', ['$event']) keydown(event: KeyboardEvent) {
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.exitEditableState();
    }
    if (event.key === 'Enter') {
      this.click();
    }
  }

  exitEditableState(): void {
    this.editStateExited.emit();
  }

  enterEditableMode() {
    this.click();
  }

  click() {
    if (this.column.editable && !this.displayNotEditable) {
      this.openEditor();
    } else {
      this.openDisplay();
    }
  }

  private openEditor() {
    if (this.column.emitCustomEvent) {
      this.emitCustomEvent.emit({ columnName: this.column.param, mode: 'edit' });
      return;
    }

    this.openCustomColumnEditor(this.value, this.column).subscribe((value) => this.valueChanged.next(value));
  }

  private openDisplay() {
    if (this.column.emitCustomEvent) {
      this.emitCustomEvent.emit({ columnName: this.column.param, mode: 'display' });
      return;
    }

    this.openCustomColumnDisplayer(this.value, this.column);
  }

  private openCustomColumnEditor(value: any, column: CustomColumn): Observable<string[]> {
    const { autocompleteService } = this;
    const data = {
      value,
      column,
      autocompleteService,
    } as CustomEditorPopupDataInterface;
    const dialogRef = this.dialog.open(CustomEditorComponent, {
      data,
      panelClass: 'no-dialog-spinner',
      width: 'auto',
    });

    return dialogRef.afterClosed().pipe(filter((filteredData) => filteredData));
  }

  private openCustomColumnDisplayer(value: any, column: CustomColumn): Observable<string[]> {
    const dialogRef = this.dialog.open(CustomDisplayComponent, {
      data: { value, column } as CustomEditorPopupDataInterface,
      panelClass: 'no-dialog-spinner',
    } as MatDialogConfig);

    return dialogRef.afterClosed();
  }
}
