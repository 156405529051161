import { ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, signal, Renderer2, inject, computed, } from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnValueTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnsComponentInterface } from '../editable-column.component';
import { SimpleColumn } from './SimpleColumn';

@Component({
  selector: 'sem-simple-column',
  templateUrl: 'simple-column.component.html',
  styleUrls: ['./simple-column.component.scss', '../shared-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleColumnComponent implements OnInit, OnDestroy, ColumnsComponentInterface {
  @Input() column!: SimpleColumn;
  @Input() displayNotEditable!: boolean;
  @Output() editStateEntered: EventEmitter<null> = new EventEmitter();
  @Output() editStateExited: EventEmitter<boolean> = new EventEmitter();
  @Output() navigationColumn: EventEmitter<SimpleColumn> = new EventEmitter();
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('input') input!: ElementRef;
  @Input() disabled: boolean = false;
  readonly isNumberType = computed(() => this.column?.valueType === ColumnValueTypesEnum.number);
  protected valueMaxLength = 50;
  protected valueTypes = ColumnValueTypesEnum;
  protected isFullContentSignal = signal(false);
  protected editStateSignal = signal(false);
  protected valueLengthSignal = signal(0);
  private valueCaughtOnFocus: string | null = null;
  private onDestroy$ = new Subject<void>();
  private readonly SIMPLE_INPUT_MAX_LENGTH = 50;
  private cd = inject(ChangeDetectorRef);
  private renderer = inject(Renderer2);

  protected _value!: string;

  @Input() set value(value: string | number | null | undefined) {
    if (typeof value === 'number') {
      this._value = value.toString();
    } else {
      this._value = value == null ? '' : value;
    }
  }

  readonly canUseSimpleInput = computed(() => {
    if (!this.column) return false;
    return (
      this.column.valueType !== ColumnValueTypesEnum.number &&
      (this._value?.length ?? 0) <= this.SIMPLE_INPUT_MAX_LENGTH &&
      !this.column.textAreaAlways
    );
  });

  @HostListener('keydown', ['$event'])
  keydown(event: KeyboardEvent): void {
    event.stopPropagation();
    if (event.key === 'Escape' || (event.keyCode === ENTER && !event.shiftKey)) {
      this.exitEditableState();
    }
  }

  @HostListener('dblclick', ['$event'])
  dbClick(event: MouseEvent): void {
    event.stopPropagation();
    this.enterEditableMode();
  }

  ngOnInit() {
    this.valueLengthSignal.set(this._value?.length || 0);
    if (this.column?.stringTruncateLimit) {
      this.valueMaxLength = this.column.stringTruncateLimit;
    }
  }

  click(): void {
  }

  enterEditableMode(): void {
    if (!this.column.editable || this.displayNotEditable) {
      this.showFullText();
      return;
    }
    this.editStateEntered.emit();
    this.editStateSignal.set(true);
    this.cd.detectChanges();
    setTimeout(() => {
      if (this.input?.nativeElement) {
        this.renderer.selectRootElement(this.input.nativeElement).focus();
        this.valueCaughtOnFocus = this.input.nativeElement.value;
      }
    }, 10);
  }

  exitEditableState(): void {
    this.checkIfValueWasChanged();
    this.editStateExited.emit();
    this.editStateSignal.set(false);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  protected showFullText(): void {
    this.isFullContentSignal.set(!this.isFullContentSignal());
  }

  protected calculateValueLength(): void {
    if (!this.column.lettersLimit) {
      return;
    }
    const length = this.input.nativeElement.value?.length ?? 0;
    this.valueLengthSignal.set(length);
  }

  private checkIfValueWasChanged(): void {
    let currentValue: string = this.input.nativeElement.value;
    if (currentValue === null) {
      currentValue = '';
    }
    if (currentValue !== this.valueCaughtOnFocus) {
      this.valueChanged.emit(currentValue);
      this.valueCaughtOnFocus = null;
    }
  }
}
