import { HttpClient, HttpErrorResponse, HttpContext } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import ApiUrls from '../../configs/api-urls.config';
import { PaginationResourceInterface, ResponseV2Interface } from '../model/response.model';
import { UserModel } from '../model/user.model';
import { FilterGroupModel } from '../sem-table/filters/models/filter.model';
import { ERROR_NOTIFICATION_SKIP } from '../../interceptors/error-interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  private http = inject(HttpClient);

  addUser(user: {
    email: string;
    name: string;
    password: string;
    permissions: number[];
    projects: number[];
    role?: number | null;
    team?: number | null;
  }): Observable<UserModel> {
    return this.http.post<ResponseV2Interface<UserModel>>(ApiUrls.users, user).pipe(
      map((response) => response.data),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }

  addUserByMail(inviteData: { emails: string[]; permissions: string[]; projects: number[]; role: number | null }): Observable<void> {
    return this.http.post<ResponseV2Interface<void>>(ApiUrls.bulkAdd, inviteData).pipe(map((response) => response.data));
  }

  getUser(userId: number): Observable<UserModel> {
    const context = new HttpContext().set(ERROR_NOTIFICATION_SKIP, [404]);

    return this.http.get<ResponseV2Interface<UserModel>>(ApiUrls.user.prepareUrl({ user: userId }), { context }).pipe(
      map((response) => response.data),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }

  getUsers(
    per_page: number,
    page: number = 0,
    filterGroups: FilterGroupModel[] | null = null,
  ): Observable<PaginationResourceInterface<UserModel>> {
    return this.http.post<PaginationResourceInterface<UserModel>>(ApiUrls.usersSearch, {
      per_page,
      page,
      filterGroups,
    });
  }

  updateUser(userId: number, user: UserModel): Observable<UserModel> {
    return this.http.patch<ResponseV2Interface<UserModel>>(ApiUrls.user.prepareUrl({ user: userId }), user).pipe(
      map((response) => response.data),
      catchError((error: HttpErrorResponse) => throwError(error.error)),
    );
  }

  deleteUser(userId: number): Observable<void> {
    return this.http.delete<void>(ApiUrls.user.prepareUrl({ user: userId }));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
