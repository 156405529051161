@if (!isColumnInEditState) {
  <div *ngIf="translateKey" class="badge badge-{{ type }}" [ngClass]="{ 'badge-outlined': isOutlined }">
    {{ translateKey | translate }}
  </div>
} @else {
  <mat-form-field>
    <mat-select [appAutoFocus]="true" *ngIf="editingList" [value]="valueKey" (selectionChange)="changeColumnValue($event.value)">
      @if (nullable) {
        <mat-option [value]="null">{{ 'tasks.priority.none' | translate }}</mat-option>
      }
      <mat-option *ngFor="let item of editingList" [value]="item.key">
        {{ item.translateKey! | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
}
