import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/service/auth.service';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (state.url.substring(1, state.url.lastIndexOf('/')) === 'auth/verify') {
      return true;
    }

    if (route.params['token']) {
      this.authService.saveToken(route.params['token']);
    }

    if (!this.authService.token) {
      return true;
    }
    return this.router.parseUrl('/dashboard');
  }
}
