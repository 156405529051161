import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class CapitalizedAction extends ActionModel {
  override type = ActionTypesEnum.capitalized;
  override value = null;

  isValid(): boolean {
    return !!this.columnName;
  }

  use(item: ItemModel) {
    let str = item[this.columnName as keyof typeof item] as string;
    str = str.toLowerCase();

    let splitStr = str.toLowerCase().split(' ');
    splitStr = splitStr.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));

    return splitStr.join(' ');
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
  }
}
