@if (editStateSignal()) {
  <mat-form-field appearance="outline" class="{{ column.param }}-column-display">
    @if (isNumberType()) {
      <input
        #input
        (blur)="exitEditableState()"
        [disabled]="disabled"
        [value]="_value"
        matInput
        type="number"
      />
    } @else if (canUseSimpleInput()) {
      <input
        #input
        (blur)="exitEditableState()"
        (input)="calculateValueLength()"
        [disabled]="disabled"
        [value]="_value | mapColumnValue: column"
        matInput
        type="'text'"
      />
    } @else {
      <textarea
        #autosize="cdkTextareaAutosize"
        #input
        (blur)="exitEditableState()"
        (input)="calculateValueLength()"
        [disabled]="disabled"
        [value]="_value | mapColumnValue: column"
        cdkAutosizeMaxRows="10"
        cdkAutosizeMinRows="1"
        cdkTextareaAutosize
        matInput
        type="text"
      >
      </textarea>
    }
  </mat-form-field>
} @else {
  <span
    #input
    [appOnlyNumber]="column.valueType === valueTypes.number"
    [isFullContent]="isFullContentSignal()"
    [ngClass]="{
      'not-editable': displayNotEditable,
      'full-content': isFullContentSignal() && valueLengthSignal() > valueMaxLength
    }"
    [semColumn]="column.directive!"
    [value]="_value"
    class="value"
  >
    {{ isFullContentSignal() ? _value : (_value | truncate: valueMaxLength : column.valueType) }}
  </span>
}

@if (valueLengthSignal() > valueMaxLength && !column._isNavigationColumn) {
  <div (click)="showFullText()" class="full-content-expander">
    @if (isFullContentSignal()) {
      <mat-icon>expand_less</mat-icon>
      {{ 'sem_table.show_less' | translate }}
    } @else {
      <mat-icon>expand_more</mat-icon>
      {{ 'sem_table.show_more' | translate }}
    }
  </div>
}

@if (column.lettersLimit) {
  <div [ngClass]="{ warn: valueLengthSignal() > column.lettersLimit }" class="limitter">
    {{ valueLengthSignal() }} / {{ column.lettersLimit }}
  </div>
}
