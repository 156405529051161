import { Component, DestroyRef, HostListener, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sem-upload-photo-popup',
  templateUrl: './upload-photo-popup.component.html',
  styleUrls: ['./upload-photo-popup.component.scss'],
})
export class UploadPhotoPopupComponent implements OnInit {
  disable = false;
  imagesInApi!: any[];
  sub!: Subscription;
  initMainImageId!: number;

  private readonly dialogRef = inject(MatDialogRef);
  private readonly destroy = inject(DestroyRef);

  @HostListener('keydown', ['$event']) c(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.getImages();
  }

  setCloseSubscription() {
    this.sub = this.dialogRef
      .backdropClick()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe(() => {
        const actualMainImage = this.imagesInApi.find((image) => image.main);
        const res = actualMainImage && this.initMainImageId !== actualMainImage.id ? actualMainImage : null;
        this.dialogRef.close(res);
      });
  }

  getImages() {}

  onClick() {}

  sendImagesToApi() {}

  deleteImage() {}

  close() {}
}
