import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class NotEmptyFilterModel extends FilterModel {
  override symbol: FilterTypesEnum = FilterTypesEnum.not_empty;

  isValid() {
    return true;
  }
}
