<div class="container">
  <span class="title">{{ 'sem_table.column_tool' | translate | titlecase }}:</span>
  <div class="tool-container">
    <div (cdkDropListDropped)="drop($event)" appVerticalScrollable cdkDropList cdkDropListOrientation="horizontal"
         class="column-container">

      @for (column of columnsService.columnChosenToDisplay; track $index) {
        <div cdkDrag class="column">
          <span class="alias">{{ configService.config.columns[column].alias }}</span>
          <mat-icon (click)="removeColumn(column)" class="remove-icon">close</mat-icon>
        </div>
      }
    </div>

    @if (columnsToChoose().length) {
      <button [matMenuTriggerFor]="addColumnMenu" class="add-new-column-btn" mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
    }

    <!-- Menu dla wyświetlanych kolumn -->
    <mat-menu #addColumnMenu="matMenu" class="column-menu" xPosition="after">
      <ng-template matMenuContent>
        <sem-column-list-display
          (columnClicked)="addColumn($event)"
          [closeAfterChoose]="false"
          [columns]="columnsToChoose()"
          [config]="configService.config"
        >
        </sem-column-list-display>
      </ng-template>
    </mat-menu>
  </div>

  <div class="btn-container">
    <!------------          storage           ------------------->
    <button [matMenuTriggerFor]="storageMenu" mat-button>
      <span class="title">{{ 'sem_table.memory' | translate | titlecase }}</span>
    </button>
    <button (click)="closed.emit()" class="close-tool-btn" mat-button>
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <!------------ menu for storage ------------------->
  <mat-menu #storageMenu="matMenu" class="column-menu" xPosition="after">
    @for (columnSet of storageColumns(); track columnSet.name.name) {
      <button
        (click)="useColumnSet(columnSet)"
        [matTooltip]="columnSet.name.name"
        class="storage-btn-container"
        mat-menu-item
      >
        <div class="iconContainer">
          <mat-icon (click)="deleteColumnSet(columnSet, $event)">delete</mat-icon>
        </div>
        {{ columnSet?.name?.name | truncate: 15 }}
      </button>
    }

    <button (click)="openSaveAs()" mat-menu-item>{{ 'sem_table.save_as' | translate }}</button>
  </mat-menu>
</div>
