import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GroupService implements OnDestroy {
  private unSubscribe$ = new Subject<void>();
  groupedBy: string[] = [];
  groupedBy$: BehaviorSubject<string[]> = new BehaviorSubject(this.groupedBy);
  addToGroupedBy$: Subject<string> = new Subject();
  removeGroupedBy$: Subject<string> = new Subject();

  constructor() {
    this.groupedBy$.pipe(takeUntil(this.unSubscribe$)).subscribe((groups) => (this.groupedBy = groups));

    this.addToGroupedBy$
      .pipe(
        takeUntil(this.unSubscribe$),
        filter((column) => !this.groupedBy.includes(column)),
      )
      .subscribe((column) => this.groupedBy$.next([...this.groupedBy, column]));

    this.removeGroupedBy$
      .pipe(
        takeUntil(this.unSubscribe$),
        filter((column) => this.groupedBy.includes(column)),
        tap((column) => this.groupedBy.splice(this.groupedBy.indexOf(column), 1)),
      )
      .subscribe(() => this.groupedBy$.next(this.groupedBy));
  }

  changeGroupOrder(actualIndex: number, previousIndex: number) {
    const temp = this.groupedBy[previousIndex];

    if (actualIndex > previousIndex) {
      this.groupedBy.splice(previousIndex, 1);
      this.groupedBy.splice(actualIndex, 0, temp);
    } else if (actualIndex < previousIndex) {
      this.groupedBy.splice(actualIndex, 0, temp);
      this.groupedBy.splice(previousIndex + 1, 1);
    }
    this.groupedBy$.next(this.groupedBy);
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
  }
}
