import { NgClass, NgStyle } from '@angular/common';
import { Component, input, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [MatIcon, MatProgressSpinner, NgClass, MatButtonModule, NgStyle],
})
export class ButtonComponent {
  @Input() color: string | null = null;
  @Input() type = 'button';
  @Input() text = 'empty';
  @Input() id?: string = '';
  @Input() loading = false;
  @Input() icon: string | null = null;
  @Input() disabled = false;
  @Input() fullWidth? = false;
  @Input() size?: 'small' | 'medium' | 'large' | 'xl' = 'small';
  @Input() marginSize?: string | null = null;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Input() onClick = () => {};
  testId = input<null | string>(null);
}
