import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class NumberBiggerOrEqualFilterModel extends FilterModel {
  override symbol: FilterTypesEnum = FilterTypesEnum.num_biggerrEqual;

  isValid() {
    return !!this.value;
  }
}
