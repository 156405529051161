<div [cdkTrapFocusAutoCapture]="true" cdkTrapFocus class="row">
  <div class="col">
    <div class="login__container">
      <div class="top">
        <h1 class="login__title">
          {{ 'auth.login.title' | translate }}
        </h1>
      </div>

      <div class="providers-container">
        <app-divider>{{ 'auth.login.sign-in-with' | translate }}</app-divider>
        <app-auth-providers />
        <app-divider>{{ 'auth.login.sign-in-or' | translate }}</app-divider>
      </div>

      <form (ngSubmit)="onSubmit()" [formGroup]="form" id="form">
        <div class="main-content">
          @if (form.errors && form.errors['serverError']) {
            <div class="errorMessage">
              {{ form.errors['serverError'] }}
            </div>
          }

          <label class="login__form-label" for="email">{{ 'auth.login.form.fields.email.label' | translate }}</label>
          <mat-form-field appearance="outline">
            <input
              [formControl]="email"
              [placeholder]="'auth.login.form.fields.email.placeholder' | translate"
              matInput
              type="email"
              id="email"
              data-testid="email"
            />
            <app-form-field-error-display matError></app-form-field-error-display>
          </mat-form-field>

          <label class="login__form-label" for="password">{{ 'auth.login.form.fields.password.label' | translate }}</label>
          <mat-form-field appearance="outline">
            <input
              class="sentry-mask"
              id="password"
              data-testid="password"
              [formControl]="password"
              [placeholder]="'auth.login.form.fields.password.placeholder' | translate"
              [type]="isPasswordVisible ? 'text' : 'password'"
              matInput
            />
            <button class="mat-icon-password" mat-icon-button matSuffix type="button" (click)="isPasswordVisible = !isPasswordVisible">
              <mat-icon>{{ isPasswordVisible ? 'visibility_off' : 'visibility' }} </mat-icon>
            </button>
            <app-form-field-error-display matError></app-form-field-error-display>
          </mat-form-field>

          <span appRouter="authForgot" class="login__forgot-password">{{ 'auth.login.forgot_password' | translate }}</span>
        </div>

        <app-button
          [fullWidth]="true"
          [loading]="isLoading"
          [text]="'auth.login.form.submit_btn_txt' | translate"
          color="primary"
          size="xl"
          type="submit"
          testId="login-submit"
        ></app-button>
      </form>
      <div class="bottom">
        <div class="bar">
          <span>{{ 'auth.login.no_account_info' | translate }}</span>
          <span class="link" appRouter="authRegister">
            {{ 'auth.login.register_link_txt' | translate }}
          </span>
        </div>
      </div>
    </div>
    <app-brand-logos></app-brand-logos>
  </div>
</div>
