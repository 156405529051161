import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, Subject } from 'rxjs';
import { ListingType } from 'src/app/shared/sem-table/public-api';
import { EventBroadcaster, EventBroadcasterService, InputRowEvent } from 'src/app/shared/sem-table/services/event-broadcaster.service';

interface TaskBaseBroadcasterEvent {
  id: number;
  parentId?: number | null;
}

interface TaskActionsBroadcasterEvent {
  ids: number[];
  kind: 'actionsSavedSuccess';
  parentId: number | null;
}

interface TaskCommentBroadcasterEvent extends TaskBaseBroadcasterEvent {
  kind: 'commentSavedSuccess';
}

interface TaskDeletedBroadcasterEvent extends TaskBaseBroadcasterEvent {
  kind: 'taskDeletedSuccessEvent';
}

interface TaskCreatedBroadcasterEvent {
  kind: 'taskCreatedSuccess';
  parentId: number | null;
}

interface TaskUpdatedBroadcasterEvent extends TaskBaseBroadcasterEvent {
  kind: 'taskUpdatedSuccess';
}

type TaskBroadcasterEvent =
  | TaskActionsBroadcasterEvent
  | TaskCommentBroadcasterEvent
  | TaskCreatedBroadcasterEvent
  | TaskDeletedBroadcasterEvent
  | TaskUpdatedBroadcasterEvent;

class AbsEventBroadcaster {
  LISTING_TYPE!: ListingType;

  private readonly broadcasterSubject = new Subject<TaskBroadcasterEvent>();
  private readonly parentBroadcasterSubject = new Subject<{ listingType: ListingType; parentId: number }>();
  private readonly semtableEventBroadcasterService = inject(EventBroadcasterService);
  private readonly tableEventData = (id: number | null, parentId?: number | string | null): EventBroadcaster => ({
    listingType: this.LISTING_TYPE,
    parentRowId: parentId || null,
    rowId: id || null,
  });

  readonly broadcaster$ = this.broadcasterSubject.asObservable();
  readonly parentBroadcaster$ = this.parentBroadcasterSubject.asObservable().pipe(
    takeUntilDestroyed(),
    filter((event) => event.listingType === this.LISTING_TYPE),
  );

  constructor(listingType: ListingType) {
    this.LISTING_TYPE = listingType;
  }

  actions({ ids, parentId }: { ids: number[]; parentId: number | null }) {
    this.broadcasterSubject.next({ kind: 'actionsSavedSuccess', ids, parentId } as TaskActionsBroadcasterEvent);

    // @TODO: -> semtableEventBroadcasterService, ogarnąć to jak resztę
  }

  comment({ id, parentId }: TaskBaseBroadcasterEvent, input?: InputRowEvent) {
    this.broadcasterSubject.next({ kind: 'commentSavedSuccess', id, parentId } as TaskCommentBroadcasterEvent);
    this.semtableEventBroadcasterService.updateRow(this.tableEventData(id, parentId), input);
  }

  create({ parentId }: { parentId: number | null }, input?: InputRowEvent) {
    this.broadcasterSubject.next({ kind: 'taskCreatedSuccess', parentId } as TaskCreatedBroadcasterEvent);
    this.semtableEventBroadcasterService.createRow(this.tableEventData(null, parentId), input);
    this.updateParentTaskToo(parentId);
  }

  delete({ id, parentId }: TaskBaseBroadcasterEvent) {
    this.broadcasterSubject.next({ kind: 'taskDeletedSuccessEvent', id, parentId } as TaskDeletedBroadcasterEvent);
    this.semtableEventBroadcasterService.deleteRow(this.tableEventData(id, parentId));
    this.updateParentTaskToo(parentId);
  }

  loading({ id, parentId }: TaskBaseBroadcasterEvent, status = true) {
    this.semtableEventBroadcasterService.loadingRow(this.tableEventData(id, parentId), status);
  }

  update({ id, parentId }: TaskBaseBroadcasterEvent, input?: InputRowEvent) {
    this.broadcasterSubject.next({ kind: 'taskUpdatedSuccess', id, parentId } as TaskUpdatedBroadcasterEvent);
    this.semtableEventBroadcasterService.updateRow(this.tableEventData(id, parentId), input);
    this.updateParentTaskToo(parentId);
  }

  private updateParentTaskToo(parentId: number | null | undefined) {
    if (parentId) {
      // Jeśli dotyczy subtaska to zaktualizować musimy też parenta (np. subtasks coutner)
      this.parentBroadcasterSubject.next({ listingType: this.LISTING_TYPE, parentId });
    }
  }
}
@Injectable({ providedIn: 'root' })
export class TaskEventBroadcaster extends AbsEventBroadcaster {
  constructor() {
    super('tasks');
  }
}

@Injectable({ providedIn: 'root' })
export class TaskTemplateEventBroadcaster extends AbsEventBroadcaster {
  constructor() {
    super('task-templates');
  }
}
