import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { CatchTokenComponent } from '../catch-token/catch-token.component';
import { MaterialModule } from '../shared/material-module/material.module';
import { SharedModule } from '../shared/shared.module';
import { AccountConfirmComponent } from './account-confirm/account-confirm.component';
import { AuthProvidersComponent } from './auth-providers/auth-providers.component';
import { AuthRoutingModule } from './auth-routing.module';
import { BrandLogosComponent } from './brand-logos/brand-logos.component';
import { AuthChangeLanguageComponent } from './change-language/auth-change-language.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { DividerComponent } from './divider/divider.component';

@NgModule({
  declarations: [AccountConfirmComponent, RegisterComponent, CatchTokenComponent],
  imports: [
    LoginComponent,
    SetPasswordComponent,
    ForgotPasswordComponent,
    BrandLogosComponent,
    AuthRoutingModule,
    SharedModule,
    MaterialModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgOptimizedImage,
    AuthProvidersComponent,
    AuthChangeLanguageComponent,
    DividerComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha?.siteKey || null,
      } as RecaptchaSettings,
    },
  ],
  exports: [BrandLogosComponent, AuthChangeLanguageComponent],
})
export class AuthModule {}
