import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
  private translateService = inject(TranslateService);

  constructor() {
    super();
    this.translateLabels();
    this.translateService.onLangChange
      .pipe(
        tap(() => this.translateLabels()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.translateService.instant('sem_table.page_of')} ${length}`;
    }
    // eslint-disable-next-line no-param-reassign
    length = Math.max(length, 0);
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);
    return `${startIndex + 1} - ${endIndex} ${this.translateService.instant('sem_table.page_of')} ${length}`;
  };

  translateLabels(): void {
    this.itemsPerPageLabel =
      this.translateService.instant('sem_table.page_items_per_page').charAt(0).toUpperCase() +
      this.translateService.instant('sem_table.page_items_per_page').slice(1);
    this.changes.next();
  }
}
