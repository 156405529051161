import {
  Component,
  signal,
  input,
  computed,
  inject,
  ViewChild,
  ElementRef,
  output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ImageUploaderService } from 'src/app/dashboard/project/assets/image-uploader/image-uploader.service';
import { ImageValidationService } from 'src/app/dashboard/project/assets/image-uploader/image-validation.service';
import { InvalidFileInterface } from '../../model/file.model';
import { FormApiValidationError } from '../../model/errors/formApiError.model';
import { ValidationErrorDisplayComponent } from 'src/app/dashboard/project/assets/validation-error-display/validation-error-display.component';

@Component({
  selector: 'app-image-uploader',
  standalone: true,
  imports: [MatIcon, MatProgressSpinner, ValidationErrorDisplayComponent],
  templateUrl: './image-uploader.component.html',
  styleUrl: './image-uploader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploaderComponent {
  private readonly translateService = inject(TranslateService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly imageUploaderService = inject(ImageUploaderService);
  private readonly imageValidationService = inject(ImageValidationService);

  @ViewChild('fileInput') fileInput!: ElementRef;

  readonly isDefaultValidation = input<boolean>(true);
  readonly isDisabled = input<boolean>(false);
  readonly isMultiple = input<boolean>(false);
  readonly placeholder = input<string>('');
  readonly title = input<string>('');
  readonly allowedTypes = input<string[]>(['image/jpeg', 'image/jpg', 'image/bmp', 'image/png']);
  readonly invalidFiles = input<InvalidFileInterface[]>([]);

  readonly filesToUpload = output<File[]>();
  readonly assetUploaded = output<void>();
  readonly invalidFilesEvent = output<InvalidFileInterface[]>();

  readonly isLoading = signal<boolean>(false);
  readonly isDragOver = signal<boolean>(false);

  readonly placeholderContent = computed(
    () => this.placeholder() || this.translateService.instant('projects.products.photo_popup.uploader_placeholder'),
  );
  readonly acceptTypes = computed(() => this.allowedTypes().join(','));
  readonly isCmpDisabled = computed(() => this.isDisabled() || this.isLoading());

  onDrop(event: DragEvent): void {
    if (this.isCmpDisabled()) {
      return;
    }

    event.preventDefault();
    this.setIsDragOver(false);
    if (event.dataTransfer!.items) {
      const droppedFiles: File[] = [];

      for (const item of Array.from(event.dataTransfer!.items)) {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            droppedFiles.push(file);
          }
        }
      }

      if (droppedFiles.length) {
        this.checkFileTypes(droppedFiles);
      }
    }
  }

  onChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      this.checkFileTypes(Array.from(target.files));
    }
  }

  onClick(event: MouseEvent): void {
    if (this.isCmpDisabled()) {
      return;
    }
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.setIsDragOver(false);
  }

  onDragLeave(): void {
    this.setIsDragOver(false);
  }

  private setIsDragOver(val: boolean): void {
    this.isDragOver.set(val);
  }

  private checkFileTypes(files: File[]): void {
    const filesToUpload: File[] = files.filter((file) => file.type && this.allowedTypes().includes(file.type));
    if (this.isDefaultValidation()) {
      this.checkFiles(filesToUpload);
    } else {
      this.filesToUpload.emit(filesToUpload);
    }
  }

  invalidFilesHandler(files: InvalidFileInterface[]): void {
    this.invalidFilesEvent.emit(files);
  }

  private async checkFiles(files: File[]): Promise<void> {
    if (!files?.length) {
      return;
    }

    const { validFiles, invalidFiles } = await this.imageValidationService.validateFiles(files);
    this.invalidFilesHandler(invalidFiles);

    if (validFiles.length) {
      this.sendImagesToApi(validFiles);
    }
  }

  private sendImagesToApi(files: File[]): void {
    this.isLoading.set(true);
    this.imageUploaderService
      .uploadImages(files)
      .pipe()
      .subscribe({
        next: () => {
          this.isLoading.set(false);
          this.cd.detectChanges();
          this.assetUploaded.emit();
        },
        error: (m: FormApiValidationError) => {
          this.isLoading.set(false);
          this.cd.detectChanges();
          m.notify();
        },
      });
  }
}
