<div *ngIf="config; else noConfig" [class]="(config && config.configuration && config.configuration.storageName) || ''" id="container">
  <span #top></span>

  <div class="main-panel">
    <!------------          Toolbars           ------------------->
    <!-- <sem-main-toolbar2
      *ngIf="config"
      [dataToDisplayCounter]="dataToDisplay?.total"
    ></sem-main-toolbar2> -->
    <sem-main-toolbar>
      <ng-content></ng-content>
    </sem-main-toolbar>
  </div>
  <!---------------------------           init                 ------------------------>

  <div *ngIf="!dataToDisplay?.data?.length; else table">
    <div class="empty-container">
      {{ dataToDisplay ? ('sem_table.no_data_to_display' | translate) : ('sem_table.loading_data' | translate) }}
    </div>
  </div>

  <!--------------------           display table                    ---------------------->
  <ng-template #table>
    <sem-table-displayer
      #columnsDisplayer
      [dataToDisplay]="dataToDisplay"
      [defaultDataForNewItemRow]="defaultDataForNewItemRow"
      [listingParentId]="listingParentId"
      [paginator]="config.paginator || null"
      [shouldCheckEmpty]="shouldCheckEmpty"
    ></sem-table-displayer>
  </ng-template>

  <!------------------------           loader                 -------------------------->
  @if (loadingState) {
    <div class="loading-state-background">
      <div class="loader-container">
        <app-sem-skeleton borderRadius="10px" height="100%" shape="rectangle" width="100%"></app-sem-skeleton>
      </div>
    </div>
  }

  <!--------------------           btns on screen bottom                 ---------------------->
  <div class="quick-actions-btn">
    <sem-quick-action-butons
      (actionEmitted)="actionEmitted.emit($event)"
      (dataToUpdate)="dataToUpdate.emit($event)"
      (sequenceEmitted)="sequenceEmitted.emit($event)"
      [requiredFields]="config.requiredFields!"
    ></sem-quick-action-butons>
  </div>
</div>

<ng-template #noConfig></ng-template>
