import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppFormFieldErrorDisplayComponent } from '../components/app-form-field-error-display/app-form-field-error-display.component';
import { ConnectionsServiceSlugIconEnum } from '../enums/connections-service.enum';
import { DialogComponent, MAT_DIALOG_DEFAULT_OPTIONS_VALUES } from './dialog/dialog.component';
import { IconComponent } from './icon/icon.component';

const ASSETS_ICONS_PATH = 'assets/icons';

@NgModule({
  declarations: [IconComponent],
  imports: [
    DialogComponent,
    A11yModule,
    CommonModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    AppFormFieldErrorDisplayComponent,
  ],
  exports: [
    A11yModule,
    CommonModule,
    DialogComponent,
    DragDropModule,
    IconComponent,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    AppFormFieldErrorDisplayComponent,
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: MAT_DIALOG_DEFAULT_OPTIONS_VALUES,
    },
  ],
})
export class MaterialModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon('ai', this.domSanitizer.bypassSecurityTrustResourceUrl(`${ASSETS_ICONS_PATH}/ai.svg`));
    this.matIconRegistry.addSvgIcon(
      'data_info_alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${ASSETS_ICONS_PATH}/data_info_alert.svg`),
    );
    this.matIconRegistry.addSvgIcon('bing', this.domSanitizer.bypassSecurityTrustResourceUrl(`${ASSETS_ICONS_PATH}/brands/bing.svg`));
    this.matIconRegistry.addSvgIcon('google', this.domSanitizer.bypassSecurityTrustResourceUrl(`${ASSETS_ICONS_PATH}/brands/google.svg`));
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${ASSETS_ICONS_PATH}/brands/linkedin.svg`),
    );


    this.matIconRegistry.addSvgIcon('pinned', this.domSanitizer.bypassSecurityTrustResourceUrl(`${ASSETS_ICONS_PATH}/pins/pinned.svg`));
    this.matIconRegistry.addSvgIcon('unpinned', this.domSanitizer.bypassSecurityTrustResourceUrl(`${ASSETS_ICONS_PATH}/pins/unpinned.svg`));

    Object.values(ConnectionsServiceSlugIconEnum).forEach((connectionKey) => {
      this.matIconRegistry.addSvgIcon(
        connectionKey,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${ASSETS_ICONS_PATH}/brands/${connectionKey}.svg`),
      );
    });
  }
}
