import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterTypesEnum } from '../../FilterTypesEnum';

@Pipe({
  name: 'filterTypeAlias',
})
export class FilterTypeAliasPipe implements PipeTransform {
  private translateService = inject(TranslateService);

  transform(type: FilterTypesEnum): any {
    switch (type) {
      case FilterTypesEnum.end:
        return this.translateService.instant('sem_table.filters_alias_end');
      case FilterTypesEnum.start:
        return this.translateService.instant('sem_table.filters_alias_start');
      case FilterTypesEnum.equal:
        return this.translateService.instant('sem_table.filters_alias_equal');
      case FilterTypesEnum.regexp:
        return this.translateService.instant('sem_table.filters_alias_regexp');
      case FilterTypesEnum.include:
        return this.translateService.instant('sem_table.filters_alias_include');
      case FilterTypesEnum.include_one_of:
        return this.translateService.instant('sem_table.filters_alias_include_one_of');
      case FilterTypesEnum.exclude:
        return this.translateService.instant('sem_table.filters_alias_exclude');

      case FilterTypesEnum.exists_in:
        return this.translateService.instant('sem_table.filters_alias_contains');
      case FilterTypesEnum.not_exists_in:
        return this.translateService.instant('sem_table.filters_alias_not_contains');
      case FilterTypesEnum.in_array:
        return this.translateService.instant('sem_table.filters_alias_multiselect');

      case FilterTypesEnum.is_empty:
        return this.translateService.instant('sem_table.filters_alias_is_empty');

      case FilterTypesEnum.not_empty:
        return this.translateService.instant('sem_table.filters_alias_not_empty');

      case FilterTypesEnum.is_uppercase:
        return this.translateService.instant('sem_table.filters_alias_is_uppercase');

      case FilterTypesEnum.num_equal:
        return this.translateService.instant('sem_table.filters_alias_num_equal');
      case FilterTypesEnum.num_bigger:
        return this.translateService.instant('sem_table.filters_alias_num_bigger');
      case FilterTypesEnum.string_bigger:
        return this.translateService.instant('sem_table.filters_alias_string_bigger');
      case FilterTypesEnum.num_biggerrEqual:
        return this.translateService.instant('sem_table.filters_alias_num_bigger_equal');
      case FilterTypesEnum.num_smaller:
        return this.translateService.instant('sem_table.filters_alias_num_smaller');
      case FilterTypesEnum.string_smaller:
        return this.translateService.instant('sem_table.filters_alias_string_smaller');
      case FilterTypesEnum.num_smallerEqual:
        return this.translateService.instant('sem_table.filters_alias_num_smaller_equal');
      case FilterTypesEnum.field_not_include_field:
        return this.translateService.instant('sem_table.filters_alias_field_not_include_field');
      case FilterTypesEnum.has_manual_changes:
        return this.translateService.instant('sem_table.filters_alias_has_manual_changes');
      case FilterTypesEnum.not_equal:
        return this.translateService.instant('sem_table.not_equal');

      default: {
        return null;
      }
    }
  }
}
