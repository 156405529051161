import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class SubtractAction extends ActionModel {
  override type = ActionTypesEnum.subtract;
  override value!: number;

  isValid(): boolean {
    return !!this.columnName && (!!this.value || Number(this.value) > 0);
  }

  use(item: ItemModel) {
    return item[this.columnName as keyof typeof item] ? Number(item[this.columnName as keyof typeof item]) - this.value : null;
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      value: this.value,
      sequence: this.sequence,
    };
  }

  copyValue(item: this, overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
  }
}
