import { FilterTypesEnum } from '../FilterTypesEnum';
import { FilterModel } from './filter.model';

export class NumberEqualFilterModel extends FilterModel {
  override symbol: FilterTypesEnum = FilterTypesEnum.num_equal;

  isValid() {
    return !!this.value;
  }
}
