import { Component, OnInit, inject } from '@angular/core';
import { MainToolbarService, TOOLBAR_STATE } from '../main-toolbar.service';

@Component({
  selector: 'sem-tool-displayer',
  templateUrl: './tool-displayer.component.html',
  styleUrls: ['./tool-displayer.component.scss'],
})
export class ToolDisplayerComponent implements OnInit {
  possibleStates: typeof TOOLBAR_STATE = TOOLBAR_STATE;

  public mainToolbarService = inject(MainToolbarService);

  get isActive(): boolean {
    return this.mainToolbarService.currentState !== TOOLBAR_STATE.default;
  }

  ngOnInit(): void {
    this.mainToolbarService.currentState = TOOLBAR_STATE.default;
  }

  disableColumnTool() {
    this.mainToolbarService.currentState = TOOLBAR_STATE.default;
  }
}
