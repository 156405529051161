import { Subject, of } from 'rxjs';
import { TruncatePipe } from '../../../../../pipes/truncate.pipe';
import { ActionTypesEnum } from '../../../../action/ActionTypesEnum';
import { mainTableColumnDefaultGroup } from '../../../../enums';
import { ItemModel } from '../../../../item.model';
import { CustomButtonPerItemInterface } from '../../../../models/CustomButton.model';
import { ObjectMapping } from '../../../../models/Mapping.model';
import { ToolInterface } from '../../../../models/TableConfigurationInterface.model';
import { AgregationInterface } from '../../../../models/agregation.model';
import { ColumnTypesEnum } from '../../../ColumnTypesEnum';
import { ColumnModel } from '../column.model';

export class CustomColumn extends ColumnModel {
  truncatePipe: TruncatePipe = new TruncatePipe();
  override valueMapping: ObjectMapping;
  override type = ColumnTypesEnum.CUSTOM;
  override autocomplete$?: Subject<any[]>;
  emitCustomEvent: boolean;
  emptyValueLabel: string;

  constructor(config: {
    ableToFilter?: boolean;
    actionsAvailable?: ActionTypesEnum[];
    aggregate?: boolean;
    aggregateBySelect?: AgregationInterface;
    alias?: string;
    autocomplete$?: Subject<any[]>;
    canBeSorted?: boolean;
    customButton?: CustomButtonPerItemInterface[];
    displayFullLength?: boolean;
    editable?: ToolInterface;
    emitCustomEvent?: boolean;
    emptyValueLabel?: string;
    group?: string;
    grouping?: boolean;
    required?: boolean;
    tooltip?: string;
    valueMapping?: ObjectMapping;
    disabledIn?: {
      duplicatePopup?: boolean;
      editPopup?: boolean;
      addPopup?: boolean;
      usingAsFilterInMassOperations?: boolean;
    };
  }) {
    super();

    this.ableToFilter = config.ableToFilter;
    this.actionsAvailableTypes = config.actionsAvailable ? config.actionsAvailable : this.actionsAvailableTypes;
    this.aggregate = config.aggregate;
    this.aggregateBySelect = config.aggregateBySelect;
    this.alias = config.alias || '';
    this.autocomplete$ = config.autocomplete$;
    this.canBeSorted = config.canBeSorted;
    this.customButton = config.customButton;
    this.disabledIn = config.disabledIn;
    this.editable = config.editable;
    this.emitCustomEvent = config.emitCustomEvent!;
    this.emptyValueLabel = config.emptyValueLabel || '';
    this.group = config.group || mainTableColumnDefaultGroup;
    this.required = config.required!;
    this.tooltip = config.tooltip;
    this.valueMapping = config.valueMapping!;
  }

  returnPossibleValues() {
    return of(null);
  }

  returnValueForDisplay(item: ItemModel): string {
    const value = this.map(item[this.param as keyof typeof item]);
    return this.displayFullLength ? this.truncatePipe.transform(value, 70) : value;
  }

  returnValue(item: ItemModel): string {
    return item[this.param as keyof typeof item] as string;
  }
}
