<ng-container *ngIf="editState"></ng-container>

<ng-container *ngTemplateOutlet="editState ? editable : notEditable"></ng-container>
<ng-template #editable>
  <div class="date-container {{ column.param }}-column-display">
    <input
      #input
      (dateTimeChange)="setDateValue($event)"
      [(ngModel)]="date"
      [disabled]="disabled"
      [name]="nameAttribute"
      [owlDateTimeTrigger]="dt1"
      [owlDateTime]="dt1"
      [placeholder]="'Choose Date'"
      [selectMode]="column.rangeType ? 'range' : 'single'"
      class="data-picker"
    />
    <owl-date-time [pickerType]="column.pickerType" #dt1></owl-date-time>
  </div>
</ng-template>
<ng-template #notEditable>
  <div #input [ngClass]="{ 'not-editable': displayNotEditable }" class="value">

    <ng-container *ngIf="column.checkOverdue; else normalDate">
      <ng-container *ngIf="date | overdueDate:'DD/MM/yyyy' as dateInfo">

        <span [ngClass]="dateInfo.className">
          {{ dateInfo.formattedDate }}
        </span>

      </ng-container>
    </ng-container>

    <ng-template #normalDate>
      {{ date }}
    </ng-template>
  </div>
</ng-template>
