import { ItemModel } from '../../item.model';
import { ActionTypesEnum } from '../ActionTypesEnum';
import { ActionModel } from './ActionModel';

export class ReplaceAction extends ActionModel {
  override type = ActionTypesEnum.replace;

  override value: {
    from: string;
    to: string;
    caseSensitive: boolean;
    fullMatch: boolean;
  } = {
    from: '',
    to: '',
    caseSensitive: false,
    fullMatch: false,
  };

  isValid(): boolean {
    return !!this.columnName && !!this.value && !!this.value.from;
  }

  use(item: ItemModel) {
    let option = 'g';
    option = this.value.caseSensitive ? option : `${option}i`;
    const fromReg = this.prepareRegex(this.value.from);
    const regex = this.value.fullMatch ? new RegExp(`(^|\\B)(${fromReg})(?=\\B|$)`, option) : new RegExp(fromReg, option);

    const value = (item[this.columnName as keyof typeof item] as string).replace(regex, this.value.to);
    return value;
  }

  returnForSend() {
    return {
      action: this.type,
      param: this.columnName,
      from: this.value.from,
      to: this.value.to,
      match_case: this.value.caseSensitive,
      match_word: this.value.fullMatch,
      sequence: this.sequence,
    };
  }

  copyValue(item: this & this['value'], overrideManualChanges: boolean): void {
    this.columnName = item.columnName;
    this.override_manual_changes = overrideManualChanges;
    this.value.from = item.from;
    this.value.to = item.to;
    this.value.caseSensitive = item.caseSensitive;
    this.value.fullMatch = item.fullMatch;
  }

  private prepareRegex(search: string): string {
    const dangerChars = ['|', '>', '@', '!', '?', '/', '_', '-', '\\'];
    return [...search].map((letter) => (dangerChars.includes(letter) ? `\\${letter}` : letter)).join('');
  }
}
