import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TranslatePipe } from '@ngx-translate/core';
import { QuickstartService } from '../quickstart.service';

@Component({
  selector: 'app-quickstart-modal',
  standalone: true,
  imports: [MatDialogModule, MatIcon, TranslatePipe],
  templateUrl: './quickstart-modal.component.html',
  styleUrl: './quickstart-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickstartModalComponent implements OnDestroy {
  private readonly quickstartService = inject(QuickstartService);
  readonly data = inject(MAT_DIALOG_DATA);

  ngOnDestroy(): void {
    this.quickstartService.closeModal();
  }
}
