import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TemplateWithIdDirective } from '../../directives/template-with-id.directive';
import { DataTableColumnTypesEnum } from '../../enums/data-table.enum';
import { DataTablePageInterface, DataTableStateModel } from '../../model/data-table.model';
import { HelperService } from '../../service/helper.service';

const COLUMN_NAME_PREFIX = 'COL';
const NO_VALUE_STR = '- -';
const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements AfterContentInit, OnInit {
  @ContentChildren(TemplateWithIdDirective)
  templates!: QueryList<TemplateWithIdDirective>;

  @Output() changeState = new EventEmitter<DataTableStateModel>();
  @Output() clickActionn = new EventEmitter<any>();
  @Output() stateChange = new EventEmitter();

  @Input() data: any;
  @Input() noDataInfo!: string;
  @Input() title!: string;
  @Input()
  get state(): DataTableStateModel {
    return this.dataState;
  }

  set state(state: DataTableStateModel) {
    this.dataState = state;

    if (state.columns) {
      this.columnsNames = state.columns.map((_column, index) => `${COLUMN_NAME_PREFIX}${index}`);
    }

    !state.page && (this.isPaginatorVisible = false);
    state.searchFields && (this.isSearchVisible = true);
  }

  private dataState!: DataTableStateModel;

  columnNamePrefix = COLUMN_NAME_PREFIX;
  columnTypes = DataTableColumnTypesEnum;
  columnsNames: string[] = [];
  isPaginatorVisible = true;
  isSearchVisible = false;
  noValueStr = NO_VALUE_STR;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  searchText = new UntypedFormControl('');
  templatesFields: string[] = [];

  templatesConst = {
    lastCell: '_lastCell',
    rightHeader: '_rightHeader',
  };

  constructor(private helperService: HelperService) {
    this.state = new DataTableStateModel();
  }

  ngOnInit() {
    this.state.searchFields &&
      this.searchText.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((searchText) => {
        this.state.filterGroups = this.helperService.defaultFilterGroup(searchText, this.state.searchFields!);
        this.afterChangeState();
      });
  }

  ngAfterContentInit() {
    this.templates.forEach((child: TemplateWithIdDirective) => {
      (this.templatesFields[child.role as keyof typeof this.templatesFields] as any) = child.template;
    });

    this.templatesFields[this.templatesConst.lastCell as keyof typeof this.templatesFields] && this.columnsNames.push('customLastCell');
  }

  setPage(page: DataTablePageInterface) {
    this.state.page = page;
    this.afterChangeState();
  }

  private afterChangeState() {
    this.changeState.emit(this.state);
  }
}
