import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { appearAnimation } from '../../animation/tool';
import { ActionFormComponent } from '../action-form/action-form.component';
import { ActionModel } from '../models/ActionModel';
import { ActionPopupDataInterface } from '../models/ActionPopupData.interface';
import { ActionFormEmittedDataInterface } from '../models/ActionPopupEmittedData.interface';

@Component({
  selector: 'sem-action-selected-popup',
  templateUrl: './action-popup.component.html',
  styleUrls: ['./action-popup.component.scss'],
  animations: [appearAnimation],
})
export class ActionPopupComponent implements OnInit, OnDestroy {
  @ViewChild(ActionFormComponent) actionForm!: ActionFormComponent;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ActionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ActionPopupDataInterface,
  ) {}

  ngOnInit() {
    if (this.data.waitForApiResponse) {
      this.data.communicationService.apiSuccess$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.dialogRef.close());
    }
  }

  initSubmit(isRule: boolean, isAction: boolean) {
    this.actionForm.submit(isRule, isAction);
  }

  submitValidForm(data: ActionFormEmittedDataInterface) {
    const { isRule, isAction, action } = data;
    if (this.data.waitForApiResponse) {
      this.asyncSubmit(isRule, isAction, action);
    } else {
      this.syncSubmit(isRule, isAction, action);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private asyncSubmit(isRule: boolean, isAction: boolean, action: ActionModel) {
    if (isRule) {
      const { groups: filterGroups } = this.data.filterService;
      this.data.communicationService.ruleEmitted$.next({ action, filterGroups, active: true });
    }
    if (isAction) {
      this.data.actionService.actionEmitted$.next(action);
    }
  }

  private syncSubmit(isRule: boolean, isAction: boolean, action: ActionModel) {
    this.dialogRef.close({
      action,
      isRule,
      isAction,
    } as ActionFormEmittedDataInterface);
  }
}
