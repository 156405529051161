import { Component, ComponentFactoryResolver, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TutorialService } from 'src/app/dashboard/tutorial/tutorial.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { ConnectionModel, ServiceModel } from 'src/app/shared/model/connections.model';
import { ConnectionsService } from 'src/app/shared/service/connections.service';
import { ProjectService } from '../../../project.service';
import { ConnectionUrlInterface, ServicesSlugsEnum } from '../../connections.model';
import { ServicesFactoryComponent } from './services-factory.component';

@Component({
  selector: 'app-services-popup',
  templateUrl: './services-popup.component.html',
  styleUrls: ['./services-popup.component.scss'],
})
export class ServicesPopupComponent extends ServicesFactoryComponent implements OnInit {
  isIntegrateDisabled = false;
  isLoaded = false;
  serviceConnections!: ConnectionModel[];
  servicesSlugsEnum = ServicesSlugsEnum;
  url!: ConnectionUrlInterface;

  constructor(
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
    private projectService: ProjectService,
    public override resolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<ServicesPopupComponent>,
    public tutorialService: TutorialService,
    @Inject(MAT_DIALOG_DATA) public currentService: ServiceModel,
  ) {
    super(resolver);
  }

  ngOnInit(): void {
    if (this.currentService?.id) {
      this.connectionsService
        .getConnectionsForService(this.currentService.id)
        .subscribe((res) => res.data && this.initServiceComponent(res.data));
    }
  }

  submitForm() {
    if (!this.isFormValid || !this.serviceComponent) {
      this.notificationService.error('invalid_form');
      return;
    }
    this.serviceComponent.project_connection_id = this.currentService.connection_id;
    this.serviceComponent.submit();
  }

  goToUrl() {
    if (this.url.url) {
      window.location.href = this.url.url;
      this.isIntegrateDisabled = true;
    }
  }

  private initServiceComponent(connections: ConnectionModel[]) {
    this.createServiceComponent(this.currentService.slug!, connections);

    if (this.serviceComponent) {
      if (this.currentService.slug !== ServicesSlugsEnum.shopify) {
        this.getUrlForConnect();
      } else {
        this.isLoaded = true;
      }

      this.serviceComponent.connectionAttached.subscribe((res: any) => this.reactToConnectionAttached(res));
    } else {
      this.notificationService.error('unavailable_resource');
      this.dialogRef.close(false);
      throw new Error(`serviceComponent not exists for ${this.currentService.slug}`);
    }
  }

  private getUrlForConnect() {
    const { type, id } = this.currentService;
    const projectId = this.projectService.activeProject$?.getValue()?.id || null;

    if (projectId) {
      this.connectionsService.getAuthURL(type!, [id!], true, projectId).subscribe((res) => {
        this.url = res;
        this.isLoaded = true;
      });
    } else {
      this.notificationService.error('no_active_project');
      this.dialogRef.close(false);
    }
  }

  private reactToConnectionAttached(isItAttached: boolean) {
    if (isItAttached) {
      this.notificationService.success('attach_connection');
      this.dialogRef.close(true);
    } else {
      this.notificationService.error('attach_connection');
    }

    this.serviceComponent && this.serviceComponent.isLoading && (this.serviceComponent.isLoading = false);
  }
}
